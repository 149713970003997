import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  getMenuCategories as getCategoriesApi,
  updateCategoryStatus as updateCategoryStatusApi,
  addNewCategory as addNewCategoryApi,
  updateCategory as updateCategoryApi,
  getAddOnsList as getAddOnsListApi,
  addNewAddOns as addNewAddOnsApi,
  updateAddOns as updateAddOnsApi,
  deleteAddOns as deleteAddOnsApi
} from "../../helpers/backend_helper";

export const getMenuCategories = createAsyncThunk(
  "categories/getCategories",
  async (event: any) => {
    try {
      const response = getCategoriesApi(event);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const updateCategoryStatus = createAsyncThunk(
  "categories/updateStatus",
  async (event: any) => {
    try {
      const response = updateCategoryStatusApi(event);
      const modifiedEvent = await response;
      return modifiedEvent;
    } catch (error) {
      return error;
    }
  }
);

export const addNewCategory = createAsyncThunk(
  "categories/addNewCategory",
  async (event: any) => {
    try {
      const response = addNewCategoryApi(event);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const updateCategory = createAsyncThunk(
  "categories/updateCategory",
  async (event: any) => {
    try {
      const response = updateCategoryApi(event);
      const modifiedEvent = await response;
      return modifiedEvent;
    } catch (error) {
      return error;
    }
  }
);
export const resetApiResponseFlag = createAsyncThunk(
  "categories/resetApiResponseFlag",
  async () => {
    try {
      return true;
    } catch (error) {
      return error;
    }
  }
);

export const getAddOns = createAsyncThunk(
  "categories/getAddOns",
  async (event: any) => {
    try {
      const response = getAddOnsListApi(event);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const addNewAddOns = createAsyncThunk(
  "categories/addNewAddOns",
  async (event: any) => {
    try {
      const response = addNewAddOnsApi(event);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const updateAddOns = createAsyncThunk(
  "categories/updateAddOns",
  async (event: any) => {
    try {
      const response = updateAddOnsApi(event);
      const modifiedEvent = await response;
      return modifiedEvent;
    } catch (error) {
      return error;
    }
  }
);

export const deleteAddOns = createAsyncThunk(
  "categories/deleteAddOns",
  async (event: any) => {
    try {
      const response = deleteAddOnsApi(event);
      const modifiedEvent = await response;
      return modifiedEvent;
    } catch (error) {
      return error;
    }
  }
);