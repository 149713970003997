import React, { useEffect, useState, ChangeEvent } from "react";
import MetaTags from "react-meta-tags";
import { withRouter, Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  UncontrolledAlert,
  PaginationItem
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import {
  getVenueList as onGetVenueList,
  getVenueArea as onGetVenueArea,
  updateVenueArea as onUpdateVenueArea,
  updateVenueAreaStatus as onUpdateVenueAreaStatus,
  getVenueAreaDetail as onGetVenueAreaDetail,
  updateVenueStatus as onUpdateVenueStatus,
  addNewVenue as onAddNewVenue,
  addNewVenueArea as onAddNewVenueArea,
  updateVenue as onUpdateVenue,
  resetVenueApiResponseFlag as onResetApiResponseFlag,
  resetVenueAreaApiResponseFlag as onResetVenueAreaApiResponseFlag,
} from "../../slices/thunks";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { isEmpty } from "lodash";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";


import DeleteModal from "../Common/DeleteModal";
//redux
import { useSelector, useDispatch } from "react-redux";
import moment from 'moment'
import { json } from "stream/consumers";

const Areas = () => {
  const dispatch = useDispatch();
  const { SearchBar } = Search;
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [venueEvent, setVenueEvent] = useState<any>({});
  const [modal, setModal] = useState<boolean>(false);
  const [viewAreaModel, setViewAreaModel] = useState<boolean>(false);
  const [venueAreaId, setVenueAreaId] = useState<any>({});
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [isDelete, setIsDelete] = useState<boolean>(false);
  const [file, setFile] = useState<File>();
  const [workingHours, setWorkingHours] = useState<Array<any>>([])
  const [workingDay, setWorkingDay] = useState<any>({})
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  // const formData = new FormData();
  const handleShow = () => setModal(false);
  const { venueList, venueArea, venueAreaAddUpdate, venueMsgResponse, venueAddUpdate } = useSelector((state: any) => ({
    venueList: state.venues.venueList,
    venueMsgResponse: state.venues.venueMsgResponse,
    venueAddUpdate: state.venues.venueAddUpdate,

    venueArea: state.areas.venueArea,
    venueAreaDetail: state.areas.venueAreaDetail,
    venueAreaAddUpdate: state.areas.venueAreaAddUpdate,
    
  }));

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
      // formData.append("floorPlanImage", e.target.files[0]);
    }
  };

  // useEffect(() => {
  //   dispatch(onGetVenueList());
  // }, [dispatch, venueAddUpdate]);

  // get all venue areas 
  useEffect(() => {
    dispatch(onGetVenueList())
    dispatch(onGetVenueArea({ id: '' }));
  }, [dispatch, venueAreaAddUpdate]);

  useEffect(() => {
    const timer = setTimeout(() => {
      dispatch(onResetApiResponseFlag());
    }, 5000);
    return () => clearTimeout(timer);
  }, [venueMsgResponse]);

  const pageOptions = {
    sizePerPage: 10,
    totalSize: venueList.length, // replace later with size(users),
    custom: true,
  };

  const defaultSorted: any = [
    {
      dataField: "_id", // if dataField is not match to any column you defined, it will be ignored.
      order: "asc", // desc or asc
    },
  ];

  const handleDeleteClick = (event: any) => {
    setVenueEvent({
      id: event._id,
      status: event.status == 1 ? 0 : 1
    });
    setDeleteModal(true);
  };
  /**
  * On delete event
  */
  const handleDeleteVenueArea = () => {
    dispatch(onUpdateVenueAreaStatus(venueEvent));
    setDeleteModal(false)
  };

  const handleDeleteClose = () => {
    setDeleteModal(false)
    setVenueEvent({})
  };

  const handleValidFormSubmit = (values: any) => {
    values["workingHours"] = JSON.stringify(workingHours);
    values["seassonStart"] = startDate;
    values["seassoEnd"] = endDate;
    delete values.day
    delete values.openingTime
    delete values.closingTime
    if (isEdit) {
      values["id"] = venueEvent._id
      if (!isEmpty(file)) {
      }
      values['file'] = file;
      // save edit user
      dispatch(onUpdateVenueArea(values));
      // setIsEdit(false)
    } else {
      values['file'] = file;
      // save new Venue Area
      dispatch(onAddNewVenueArea(values));
    }
    setWorkingHours([])
    toggleGetArea();
  };

  const toggleGetArea = () => {
    setModal(!modal);
    if (isEdit) {
      setIsEdit(false)
      setVenueEvent({})
    }
    if (!modal && !isEmpty(venueEvent) && !!isEdit) {
      setTimeout(() => {
        setVenueEvent({})
        setIsEdit(false);
      }, 500);
    }
  };

  // View And Close View Areas Model
  const toggleViewArea = () => {
    setViewAreaModel(!viewAreaModel);
    // setIsEdit(!isEdit)
  };

  const handleAddAreaClick = () => {
    // venueEvent.venueId = venue._id
    dispatch(onGetVenueList())
    toggleGetArea();
  };

  const filterArea = (venueId: string) => {
    dispatch(onGetVenueArea({ id: venueId }));
  };


  const handleEditVenueAreaClick = (event: any) => {
    setStartDate(new Date(moment(event.seassonStart).format('YYYY/MM/DD')))
    setEndDate(new Date(moment(event.seassoEnd).format('YYYY/MM/DD')))
    setWorkingHours(event.workingHours)
    setVenueEvent(event);
    setIsEdit(true);
    toggleGetArea();
  };

  // Working day hadle
  const handleWorkingDayChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = event.target;
    setWorkingDay({
      ...workingDay,
      [name]: value,
    });
  };
  const handleAddWorkingDay = () => {
    setWorkingHours([...workingHours, workingDay])
    setWorkingDay({ day: '', openingTime: '', closingTime: '' })
  }

  const venuesListColumns = [
    {
      text: "IMAGE",
      dataField: "locationOnFloorPlan",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent: any, venueArea: any) => (
        <React.Fragment>
          <img
            className="avatar-sm rounded-circle me-2"
            src={venueArea.locationOnFloorPlan}
            alt=""
          />
        </React.Fragment>
      ),
    },
    {
      text: "VENUE NAME",
      dataField: "venueName",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent: any, venueArea: any) => <React.Fragment>{venueArea.venueInfo.name}</React.Fragment>,
    },
    {
      text: "AREA NAME",
      dataField: "areaName",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent: any, venueArea: any) => <React.Fragment>{venueArea.areaName}
      <Link to={"/assets/kitchen?asset=&area="+venueArea._id}><div className="badge bg-primary font-size-12">View Kitchen</div>
      </Link></React.Fragment>,
    },
    {
      text: "EMAIL",
      dataField: "email",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent: any, venueArea: any) => <React.Fragment>{venueArea.venueInfo.email}</React.Fragment>,
    },
    {
      text: "ASSET TYPE",
      dataField: "assetType",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent: any, venueArea: any) => <React.Fragment>{venueArea.assetType}</React.Fragment>,
    },
    // {
    //   text: "TIMING",
    //   dataField: "workingHours",
    //   sort: true,
    //   formatter: (cellContent: any, venueArea: any) =>
    //     <React.Fragment>
    //       {venueArea.workingHours.map((hours: any, index: any) => (
    //         <Row key={index}>
    //           <Col><span className="w-100">{hours.day}</span></Col>
    //           <Col><span className="w-100">{hours.openingTime}</span></Col>
    //           <Col><span className="w-100">{hours.closingTime}</span></Col>
    //         </Row>
    //       ))}
    //     </React.Fragment>,
    // },
    {
      text: "STATUS",
      dataField: "currentStatus",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent: any, venueArea: any) => (
        <React.Fragment>
          {venueArea.currentStatus == 'Available' ?
            <div className="badge bg-success font-size-12">{"Available"}</div>
            : <div className="badge bg-warning font-size-12">{"Unavailable"}</div>}
        </React.Fragment>
      ),
    },
    {
      text: "ACTION",
      dataField: "action",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent: any, venueArea: any) => (
        <React.Fragment>
          <UncontrolledDropdown>
            <DropdownToggle tag="button" className="btn btn-light btn-sm">
              <i className="uil uil-ellipsis-h"></i>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-end">
              {/* <DropdownItem to="#" onClick={() => handleViewAreaClick(venueArea)}>View Areas</DropdownItem> */}
              {/* <DropdownItem to="#" onClick={() => handleAddAreaClick(venueArea)}>Add Area</DropdownItem> */}
              <DropdownItem to="#" onClick={() => handleEditVenueAreaClick(venueArea)}>Edit Area</DropdownItem>
              {/* <DropdownItem to="#" onClick={() => handleDeleteAreaClick(venue)}>Delete Area</DropdownItem> */}
              <DropdownItem to="#" onClick={() => handleDeleteClick(venueArea)}>Delete</DropdownItem>
              {/* <DropdownItem to="#" onClick={() => handleDeleteClick(venueArea)}>{venueArea.status == 1 ? "Inactive" : "Active"}</DropdownItem> */}
            </DropdownMenu>
          </UncontrolledDropdown>
        </React.Fragment>
      ),
    },
  ];

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteVenueArea}
        onCloseClick={handleDeleteClose}
        deleteButtonLable={"Delete"}
      />
      <div className="page-content">
        <MetaTags>
          <title>Venue Areas | Retaurant Booking</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Restaurant" breadcrumbItem="Venue Areas" />
          {venueMsgResponse && venueMsgResponse.message ? (
            <UncontrolledAlert color="primary">
              {venueMsgResponse.message}
            </UncontrolledAlert>
          ) : null}
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="_id"
                        data={venueArea}
                        columns={venuesListColumns}
                        bootstrap4
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <Row className="align-items-start">
                              <div className="col-sm">
                                <div>
                                  <Button color="light" onClick={handleAddAreaClick} className="mb-4 button-success">
                                    <i className="mdi mdi-plus me-1"></i>
                                    Add Venue Area</Button>
                                </div>
                              </div>
                              <Col xs={3} md={3}>   
                                  <select className="input-select" onChange={(e) => filterArea(e.target.value)}>
                                    <option value=""> Select Venue </option>
                                    {
                                      venueList.map((venue: any, key: any) => (
                                        <option value={venue._id} key={key}>{venue.name}</option>
                                      ))}
                                  </select>
                               </Col>
                            </Row>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                    defaultSorted={defaultSorted}
                                    classes={
                                      "table align-middle table-nowrap table-hover"
                                    }
                                    bordered={false}
                                    striped={false}
                                  />
                                  <Modal isOpen={modal} toggle={toggleGetArea}>
                                    <ModalHeader toggle={toggleGetArea} tag="h4">
                                      {!!isEdit ? "Edit Area" : "Add Area"}
                                    </ModalHeader>
                                    <ModalBody>
                                      <AvForm
                                        encType='multipart/form-data'
                                        onValidSubmit={(
                                          e: any,
                                          values: any
                                        ) => {
                                          handleValidFormSubmit(values);
                                        }}
                                      >
                                        <Row form>
                                          <Col xs={12}>
                                            <div className="mb-3">
                                              <AvField
                                                name="venueId"
                                                label="Venue"
                                                type="select"
                                                placeholder="Select Venue"
                                                errorMessage="Select valid Venue"
                                                multiple={false}
                                                required
                                                value={venueEvent.venueId || ""}
                                              >
                                                <option key='blankChoice1' hidden value=""> Select Venue </option>
                                                {
                                                  venueList.map((venue: any, key: any) => (
                                                    <option value={venue._id} key={key}>{venue.name}</option>
                                                  ))}
                                              </AvField>
                                            </div>
                                            <div className="mb-3" >
                                              <AvField
                                                name="areaName"
                                                label="Area Name"
                                                type="text"
                                                placeholder="Enter Valid Area Name"
                                                errorMessage="Invalid Area Name"
                                                validate={{
                                                  required: { value: true, },
                                                }}
                                                value={venueEvent.areaName || ""}
                                              />
                                            </div>
                                            <div className="mb-3"> Seasson Start
                                            <DatePicker 
                                              name="seassonStart"
                                              showIcon
                                              selected={startDate} 
                                              onChange={(date: any) => setStartDate(date)} 
                                              icon="fa fa-calendar"
                                              dateFormat="yyyy/MM/dd"
                                              placeholderText="Enter Valid Seasson Start "
                                              className="red-border"
                                              required
                                            />
                                              {/* <AvField
                                                name="seassonStart"
                                                label="Seasson Start"
                                                type="text"
                                                placeholder="Enter Valid Seasson Start "
                                                errorMessage="Invalid Seasson Start "
                                                validate={{
                                                  required: { value: true },
                                                }}
                                                value={venueEvent.seassonStart || ""}
                                              /> */}
                                            </div>
                                            <div className="mb-3">
                                            Seasson End
                                            <DatePicker 
                                              name="seassoEnd"
                                              showIcon
                                              selected={endDate} 
                                              onChange={(date: any) => setEndDate(date)} 
                                              icon="fa fa-calendar"
                                              dateFormat="yyyy/MM/dd"
                                              placeholderText="Enter Valid Seasson End "
                                              className="red-border"
                                              required
                                            />
                                              {/* <AvField
                                                name="seassoEnd"
                                                label="Seasson End"
                                                type="text"
                                                placeholder="Enter Valid Seasso End "
                                                errorMessage="Invalid Sasson End"
                                                validate={{
                                                  required: { value: true },
                                                }}
                                                value={venueEvent.seassoEnd || ""}
                                              /> */}
                                            </div>
                                            <div className="mb-3">
                                              <AvField
                                                name="timeslots"
                                                label="timeslots"
                                                type="number"
                                                placeholder="Enter Valid timeslots "
                                                errorMessage="Invalid timeslots "
                                                validate={{
                                                  required: { value: true },
                                                }}
                                                value={venueEvent.timeslots || ""}
                                              />
                                            </div>
                                            {/* <div className="mb-3">
                                              <AvField
                                                name="assetType"
                                                label="Asset Type"
                                                type="text"
                                                placeholder="Enter Valid Asset Type "
                                                errorMessage="Invalid Asset Type "
                                                validate={{
                                                  required: { value: true },
                                                }}
                                                value={venueEvent.assetType || ""}
                                              />
                                            </div> */}
                                            <div className="mb-3">
                                              <AvField
                                                name="assetType"
                                                label="Asset Type"
                                                type="select"
                                                placeholder="Select Asset Type"
                                                errorMessage="Select valid Asset Type"
                                                multiple={false}
                                                required
                                                value={venueEvent.assetType || ""}
                                              >
                                                <option key='value1' hidden value=""> Select Asset Type </option>
                                                <option key='value2' value="Table"> Table </option>
                                                <option key='value3' value="Lounge Chair"> Lounge Chair </option>
                                                <option key='value4' value="Sunbed"> Sunbed </option>
                                                <option key='value5' value="VIP Table"> VIP Table </option>
                                                <option key='value6' value="Room"> Room </option>
                                                <option key='value7' value="Other"> Other </option>
                                              </AvField>
                                            </div>
                                            <div className="mb-3">
                                              <AvField
                                                name="description"
                                                label="Description"
                                                type="textarea"
                                                placeholder="Enter Valid Description "
                                                errorMessage="Invalid Description "
                                                validate={{
                                                  required: { value: true },
                                                }}
                                                value={venueEvent.description || ""}
                                              />
                                            </div>
                                            <div className="mb-3">
                                              {
                                                !!isEdit ?
                                                  <AvField
                                                    onChange={handleFileChange}
                                                    name="locationOnFloorPlan"
                                                    label="Area Image "
                                                    type="file"
                                                    placeholder="Enter Valid Venue Image "
                                                  />
                                                  :
                                                  <AvField
                                                    onChange={handleFileChange}
                                                    name="locationOnFloorPlan"
                                                    label="Area Image "
                                                    type="file"
                                                    placeholder="Enter Valid Area Image "
                                                    errorMessage="Invalid Area Image "
                                                    validate={{
                                                      required: { value: true },
                                                    }}
                                                  />}
                                            </div>
                                            {/* get working hours */}
                                            <div className="mb-3">
                                              <Row className="">
                                                <Col>Day</Col>
                                                <Col>Opening Time</Col>
                                                <Col>Closing Time</Col>
                                              </Row>
                                              {workingHours.map((hours: any, index: any) => (
                                                <Row key={index}>
                                                  <Col><span className="w-100">{hours.day}</span></Col>
                                                  <Col><span className="w-100">{hours.openingTime}</span></Col>
                                                  <Col><span className="w-100">{hours.closingTime}</span></Col>
                                                </Row>
                                              ))}
                                              <Row>
                                                <Col>
                                                  <AvField
                                                    name="day"
                                                    value={workingDay.day}
                                                    onChange={handleWorkingDayChange}
                                                    className="w-100"
                                                    placeholder="Enter Week Day "
                                                    errorMessage="Invalid Week Day "
                                                  />
                                                </Col>
                                                <Col>
                                                  <AvField
                                                    name="openingTime"
                                                    value={workingDay.openingTime}
                                                    onChange={handleWorkingDayChange}
                                                    className="w-100"
                                                    placeholder="Enter Opening Time "
                                                    errorMessage="Invalid Opening Time "
                                                  />
                                                </Col>
                                                <Col>
                                                  <AvField
                                                    name="closingTime"
                                                    value={workingDay.closingTime}
                                                    onChange={handleWorkingDayChange}
                                                    placeholder="Enter Closing Time "
                                                    errorMessage="Invalid Closing Time "
                                                    className="w-100"
                                                  />
                                                </Col>
                                              </Row>
                                              <div className="text-center">
                                                <Button onClick={handleAddWorkingDay} className="p-1 mt-1"> + Add</Button>
                                              </div>
                                            </div>
                                          </Col>
                                        </Row>
                                        <Row>
                                          <Col>
                                            <div className="text-end">
                                              <button type="button" className="btn btn-light w-sm" onClick={handleShow}>Close</button>
                                              <button
                                                type="submit"
                                                className="btn btn-success save-user"
                                              >
                                                Save
                                              </button>
                                            </div>
                                          </Col>
                                        </Row>
                                      </AvForm>
                                    </ModalBody>
                                  </Modal>
                                </div>
                              </Col>
                            </Row>
                            <Row className="align-items-md-center mt-30">
                              <Col className="pagination pagination-rounded justify-content-end mb-2">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row >
        </Container >
      </div >
    </React.Fragment >
  );
};

export default Areas;
