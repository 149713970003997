import { createSlice } from "@reduxjs/toolkit";

import {
  getItemList,
  addNewItem,
  resetItemApiResponseFlag,
  updateItem,
  updateItemStatus,
  updateMenuItem
} from "./thunk";

interface ItemsStateType {
  itemList: Array<any>,
  itemMsgResponse: object,
  error?: string | object | null | undefined | unknown,
  itemAddUpdate: any,
  itemAdd: any,
  statusCode: any,
}
export const initialState: ItemsStateType = {
  itemList: [],
  error: {},
  itemMsgResponse: {},
  itemAddUpdate: {},
  itemAdd: {},
  statusCode: 0
};

const itemsSlice = createSlice({
  name: "items",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(addNewItem.fulfilled, (state: any, action: any) => {
      state.itemAdd = action.payload;
      state.itemMsgResponse = {
        message: action.payload ? action.payload.message : 'New Item Added Successfully',
        type: action.payload ? 'success' : 'error',
      };

    })

    builder.addCase(addNewItem.rejected, (state, action) => {
      state.error = action.payload || null;
    })

    builder.addCase(getItemList.fulfilled, (state: any, action: any) => {
      state.itemList = action.payload.data;
    })

    builder.addCase(getItemList.rejected, (state, action) => {
      state.error = action.payload || null;
    })

    builder.addCase(resetItemApiResponseFlag.fulfilled, (state, action) => {
      state.itemMsgResponse = { message: "" };
      state.itemAddUpdate = { statusCode: 0 }
      state.itemAdd = { statusCode: 0 }
    })

    builder.addCase(updateItemStatus.fulfilled, (state: any, action: any) => {
      //state.itemAddUpdate = action.payload;
      state.itemMsgResponse = {
        message: action.payload ? action.payload.message : 'Item Status Updated Successfully',
        type: action.payload ? 'success' : 'error',
      };
    })

    builder.addCase(updateItemStatus.rejected, (state, action) => {
      state.error = action.payload || null;
    })

    builder.addCase(updateMenuItem.fulfilled, (state: any, action: any) => {
      state.itemMsgResponse = {
        message: action.payload ? action.payload.message : 'Item Updated Successfully',
        type: action.payload ? 'success' : 'error',
      };
    })

    builder.addCase(updateMenuItem.rejected, (state, action) => {
      state.error = action.payload || null;
    })

    builder.addCase(updateItem.fulfilled, (state: any, action: any) => {
      state.itemAddUpdate = action.payload;
      state.itemMsgResponse = {
        message: action.payload ? action.payload.message : 'Item Updated Successfully',
        type: action.payload ? 'success' : 'error',
      };
    })

    builder.addCase(updateItem.rejected, (state, action) => {
      state.error = action.payload || null;
    })

  },
});

export default itemsSlice.reducer;