import axios from "axios";
import config  from '../config/config.json'
const API_URL = config.API_URL;
const axiosApi = axios.create({
    baseURL: API_URL,
});

// content type
axiosApi.defaults.headers.post["Content-Type"] = "application/json";

// intercepting to capture errors
axiosApi.interceptors.response.use(function (response) {
    // console.log('res api helper status', response.data.statusCode)
    if (response.data.statusCode === 401) {
        localStorage.removeItem("authUser");
        window.location.reload()
    }
    return response.data ? response.data : response;
}, function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    let message;
    switch (error.status) {
        case 500: message = "Internal Server Error"; break;
        case 401: message = "Invalid credentials"; break;
        case 404: message = "Sorry! the data you are looking for could not be found"; break;
        default: message = error.message || error;
    }
    return Promise.reject(message);
});

axiosApi.interceptors.request.use(async (request) => {
    const session = localStorage.getItem("authUser");
    if (session) {
        const obj = JSON.parse(session);
        request.headers.common["Authorization"] = `Bearer ${obj.token ? obj.token : ""}`;
    }
    return request;
});

class ApiCore {
    /**
     * Fetches data from given url
     */
    get = (url: string, params?: {}) => {
        return axiosApi.get(url, params);
    };

    /**
    * post given data to url
    */
    loginUser = (url: string, data: {}) => {
        return axiosApi.post(url, data);
    };
    /**
     * post given data to url
     */
    create = (url: string, data: {}) => {
        return axiosApi.post(url, data);
    };

    createWithMultipartItem = (url: string, data: any) => {
        console.log('data', data)
        axiosApi.defaults.headers.post["Content-Type"] = `multipart/form-data`;
        const formData = new FormData();
        formData.append("image", data.file);
        formData.append("videoFile", data.videoFile);
        delete data.file;
        delete data.videoFile;
        for (const d in data) {
            formData.append(d, data[d]);
        }
        return axiosApi.post(url, formData);
    };

    createWithMultipartUploadItem = (url: string, data: any) => {
        axiosApi.defaults.headers.post["Content-Type"] = `multipart/form-data`;
        const formData = new FormData();
        formData.append("file", data.file);
        delete data.file;
        for (const d in data) {
            formData.append(d, data[d]);
        }
        return axiosApi.post(url, formData);
    };

    createWithMultipart = (url: string, data: any) => {
        axiosApi.defaults.headers.post["Content-Type"] = `multipart/form-data`;
        const formData = new FormData();
        formData.append("floorPlanImage", data.file);
        formData.append("bannerFile", data.bannerFile);
        delete data.file;
        delete data.bannerFile;
        for (const d in data) {
            formData.append(d, data[d]);
        }
        return axiosApi.post(url, formData);
    };

    createWithMultipartStaff = (url: string, data: any) => {
        axiosApi.defaults.headers.post["Content-Type"] = `multipart/form-data`;
        const formData = new FormData();
        formData.append("profileImage", data.file);
        delete data.file;
        for (const d in data) {
            formData.append(d, data[d]);
        }
        return axiosApi.post(url, formData);
    };

    createWithMultipartVenueArea = (url: string, data: any) => {
        axiosApi.defaults.headers.post["Content-Type"] = `multipart/form-data`;
        const formData = new FormData();
        formData.append("locationOnFloorPlan", data.file);
        delete data.file;
        for (const d in data) {
            formData.append(d, data[d]);
        }
        return axiosApi.post(url, formData);
    };

    // Hardware

    createWithMultipartHardware = (url: string, data: any) => {
        axiosApi.defaults.headers.post["Content-Type"] = `multipart/form-data`;
        const formData = new FormData();
        for (const d in data) {
            formData.append(d, data[d]);
        }

        return axiosApi.post(url, formData);
    }

    updateWithMultipartHardwareLocation = (url: string, data: any) => {
        axiosApi.defaults.headers.post["Content-Type"] = `multipart/form-data`;
        const formData = new FormData();
        for (const d in data) {
            formData.append(d, data[d]);
        }
        return axiosApi.put(url, formData);
    };

    createWithMultipartCategory = (url: string, data: any) => {
        axiosApi.defaults.headers.post["Content-Type"] = `multipart/form-data`;
        const formData = new FormData();
        formData.append("image", data.file);
        delete data.file;
        for (const d in data) {
            formData.append(d, data[d]);
        }
        return axiosApi.post(url, formData);
    };

    createWithMultipartAsset = (url: string, data: any) => {
        axiosApi.defaults.headers.post["Content-Type"] = `multipart/form-data`;
        const formData = new FormData();
        formData.append("locationOnFloorPlan", data.file);
        delete data.file;
        for (const d in data) {
            formData.append(d, data[d]);
        }
        return axiosApi.post(url, formData);
    };

    updateWithMultipartAsset = (url: string, data: any) => {
        axiosApi.defaults.headers.post["Content-Type"] = `multipart/form-data`;
        const formData = new FormData();
        if (data.hasOwnProperty("file")) {
            formData.append("locationOnFloorPlan", data.file);
            delete data.file;
        }

        for (const d in data) {
            formData.append(d, data[d]);
        }
        return axiosApi.put(url, formData);
    };


    updateWithMultipart = (url: string, data: any) => {
        axiosApi.defaults.headers.post["Content-Type"] = `multipart/form-data`;
        const formData = new FormData();
        if (data.hasOwnProperty("file")) {
            formData.append("floorPlanImage", data.file);
            delete data.file;
        }
        if (data.hasOwnProperty("bannerFile")) {
            formData.append("bannerFile", data.bannerFile);
            delete data.bannerFile;
        }
        for (const d in data) {
            formData.append(d, data[d]);
        }
        return axiosApi.put(url, formData);
    };

    updateWithMultipartVenueArea = (url: string, data: any) => {
        axiosApi.defaults.headers.post["Content-Type"] = `multipart/form-data`;
        const formData = new FormData();
        if (data.hasOwnProperty("file")) {
            formData.append("locationOnFloorPlan", data.file);
            delete data.file;
        }

        for (const d in data) {
            formData.append(d, data[d]);
        }
        return axiosApi.put(url, formData);
    };

    updateWithMultipartCategory = (url: string, data: any) => {
        axiosApi.defaults.headers.post["Content-Type"] = `multipart/form-data`;
        const formData = new FormData();
        if (data.hasOwnProperty("file")) {
            formData.append("image", data.file);
            delete data.file;
        }
        for (const d in data) {
            formData.append(d, data[d]);
        }
        return axiosApi.put(url, formData);
    };

    updateWithMultipartItem = (url: string, data: any) => {
        axiosApi.defaults.headers.post["Content-Type"] = `multipart/form-data`;
        const formData = new FormData();
        if (data.hasOwnProperty("file")) {
            formData.append("image", data.file);
            delete data.file;
        }
        if (data.hasOwnProperty("videoFile")) {
            formData.append("videoFile", data.videoFile);
            delete data.videoFile;
        }
        for (const d in data) {
            formData.append(d, data[d]);
        }
        return axiosApi.put(url, formData);
    };

    updateWithMultipartStaff = (url: string, data: any) => {
        axiosApi.defaults.headers.post["Content-Type"] = `multipart/form-data`;
        const formData = new FormData();
        if (data.hasOwnProperty("file")) {
            formData.append("image", data.file);
            delete data.file;
        }
        for (const d in data) {
            formData.append(d, data[d]);
        }
        return axiosApi.put(url, formData);
    };

    /**
     * Updates data
     */
    update = (url: string, data: {}) => {
        return axiosApi.put(url, data);
    };

    /**
    * Updates data with Patch 
    */
    updatePatch = (url: string, data: {}) => {
        return axiosApi.patch(url, data);
    };

    // put request
    put = (url: string, data: {}) => {
        return axiosApi.put(url, data);
    };

    /**
     * Delete 
     */
    delete = (url: string, data?: {}) => {
        return axiosApi.delete(url, { ...data });
    };
};

export { ApiCore };