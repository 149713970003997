import { createSlice } from "@reduxjs/toolkit";

import {
    getMenuArea,
    getMenuAreaDetail,
    updateMenuArea,
    addNewMenuArea,
    deleteMenuArea,
    resetMenuAreaApiResponseFlag,
    getMenuListData
} from "./thunk";

interface MenuAreaStateType {
    menuArea: Array<any>,
    menuItemsList: Array<any>,
    menuAreaDetail: Array<any>,
    menuAreaMsgResponse: object,
    error?: string | object | null | undefined | unknown,
    menuAreaAddUpdate: any
}

export const initialState: MenuAreaStateType = {
    menuArea: [],
    menuItemsList: [],
    menuAreaDetail: [],
    error: {},
    menuAreaMsgResponse: {},
    menuAreaAddUpdate: {}
};

const menuAreaSlice = createSlice({
    name: "menuAreas",
    initialState,
    reducers: {},
    extraReducers: (builder) => {

        // Add New menu Area
        builder.addCase(addNewMenuArea.fulfilled, (state: any, action: any) => {
            state.menuAreaAddUpdate = action.payload;
            state.menuAreaMsgResponse = {
                message: action.payload ? action.payload.message : 'New Menu Area Added Successfully',
                type: action.payload ? 'success' : 'error',
            };
        })
        builder.addCase(addNewMenuArea.rejected, (state, action) => {
            state.error = action.payload || null;
        })

        // Get menu Areas
        builder.addCase(getMenuArea.fulfilled, (state: any, action: any) => {
            state.menuArea = action.payload.data;
        })
        builder.addCase(getMenuArea.rejected, (state, action) => {
            state.error = action.payload || null;
        })

        // Get menu Areas
        builder.addCase(getMenuListData.fulfilled, (state: any, action: any) => {
            state.menuItemsList = action.payload.data;
        })
        builder.addCase(getMenuListData.rejected, (state, action) => {
            state.error = action.payload || null;
        })

        // Get menu Areas Detail
        builder.addCase(getMenuAreaDetail.fulfilled, (state: any, action: any) => {
            state.menuAreaDetail = action.payload.data;
        })
        builder.addCase(getMenuAreaDetail.rejected, (state, action) => {
            state.error = action.payload || null;
        })

        // Menu Msg Res Flag
        builder.addCase(resetMenuAreaApiResponseFlag.fulfilled, (state, action) => {
            state.menuAreaMsgResponse = { message: "" };
        })

        // Update menu Area
        builder.addCase(updateMenuArea.fulfilled, (state: any, action: any) => {
            state.menuAreaAddUpdate = action.payload;
            state.menuAreaMsgResponse = {
                message: action.payload ? action.payload.message : 'Menu Area Updated Successfully',
                type: action.payload ? 'success' : 'error',
            };
        })
        builder.addCase(updateMenuArea.rejected, (state, action) => {
            state.error = action.payload || null;
        })

        // Delete Menu Area
        builder.addCase(deleteMenuArea.fulfilled, (state: any, action: any) => {
            state.venueAreaAddUpdate = action.payload;
            state.venueAreaMsgResponse = {
                message: action.payload ? action.payload.message : 'Menu Area Deleted Successfully',
                type: action.payload ? 'success' : 'error',
            };
        })
        builder.addCase(deleteMenuArea.rejected, (state, action) => {
            state.error = action.payload || null;
        })
    },
});

export default menuAreaSlice.reducer;