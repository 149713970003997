import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  getSubCategories as getSubCategoriesTypeApi,
  updateSubCategoryStatus as updateSubCategoryStatusApi,
  addNewSubCategory as addNewSubCategoryApi,
  updateSubCategory as updateSubCategoryApi
} from "../../helpers/backend_helper";

export const getSubCategoriesType = createAsyncThunk(
  "subCategories/getSubCategoriesType",
  async (event: any) => {
    try {
      const response = getSubCategoriesTypeApi(event);
      return response;
    } catch (error) {
      return error;
    }
  }
);


export const addNewSubCategoryType = createAsyncThunk(
  "subCategories/addNewSubCategory",
  async (event: any) => {
    try {
      const response = addNewSubCategoryApi(event);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const updateSubCategoryType = createAsyncThunk(
  "subCategories/updateSubCategory",
  async (event: any) => {
    try {
      const response = updateSubCategoryApi(event);
      const modifiedEvent = await response;
      return modifiedEvent;
    } catch (error) {
      return error;
    }
  }
);

export const updateSubCategoryTypeStatus = createAsyncThunk(
  "subCategories/updateSubCategoryTypeStatus",
  async (event: any) => {
    try {
      const response = updateSubCategoryStatusApi(event);
      const modifiedEvent = await response;
      return modifiedEvent;
    } catch (error) {
      return error;
    }
  }
);

export const resetApiSubCategoryTypeResponseFlag = createAsyncThunk(
  "subCategories/resetApiSubCategoryResponseFlag",
  async () => {
    try {
      return true;
    } catch (error) {
      return error;
    }
  }
);
