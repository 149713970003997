import { createSlice } from "@reduxjs/toolkit";

import {
  getHardwareLocation,
  getHardwareLocationDetail,
  updateHardwareLocationStatus,
  addNewHardwareLocation,
  updateHardwareLocation,
  resetHardwareLocationApiResponseFlag
} from "./thunk";

interface hardwareLocationStateType {
    hardwareLocation: Array<any>,
    hardwareLocationDetail: Array<any>,
    hardwareLocationMsgResponse: object,
    error?: string | object | null | undefined | unknown,
    hardwareLocationAddUpdate: any
}

export const initialState: hardwareLocationStateType = {
    hardwareLocation: [],
    hardwareLocationDetail: [],
    error: {},
    hardwareLocationMsgResponse: {},
    hardwareLocationAddUpdate: {}
};  


const hardwareLocationSlice = createSlice({
name: "hardwareLocations",
initialState,
reducers: {},
extraReducers: (builder) => {

    // Add New Hardware Location
    builder.addCase(addNewHardwareLocation.fulfilled, (state: any, action: any) => {
    state.hardwareLocationAddUpdate = action.payload;
    state.hardwareLocationMsgResponse = {
        message:action.payload ? action.payload.message : 'Hardware Location Added Successfully',
        type: action.payload ? 'success' : 'error',
    };
    })
    builder.addCase(addNewHardwareLocation.rejected, (state, action) => {
    state.error = action.payload || null;
    })

    // Get Hardware Location
    builder.addCase(getHardwareLocation.fulfilled, (state: any, action: any) => {
    state.hardwareLocation = action.payload.data;
    })
    builder.addCase(getHardwareLocation.rejected, (state, action) => {
    state.error = action.payload || null;
    })

    // Get Hardware Location Detail
    builder.addCase(getHardwareLocationDetail.fulfilled, (state: any, action: any) => {
        state.hardwareLocationDetail = action.payload.data;
    });
    builder.addCase(getHardwareLocationDetail.rejected, (state, action) => {
    state.error = action.payload || null;
    })

    // Hardware Location Msg Res
    builder.addCase(resetHardwareLocationApiResponseFlag.fulfilled, (state, action) => {
    state.hardwareLocationMsgResponse = { message: "" };
    })

    // Update Status
    builder.addCase(updateHardwareLocationStatus.fulfilled, (state: any, action: any) => {
    state.hardwareLocationAddUpdate = action.payload;
    state.hardwareLocationMsgResponse = {
        message:action.payload ? action.payload.message : 'Hardware Location Status Updated Successfully',
        type: action.payload ? 'success' : 'error',
    };
    })
    builder.addCase(updateHardwareLocationStatus.rejected, (state, action) => {
    state.error = action.payload || null;
    
    })

    // Update Venue Area
    builder.addCase(updateHardwareLocation.fulfilled, (state: any, action: any) => {
    state.hardwareLocationAddUpdate = action.payload;
    state.hardwareLocationMsgResponse = {
        message:action.payload ? action.payload.message : 'Hardware Location Updated Successfully',
        type: action.payload ? 'success' : 'error',
    };
    })
    builder.addCase(updateHardwareLocation.rejected, (state, action) => {
    state.error = action.payload || null;
    })
},
});

export default hardwareLocationSlice.reducer;