import { Redirect } from "react-router-dom";

//Authentication pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import VenueLogin from "../pages/Authentication/VenueLogin";

//Pages
import Home from "../pages/Home"
import Sales from "../pages/Sales";
import Categories from "../pages/Categories"
import Venues from "../pages/Venues"
import Areas from "../pages/Areas"
import Assets from "../pages/Assets"
import OrderList from "../pages/Orders"
import Items from "../pages/Items"
import Hardwares from "../pages/Hardware"
import HardwareLocation from "src/pages/HardwareLocation";
import MenuArea from "src/pages/MenuArea";
import Kitchen from "src/pages/Kitchen";
import CategoriesType from "src/pages/CategoriesType";
import SubCategories from "src/pages/SubCategories";
import ItemSize from "src/pages/ItemSize";
import ServingOrder from "src/pages/ServingOrder";
import Staff from "../pages/Staff"
import Notification from "../pages/Notifications"
import VenueKDS from "../pages/VenueKds"
import Reviews from "../pages/Reviews"
import AddOns from "../pages/AddOns"

interface RouteProps {
  path: string;
  component: any;
  exact?: boolean;
}

const userRoutes: Array<RouteProps> = [
  //dashboard
  { path: "/home", component: Home },
  { path: "/sales", component: Sales },

  //Pages
  { path: "/menu/addons", component: AddOns },
  { path: "/menu/categories", component: Categories },
  { path: "/menu/subCategories", component:  SubCategories},
  { path: "/menu/categoriesType", component: CategoriesType },
  { path: "/menu/itemSize", component: ItemSize },
  { path: "/menu/servingOrder", component: ServingOrder },
  { path: "/venue", component: Venues },
  { path: "/staff", component: Staff },
  { path: "/area", component: Areas },
  { path: "/assets", component: Assets },
  { path: "/assets/kitchen", component: Kitchen },
  { path: "/menu/items", component: Items },
  { path: "/orders", component: OrderList },
  { path: "/hardware", component: Hardwares },
  { path: "/hardwareLocation", component: HardwareLocation },
  { path: "/menuArea", component: MenuArea },
  { path: "/notifications", component: Notification },
  { path: "/venuekds", component: VenueKDS },
  { path: "/reviews", component: Reviews },


  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/home" /> }
];

const authRoutes: Array<RouteProps> = [
  //Authentication pages
  { path: "/login", component: Login },
  { path: "/venue-login", component: VenueLogin },
  { path: "/logout", component: Logout },
];

export { userRoutes, authRoutes };
