import React, { useEffect, useState, ChangeEvent } from "react";
import MetaTags from "react-meta-tags";
import { withRouter, Link } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  UncontrolledAlert,
  Table,
  Input,
  Label,
  CustomInput
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import {
  getStaffList as onGetStaffList,
  getModuleList as onGetModuleList,
  updateStaffStatus as onUpdateStaffStatus,
  addNewStaff as onAddNewStaff,
  updateStaff as onUpdateStaff,
  resetStaffApiResponseFlag as onResetApiResponseFlag,
  getCountryList as onGetCountryList,
  getStateList as onGetStateList,
  getCityList as onGetCityList,
  getVenueList as onGetVenueList,
  getVenueArea as onGetVenueArea,
  updateStaffPermissions as OnUpdateStaffPermissions,
  logoutUser
} from "../../slices/thunks";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { isEmpty } from "lodash";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import { ToastContainer, toast } from 'react-toastify';

interface LogoutProps {
  history: any;
}
import DeleteModal from "../Common/DeleteModal";
//redux
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";

const Staff = ({ history }: LogoutProps) => {
  const dispatch = useDispatch();
  const { SearchBar } = Search;

  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [venueEvent, setVenueEvent] = useState<any>({});
  const [permissionEvent, setPermissionEvent] = useState<any>([]);
  const [countryCode, setCountryCode] = useState<any>('');
  const [messgeStatus, setMessgeStatus] = useState<any>('');
  const [stateCode, setStateCode] = useState<any>('');
  const [modal, setModal] = useState<boolean>(false);
  const [permissionModel, setPermissionModel] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [isFlag, setIsFlag] = useState<boolean>(true);
  const [otherCountry, setOtherCountry] = useState<boolean>(false);
  const [otherState, setOtherState] = useState<boolean>(false);
  const [otherCity, setOtherCity] = useState<boolean>(false);
  const [file, setFile] = useState<File>();
  const [groupModel, setGroupModel] = useState<boolean>(false);
  const [groupEvent, setGroupEvent] = useState<any>([]);
  // const formData = new FormData();
  const handleShow = () => setModal(false);
  const handleShowClose = () => setPermissionModel(false);
  const handleShowCloseView = () => setGroupModel(false);

  const { staffList, staffMsgResponse, staffAddUpdate, countryList, stateList, cityList, statusCode, venueList, venueArea, moduleList } = useSelector((state: any) => ({
    staffList: state.staff.staffList,
    staffMsgResponse: state.staff.staffMsgResponse,
    staffAddUpdate: state.staff.staffAddUpdate,
    countryList: state.locations.countryList,
    stateList: state.locations.stateList,
    cityList: state.locations.cityList,
    statusCode: state.staff.statusCode,
    venueList: state.venues.venueList,
    venueArea: state.areas.venueArea,
    moduleList: state.staff.moduleList,
  }));
  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
      // formData.append("floorPlanImage", e.target.files[0]);
    }
  };

  // get country code
  const handleCountryChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedOption = event.target.options[event.target.selectedIndex];
    const eventValue = selectedOption.value;
    const countryCode = selectedOption.getAttribute('data-label');

    if (eventValue === 'other') {
      setOtherCountry(true)
    }
    else {
      setOtherCountry(false)
    }
    setCountryCode(countryCode);
  };
  // state click
  const handleCountryClick = () => {
    dispatch(onGetStateList({ countryCode: `${countryCode}` }))
    handleStateClick()
  }

  // get state code
  const handleStateChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedOption = event.target.options[event.target.selectedIndex];
    const eventValue = selectedOption.value;
    const stateCode = selectedOption.getAttribute('data-label');
    if (eventValue === 'other') {
      setOtherState(true)
    }
    else {
      setOtherState(false)
    }
    setStateCode(stateCode);
  };
  // state click
  const handleStateClick = () => {
    dispatch(onGetCityList({ countryCode: `${countryCode}`, stateCode: `${stateCode}` }))
  }

  // get state code
  const handleCityChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedOption = event.target.options[event.target.selectedIndex];
    const eventValue = selectedOption.value;
    if (eventValue === 'other') {
      setOtherCity(true)
    }
    else {
      setOtherCity(false)
    }
  };

  // Handle other input value change
  const handleOtherInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setVenueEvent((prevData: any) => ({ ...prevData, [name]: value }));
  };

  const pageOptions = {
    sizePerPage: 10,
    totalSize: staffList.length, // replace later with size(users),
    custom: true,
  };

  const defaultSorted: any = [
    {
      dataField: "_id", // if dataField is not match to any column you defined, it will be ignored.
      order: "asc", // desc or asc
    },
  ];

  const filterArea = (venueId: string) => {
    dispatch(onGetVenueArea({ id: venueId }));
  };

  // ----- Use Effect : Start ----------

  // ----- 1. Get Venue List -----------
  useEffect(() => {
    dispatch(onGetStaffList());
    dispatch(onGetVenueList());
    // if(statusCode == 401){
    //   localStorage.removeItem("authUser");
    //   history.push("/login");
    // }
  }, [dispatch, history]);

  // ----- 2. Get Response Message -----
  useEffect(() => {
    const timer = setTimeout(() => {
      dispatch(onResetApiResponseFlag());
    }, 5000);
    return () => clearTimeout(timer);
  }, [staffMsgResponse]);

  // ------- Use Effect : End -----

  const handleDeleteClick = (event: any) => {
    setVenueEvent({
      id: event._id,
      status: event.status == 1 ? 0 : 1
    });
    setDeleteModal(true);
  };
  /**
  * On delete event
  */
  const handleDeleteVenue = () => {
    dispatch(onUpdateStaffStatus(venueEvent));
    setDeleteModal(false);
  };

  const handleDeleteClose = () => {
    setDeleteModal(false)
    setVenueEvent({})
  };

  const handleValidFormSubmit = (values: any) => {
    if (isEdit) {
      values["id"] = venueEvent._id
      console.log(values)
      if (file?.size) {
        values['file'] = file;
      }
      // save edit user
      dispatch(onUpdateStaff(values));
      setIsFlag(true)
    } else {
      values['file'] = file;
      // save new Venue
      dispatch(onAddNewStaff(values));
      setIsFlag(true)
    }
    toggle();
  };

  const handleValidFormSubmitPermission = (values: any) => {
    const module: any = []
    for (let i = 0; i < moduleList.length; i++) {
      const permissionKey = `permission${i}`;
      if (values[moduleList[i]['moduleName']] === true) {
        if (values.hasOwnProperty(permissionKey)) {
          let modulePermission: any = {
            id: moduleList[i]['_id'],
            moduleTitle: moduleList[i]['moduleTitle'],
            moduleName: moduleList[i]['moduleName'],
            url: moduleList[i]['url'],
            action: values[permissionKey]
          }
          module.push(modulePermission);
        }
      }
    }
    const obj: any = { module: JSON.stringify(module), id: `${venueEvent._id}` }
    dispatch(OnUpdateStaffPermissions(obj));
  };


  if (Object.keys(staffAddUpdate).length > 0) {
    if (staffAddUpdate.statusCode == 400) {
      if (isFlag) {
        setModal(true)
        setIsFlag(false)
        toast.error(staffAddUpdate.message)
      }
    } else {
      toast.success(staffAddUpdate.message)
      setTimeout(function () {
        window.location.reload();
      }, 2000);
    }
  }

  const toggle = () => {
    setModal(!modal);
    if (!modal && !isEmpty(venueEvent) && !!isEdit) {
      setTimeout(() => {
        setVenueEvent({})
        setIsEdit(false);
      }, 500);
    }
  };

  const handleUserClicks = () => {
    dispatch(onGetCountryList())
    setIsEdit(false);
    toggle();
  };

  const handleEditVenueClick = (event: any) => {
    dispatch(onGetCountryList())

    // Need Change Here \/ set locations when edit
    setOtherState(true)
    setOtherCity(true)

    setVenueEvent({
      ...event,
      country: `${event.location.country}`,
      state: `${event.location.state}`,
      city: `${event.location.city}`,
      zip: `${event.location.zip}`,
      address: `${event.location.address}`
    });
    setIsEdit(true);
    toggle();
  };

  const handleModulePermissionClick = (event: any) => {
    dispatch(onGetModuleList())
    setPermissionModel(true)
    setVenueEvent({
      ...event,
      country: `${event.location.country}`,
      state: `${event.location.state}`,
      city: `${event.location.city}`,
      zip: `${event.location.zip}`,
      address: `${event.location.address}`
    });
  };


  const handleViewGroup = (event: any) => {
    setGroupEvent(event.module);
    setGroupModel(true)
  };

  const venuesListColumns = [
    {
      text: "NAME(CODE)",
      dataField: "name",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent: any, venue: any) => <React.Fragment><img
        className="avatar-sm rounded-circle me-2"
        src={venue.profileImage}
        alt=""
      />{venue.firstName} {venue.lastName} ({venue.staffCode})</React.Fragment>,
    },
    {
      text: "ROLE",
      dataField: "role",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent: any, venue: any) => <React.Fragment>{venue.role}</React.Fragment>,
    },
    {
      text: "VENUE",
      dataField: "venue",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent: any, venue: any) => <React.Fragment>{venue.venue}</React.Fragment>,
    },
    {
      text: "VENUE AREA",
      dataField: "venueArea",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent: any, venue: any) => <React.Fragment><div className="text-danger">{venue.venueArea}</div></React.Fragment>,
    },
    {
      text: "EMAIL",
      dataField: "email",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent: any, venue: any) => <React.Fragment>{venue.email}</React.Fragment>,
    },
    {
      text: "CITY",
      dataField: "city",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent: any, venue: any) => <React.Fragment>{venue.location.city}</React.Fragment>,
    },
    {
      text: "PIN",
      dataField: "pin",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent: any, venue: any) => <React.Fragment>{venue.pin}</React.Fragment>,
    },
    {
      text: "Permission",
      dataField: "view",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent: any, venue: any) => <React.Fragment>
        <Button onClick={() => handleModulePermissionClick(venue)}>Module Permission</Button>
        <div> <Button className="badge btn-success btn-sm" onClick={() => handleViewGroup(venue)}> View Permission</Button></div>
      </React.Fragment>,
    },
    {
      text: "CREATED DATE",
      dataField: "createDate",
      sort: true,
      // eslint-disable-next-line react/display-name 
      formatter: (cellContent: any, venue: any) => <React.Fragment>{moment(venue.createDate).format('DD MMM Y')}</React.Fragment>,
    },
    {
      text: "STATUS",
      dataField: "status",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent: any, venue: any) => (
        <React.Fragment>
          {venue.status == 1 ?
            <div className="badge bg-success font-size-12">{"Active"}</div>
            : <div className="badge bg-warning font-size-12">{"Inactive"}</div>}
        </React.Fragment>
      ),
    },
    {
      text: "ACTION",
      dataField: "action",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent: any, venue: any) => (
        <React.Fragment>
          <UncontrolledDropdown>
            <DropdownToggle tag="button" className="btn btn-light btn-sm">
              <i className="uil uil-ellipsis-h"></i>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-end">
              <DropdownItem to="#" onClick={() => handleEditVenueClick(venue)}>Edit</DropdownItem>
              <DropdownItem to="#" onClick={() => handleModulePermissionClick(venue)}>Module Permission</DropdownItem>
              <DropdownItem to="#" onClick={() => handleDeleteClick(venue)}>{venue.status == 1 ? "Inactive" : "Active"}</DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </React.Fragment>
      ),
    },
  ];

  return (
    <React.Fragment>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteVenue}
        onCloseClick={handleDeleteClose}
        deleteButtonLable={venueEvent.status == 0 ? "Inactive" : "Active"}
      />
      <div className="page-content">
        <MetaTags>
          <title>Staff | Retaurant Booking</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Restaurant" breadcrumbItem="Staff" />
          {staffMsgResponse && staffMsgResponse.message ? (
            <UncontrolledAlert color="primary">
              {staffMsgResponse.message}
            </UncontrolledAlert>
          ) : null}
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="_id"
                        data={staffList}
                        columns={venuesListColumns}
                        bootstrap4
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <Row className="align-items-start">
                              <div className="col-sm">
                                <div>
                                  <Button color="light" onClick={handleUserClicks} className="mb-4 button-success">
                                    <i className="mdi mdi-plus me-1"></i>
                                    Add Staff</Button>
                                  {/* <Button color="light" className="mb-4">
                                    <i className="mdi mdi-plus me-1"></i>
                                    Add Area Venue</Button> */}
                                </div>
                              </div>
                            </Row>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                    defaultSorted={defaultSorted}
                                    classes={
                                      "table align-middle table-nowrap table-hover"
                                    }
                                    bordered={false}
                                    striped={false}
                                  />
                                  <Modal isOpen={modal} toggle={toggle} size="lg">
                                    <ModalHeader toggle={toggle} tag="h4">
                                      {!!isEdit ? "Edit Staff" : "Add Staff"}
                                    </ModalHeader>
                                    <ModalBody>
                                      <AvForm
                                        encType='multipart/form-data'
                                        onValidSubmit={(
                                          e: any,
                                          values: any
                                        ) => {
                                          handleValidFormSubmit(values);
                                        }}
                                      >
                                        <Row form>
                                          <Row >
                                            <Col xs={6} md={6}>
                                              <AvField
                                                name="firstName"
                                                label="First Name"
                                                type="text"
                                                placeholder="Enter Valid First Name"
                                                errorMessage="Invalid First Name"
                                                validate={{
                                                  required: { value: true, },
                                                }}
                                                value={venueEvent.firstName || ""}
                                              />
                                            </Col>
                                            <Col xs={6} md={6}>
                                              <AvField
                                                name="lastName"
                                                label="Last Name"
                                                type="text"
                                                placeholder="Enter Valid Last Name"
                                                errorMessage="Invalid Last Name"
                                                validate={{
                                                  required: { value: true, },
                                                }}
                                                value={venueEvent.lastName || ""}
                                              />
                                            </Col>
                                            <Col xs={6} md={6}>
                                              <AvField
                                                name="staffCode"
                                                label="Staff Code"
                                                type="text"
                                                placeholder="Enter Valid Staff Code"
                                                errorMessage="Invalid Staff Code"
                                                validate={{
                                                  required: { value: true, },
                                                }}
                                                value={venueEvent.staffCode || ""}
                                              />
                                            </Col>
                                            <Col xs={6} md={6}>
                                              <AvField
                                                name="phoneNumber"
                                                label="Phone Number"
                                                type="text"
                                                placeholder="Enter Valid Phone Number"
                                                errorMessage="Invalid Phone Number"
                                                validate={{
                                                  required: { value: true, },
                                                }}
                                                value={venueEvent.phoneNumber || ""}
                                              />
                                            </Col>
                                            <Col xs={6} md={6}>

                                              <AvField
                                                type="select"
                                                name="venueId"
                                                placeholder="Select Venue"
                                                label="Venue"
                                                multiple={false}
                                                required
                                                onChange={(e: any) => filterArea(e.target.value)}
                                                value={venueEvent.venueId || ""}
                                              >
                                                <option key='blankChoice' hidden value=""> Select Venue </option>
                                                {
                                                  venueList.map((venue: any, index: number) => (
                                                    <option value={venue._id} key={index}>{venue.name}</option>
                                                  ))}
                                              </AvField>

                                            </Col>
                                            <Col xs={6} md={6}>

                                              <AvField
                                                type="select"
                                                name="venueAreasId"
                                                placeholder="Select Venue Area"
                                                label="Venue Area (Optional)"
                                                multiple={true}
                                                value={venueEvent.venueAreasId || ""}
                                              >
                                                <option key='blankChoice' hidden value=""> Select Venue Area </option>
                                                {
                                                  venueArea.map((area: any, index: number) => (
                                                    <option value={area._id} key={index}>{area.areaName}</option>
                                                  ))}
                                              </AvField>

                                            </Col>
                                            <Col xs={6} md={6}>
                                              <AvField
                                                name="email"
                                                label="Email"
                                                type="email"
                                                placeholder="Enter Valid Email "
                                                errorMessage="Invalid Email "
                                                validate={{
                                                  required: { value: true },
                                                }}
                                                value={venueEvent.email || ""}
                                              />
                                            </Col>
                                            <Col xs={6} md={6}>
                                              <AvField
                                                name="password"
                                                label="Password"
                                                type="password"
                                                placeholder="Enter Valid Password "

                                                validate={!isEdit ? {
                                                  required: { value: true, errorMessage: "Invalid Password" },
                                                  pattern: { value: '/[*@!#%&()^~{ }]+/', errorMessage: 'Your pasword must be composed with one special characters' },
                                                  minLength: { value: 8, errorMessage: 'Your password must be between 6 and 16 characters' },
                                                  maxLength: { value: 16, errorMessage: 'Your password must be between 6 and 16 characters' }
                                                } : { required: { value: false } }}
                                                value={""}
                                              />
                                            </Col>
                                            <Col xs={6} md={6}>
                                              <AvField
                                                name="pin"
                                                label="PIN"
                                                type="text"
                                                placeholder="Enter Valid Pin "
                                                errorMessage="Invalid Pin "
                                                validate={{
                                                  required: { value: true },
                                                }}
                                                value={venueEvent.pin || ""}
                                              />
                                            </Col>
                                            <Col xs={6} md={6}>
                                              <AvField
                                                name="description"
                                                label="Description"
                                                type="textarea"
                                                placeholder="Enter Valid Description "
                                                errorMessage="Invalid Description "
                                                validate={{
                                                  required: { value: true },
                                                }}
                                                value={venueEvent.description || ""}
                                              />
                                            </Col>
                                            <Col xs={6} md={6}>
                                              <AvField
                                                type="select"
                                                name="role"
                                                placeholder="Select Role"
                                                errorMessage="Select valid Role"
                                                label="Role"
                                                multiple={false}
                                                required
                                                value={venueEvent.role || ""}
                                              >
                                                <option key='blankChoice1' hidden value=""> Select Role </option>
                                                <option key='blankChoice2' value="Staff">Staff</option>
                                                <option key='blankChoice3' value="Cashier">Cashier</option>
                                                <option key='blankChoice4' value="Waiters">Waiters</option>
                                                <option key='blankChoice5' value="Kitchen-staff">Kitchen-staff</option>
                                                <option key='blankChoice6' value="Manager">Manager</option>
                                                <option key='blankChoice7' value="Kitchen-manager">Kitchen-manager</option>
                                                <option key='blankChoice8' value="Restaurant-manager">Restaurant-manager</option>
                                                <option key='blankChoice9' value="Server">Server</option>
                                                <option key='blankChoice10' value="Hosts">Hosts</option>
                                                <option key='blankChoice11' value="Delivery">Delivery</option>
                                              </AvField>
                                            </Col>
                                            <Col xs={6} md={6}>
                                              <AvField
                                                name="address"
                                                label="Address"
                                                type="text"
                                                placeholder="Enter Valid address "
                                                errorMessage="Invalid Address "
                                                validate={{
                                                  required: { value: true },
                                                }}
                                                value={venueEvent.address || ""}
                                              />
                                            </Col>
                                            <Col xs={6} md={6}>
                                              <AvField
                                                name="country"
                                                label="Country"
                                                type="select"
                                                placeholder="Select Country"
                                                errorMessage="Select valid Country"
                                                multiple={false}
                                                required
                                                value={venueEvent.country || ""}
                                                onClick={handleCountryClick}
                                                onChange={handleCountryChange}
                                              >
                                                <option key='blankChoice1' hidden value=""> Select Country </option>
                                                {
                                                  countryList.map((country: any, key: any) => (
                                                    <option value={country.name} data-label={country.iso2} key={key}>{country.name}</option>
                                                  ))}
                                                <option key='otherCountry' value="other"> Other </option>
                                              </AvField>
                                            </Col>
                                            <Col xs={6} md={6}>
                                              {otherCountry &&
                                                <AvField
                                                  name="country"
                                                  type="text"
                                                  placeholder="Enter Valid country "
                                                  errorMessage="Invalid country "
                                                  validate={{
                                                    required: { value: true },
                                                  }}
                                                  onChange={handleOtherInputChange}
                                                  value={venueEvent.country || ""}
                                                />
                                              }
                                            </Col>
                                            <Col xs={6} md={6}>
                                              <AvField
                                                name="state"
                                                label="State Name"
                                                type="select"
                                                placeholder="Select State"
                                                errorMessage="Select valid State"
                                                multiple={false}
                                                required
                                                value={venueEvent.state || ""}
                                                onClick={handleStateClick}
                                                onChange={handleStateChange}
                                              >
                                                <option key='blankChoice1' hidden value=""> Select State </option>
                                                {
                                                  stateList.map((state: any, key: any) => (
                                                    <option value={state.name} data-label={state.id} key={key}>{state.name}</option>
                                                  ))}
                                                <option key='otherState' value="other"> Other </option>
                                              </AvField>
                                              {otherState &&
                                                <AvField
                                                  name="state"
                                                  type="text"
                                                  placeholder="Enter Valid state "
                                                  errorMessage="Invalid state "
                                                  validate={{
                                                    required: { value: true },
                                                  }}
                                                  onChange={handleOtherInputChange}
                                                  value={venueEvent.state || ""}
                                                />
                                              }
                                            </Col>
                                            <Col xs={6} md={6}>
                                              <AvField
                                                name="city"
                                                label="City Name"
                                                type="select"
                                                placeholder="Select City"
                                                errorMessage="Select valid City"
                                                multiple={false}
                                                required
                                                onChange={handleCityChange}
                                                value={venueEvent.city || ""}
                                              >
                                                <option key='blankChoice1' hidden value=""> Select City </option>
                                                {
                                                  cityList.map((city: any, key: any) => (
                                                    <option value={city.name} key={key}>{city.name}</option>
                                                  ))}
                                                <option key='otherState' value="other"> Other </option>
                                              </AvField>
                                              {otherCity &&
                                                <AvField
                                                  name="city"
                                                  type="text"
                                                  placeholder="Enter Valid City "
                                                  errorMessage="Invalid city "
                                                  validate={{
                                                    required: { value: true },
                                                  }}
                                                  onChange={handleOtherInputChange}
                                                  value={venueEvent.city || ""}
                                                />
                                              }
                                            </Col>
                                            <Col xs={6} md={6}>
                                              <AvField
                                                name="zip"
                                                label="Zip"
                                                type="text"
                                                placeholder="Enter Valid zip "
                                                errorMessage="Invalid zip "
                                                validate={{
                                                  required: { value: true },
                                                }}
                                                value={venueEvent.zip || ""}
                                              />
                                            </Col>
                                            <Col xs={6} md={6}>
                                              {
                                                !!isEdit ?
                                                  <AvField
                                                    onChange={handleFileChange}
                                                    name="profileImage"
                                                    label="Staff Image "
                                                    type="file"
                                                    placeholder="Enter Valid Image "
                                                  />
                                                  :
                                                  <AvField
                                                    onChange={handleFileChange}
                                                    name="profileImage"
                                                    label="Staff Image "
                                                    type="file"
                                                    placeholder="Enter Valid Image "
                                                    errorMessage="Invalid Image "
                                                    validate={{
                                                      required: { value: true },
                                                    }}
                                                  />}
                                            </Col>
                                          </Row>
                                        </Row>
                                        <Row>
                                          <Col>
                                            <div className="text-end">
                                              <button type="button" className="btn btn-danger" onClick={handleShow}>Close</button>
                                              <button
                                                type="submit"
                                                className="btn btn-success save-user"
                                              >
                                                Save
                                              </button>
                                            </div>
                                          </Col>
                                        </Row>
                                      </AvForm>
                                    </ModalBody>
                                  </Modal>
                                  <Modal isOpen={permissionModel} toggle={handleShowClose} size="lg">

                                    <ModalHeader toggle={handleShowClose}>Module Permission</ModalHeader>
                                    <ModalBody>

                                      <AvForm onValidSubmit={(
                                        e: any,
                                        values: any
                                      ) => {
                                        handleValidFormSubmitPermission(values);
                                      }} >

                                        <Row form>
                                          <Table responsive>
                                            <thead>
                                              <tr>
                                                <th>
                                                  #
                                                </th>
                                                <th>
                                                  Sections
                                                </th>
                                                <th>
                                                  Action (View,List)
                                                </th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {
                                                moduleList.map((m: any, key: any) => (

                                                  <tr key={key}>
                                                    <th scope="row">
                                                      {key + 1}
                                                    </th>
                                                    <td>
                                                      {m.moduleTitle}
                                                      {/* <AvField
                                                type="hidden"
                                                name="id"
                                                value={_id}
                                              /> */}
                                                      <AvField
                                                        type="checkbox"
                                                        name={m.moduleName}
                                                        value={m}
                                                      />
                                                    </td>
                                                    <td>
                                                      <AvField
                                                        type="checkbox"
                                                        name={"permission" + key}
                                                      />
                                                    </td>
                                                  </tr>
                                                ))
                                              }
                                            </tbody>
                                          </Table>
                                          <Row>
                                            <Col>
                                              <div className="text-end">
                                                <button
                                                  type="submit"
                                                  className="btn btn-success save-user"
                                                >
                                                  Save
                                                </button>
                                                <Button color="secondary" onClick={handleShowClose}>
                                                  Cancel
                                                </Button>
                                              </div>
                                            </Col>
                                          </Row>
                                        </Row>
                                      </AvForm>
                                    </ModalBody>
                                    {/* <ModalFooter>
                                      <Button color="primary"  type="submit">
                                        Submit
                                      </Button>{' '}
                                      <Button color="secondary" onClick={handleShowClose}>
                                        Cancel
                                      </Button>
                                    </ModalFooter> */}

                                  </Modal>

                                  <Modal isOpen={groupModel} toggle={handleShowCloseView} size="lg">

                                    <ModalHeader toggle={handleShowCloseView}>Permission Info</ModalHeader>
                                    <ModalBody>
                                      <Table responsive>
                                        <thead>
                                          <tr>
                                            <th>
                                              #
                                            </th>
                                            <th>
                                              Module
                                            </th>
                                            <th>
                                              Action
                                            </th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {
                                            (groupEvent) ?
                                              groupEvent.map((m: any, key: any) => (

                                                <tr key={key}>
                                                  <th scope="row">
                                                    {key + 1}
                                                  </th>
                                                  <td>
                                                    {m.moduleTitle}
                                                  </td>
                                                  <td>
                                                    {(m.action) ? "Yes" : "No"}
                                                  </td>
                                                </tr>
                                              ))
                                              : <tr><th>No Permission</th><td>No Permission</td><td>No Permission</td></tr>
                                          }
                                        </tbody>
                                      </Table>
                                    </ModalBody>
                                    <ModalFooter>
                                      <Button color="secondary" onClick={handleShowCloseView}>
                                        Close
                                      </Button>
                                    </ModalFooter>

                                  </Modal>
                                </div>
                              </Col>
                            </Row>
                            <Row className="align-items-md-center mt-30">
                              <Col className="pagination pagination-rounded justify-content-end mb-2">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row >
        </Container >
      </div >
    </React.Fragment >
  );
};

export default Staff;
