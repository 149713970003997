interface MenuItemsProps {
    id: number;
    novidade?: any;
    label: string;
    icon?: string;
    link?: string;
    badge?: string;
    badgecolor?: string;
    subItems?: any;
    isHeader?: boolean
}

const menuItems: Array<MenuItemsProps> = [
    {
        id: 1,
        label: "Dashboards",
        isHeader: true
    },
    {
        id: 2,
        label: "Venue KDS",
        icon: "airplay",
        link: "/venuekds",
        // badge: "5+",
        badgecolor: "badge-soft-secondary"
    },
    {
        id: 3,
        label: "KDS Orders",
        icon: "shopping-bag",
        link: "/assets/kitchen",
        // badge: "5+",
        badgecolor: "badge-soft-secondary"
    },
    {
        id: 10,
        label: "All Areas Today's Orders",
        icon: "shopping-cart",
        link: "/home",
        // badge: "5+",
        badgecolor: "badge-soft-secondary"
    },
    {
        id: 4,
        label: "Sales",
        icon: "monitor",
        link: "/sales",
        badge: "",
        badgecolor: "badge-soft-secondary"
    },
    {
        id: 5,
        label: "Configurator",
        isHeader: true
    },
    {
        id: 11,
        label: "Staff",
        icon: "users",
        link: "/staff"
    },
    {
        id: 13,
        label: "Orders",
        icon: "shopping-cart",
        link: "/orders"
    },
    {
        id: 6,
        label: "Venue/Assets",
        icon: "map-pin",
        link: "/assets",
        subItems: [
            {
                id: 724,
                label: "Venues",
                // icon: "map-pin",
                link: "/venue",
                parentId: 6
            },
            {
                id: 710,
                label: "Venue Areas",
                link: "/area",
                parentId: 6
            },
            // {
            //     id: 711,
            //     label: "Hardware Location",
            //     link: "/hardwareLocation",
            //     parentId: 6
            // },
            {
                id: 712,
                label: "KDS (Hardware)",
                link: "/hardware",
                parentId: 6
            },
            {
                id: 713,
                label: "Asset/Table",
                // icon: "trello",
                link: "/assets",
                parentId: 6,
            }
        ]
    },
    {
        id: 7,
        label: "Items Management",
        link: "/menu",
        icon: "menu",
        subItems: [
            {
                id: 897,
                label: "Add-Ons",
                link: "/menu/addOns",
                parentId: 7
            },
            {
                id: 959,
                label: "Item Sizes",
                link: "/menu/itemSize",
                parentId: 7
            },
            {
                id: 996,
                label: "Serving Order",
                link: "/menu/servingOrder",
                parentId: 7
            },
            {
                id: 95,
                label: "VAT Categories",
                link: "/menu/categoriesType",
                parentId: 7
            },
            {
                id: 92,
                label: "Categories",
                link: "/menu/categories",
                parentId: 7
            },
            {
                id: 9,
                label: "Sub Categories",
                link: "/menu/subCategories",
                parentId: 7
            },
            {
                id: 910,
                label: "Items",
                link: "/menu/items",
                parentId: 7
            },
            {
                id: 10,
                label: "Area In items",
                link: "/menuArea",
                parentId: 7
            }
        ]
    },
    {
        id: 12,
        label: "Notifications",
        icon: "bell",
        link: "/notifications"
    },
];

export { menuItems };
