import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  getAssetsList as getAssetsListApi,
  updateAssetStatus as updateAssetStatusApi,
  addNewAsset as addNewAssetApi,
  updateAsset as updateAssetApi,
  getAssetsListWithTypes as getAssetsListWithTypesApi
} from "../../helpers/backend_helper";


export const addNewAsset = createAsyncThunk(
  "assets/addNewAsset",
  async (event: any) => {
    try {
      const response = addNewAssetApi(event);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getAssetsList = createAsyncThunk(
  "assets/getAssetsList",
  async (event: any) => {
    try {
      const response = getAssetsListApi(event);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getAssetsListWithType = createAsyncThunk(
  "assets/getAssetsListWithType",
  async (event: any) => {
    try {
      const response = getAssetsListWithTypesApi(event);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const updateAssetStatus = createAsyncThunk(
  "assets/updateAssetStatus",
  async (event: any) => {
    try {
      const response = updateAssetStatusApi(event);
      const modifiedEvent = await response;
      return modifiedEvent;
    } catch (error) {
      return error;
    }
  }
);

export const resetAssetApiResponseFlag = createAsyncThunk(
  "assets/resetAssetApiResponseFlag",
  async () => {
    try {
      return true;
    } catch (error) {
      return error;
    }
  }
);

export const updateAsset = createAsyncThunk(
  "assets/updateAsset",
  async (event: any) => {
    try {
      const response = updateAssetApi(event);
      const modifiedEvent = await response;
      return modifiedEvent;
    } catch (error) {
      return error;
    }
  }
);