import React, { useEffect, useState, ChangeEvent } from "react";
import MetaTags from "react-meta-tags";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  UncontrolledAlert,
  ModalFooter,
  CustomInput
} from "reactstrap";
import { AvForm, AvField, AvCheckboxGroup, AvCheckbox, AvInput } from "availity-reactstrap-validation";
import {
  getVenueList as onGetVenueList,
  updateVenueStatus as onUpdateVenueStatus,
  addNewVenue as onAddNewVenue,
  addVenueSettingConfigure as onAddVenueSettingConfigure,
  updateVenue as onUpdateVenue,
  resetVenueApiResponseFlag as onResetApiResponseFlag,
  getCountryList as onGetCountryList,
  getStateList as onGetStateList,
  getCityList as onGetCityList,
  logoutUser
} from "../../slices/thunks";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { isEmpty } from "lodash";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
interface LogoutProps {
  history: any;
}
import DeleteModal from "../Common/DeleteModal";
//redux
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { Link } from "react-router-dom";

const Venues = ({ history }: LogoutProps) => {
  const dispatch = useDispatch();
  const { SearchBar } = Search;

  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [venueEvent, setVenueEvent] = useState<any>({});
  const [countryCode, setCountryCode] = useState<any>('');
  const [stateCode, setStateCode] = useState<any>('');
  const [modal, setModal] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [otherCountry, setOtherCountry] = useState<boolean>(false);
  const [otherState, setOtherState] = useState<boolean>(false);
  const [otherCity, setOtherCity] = useState<boolean>(false);
  const [file, setFile] = useState<File>();
  const [bannerFile, setBannerFile] = useState<File>();
  const [settingModel, setSettingModel] = useState<boolean>(false);
  const [venueSettingEvent, setVenueSettingEvent] = useState<any>({});
  const [role, setRole] = useState<any>('');
  // const formData = new FormData();
  const handleShow = () => setModal(false);
  const handleSettingModel = () => setSettingModel(false);
  const { venueList, venueMsgResponse, venueAddUpdate, countryList, stateList, cityList, statusCode } = useSelector((state: any) => ({
    venueList: state.venues.venueList,
    venueMsgResponse: state.venues.venueMsgResponse,
    venueAddUpdate: state.venues.venueAddUpdate,
    countryList: state.locations.countryList,
    stateList: state.locations.stateList,
    cityList: state.locations.cityList,
    statusCode: state.venues.statusCode,
  }));

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
  };

  const handleBannerFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setBannerFile(e.target.files[0]);
    }
  };

  // get country code
  const handleCountryChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedOption = event.target.options[event.target.selectedIndex];
    const eventValue = selectedOption.value;
    const countryCode = selectedOption.getAttribute('data-label');

    if (eventValue === 'other') {
      setOtherCountry(true)
    }
    else {
      setOtherCountry(false)
    }
    setCountryCode(countryCode);
  };
  // state click
  const handleCountryClick = () => {
    dispatch(onGetStateList({ countryCode: `${countryCode}` }))
    handleStateClick()
  }

  // get state code
  const handleStateChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedOption = event.target.options[event.target.selectedIndex];
    const eventValue = selectedOption.value;
    const stateCode = selectedOption.getAttribute('data-label');
    if (eventValue === 'other') {
      setOtherState(true)
    }
    else {
      setOtherState(false)
    }
    setStateCode(stateCode);
  };
  // state click
  const handleStateClick = () => {
    dispatch(onGetCityList({ countryCode: `${countryCode}`, stateCode: `${stateCode}` }))
  }

  // get state code
  const handleCityChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedOption = event.target.options[event.target.selectedIndex];
    const eventValue = selectedOption.value;
    if (eventValue === 'other') {
      setOtherCity(true)
    }
    else {
      setOtherCity(false)
    }
  };

  // Handle other input value change
  const handleOtherInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setVenueEvent((prevData: any) => ({ ...prevData, [name]: value }));
  };

  const pageOptions = {
    sizePerPage: 10,
    totalSize: venueList.length, // replace later with size(users),
    custom: true,
  };

  const defaultSorted: any = [
    {
      dataField: "_id", // if dataField is not match to any column you defined, it will be ignored.
      order: "asc", // desc or asc
    },
  ];
  // ----- 1. Get Venue List -----------
  useEffect(() => {
    dispatch(onGetVenueList());
    let logInfo: any = localStorage.getItem('authUser');
    let logInfoRole: any = JSON.parse(logInfo);
    setRole(logInfoRole.role)
  }, [dispatch, history]);

  // ----- 2. Get Response Message -----
  useEffect(() => {
    const timer = setTimeout(() => {
      dispatch(onResetApiResponseFlag());
    }, 5000);
    return () => clearTimeout(timer);
  }, [venueMsgResponse]);

  // ------- Use Effect : End -----
  const handleDeleteClick = (event: any) => {
    setVenueEvent({
      id: event._id,
      status: event.status == 1 ? 0 : 1
    });
    setDeleteModal(true);
  };
  /**
  * On delete event
  */
  const handleDeleteVenue = () => {
    dispatch(onUpdateVenueStatus(venueEvent));
    setDeleteModal(false);
  };

  const handleDeleteClose = () => {
    setDeleteModal(false)
    setVenueEvent({})
  };

  const handleValidFormSubmit = (values: any) => {
    if (isEdit) {
      values["id"] = venueEvent._id
      if (file?.size) {
        values['file'] = file;
      }
      if (bannerFile?.size) {
        values['bannerFile'] = bannerFile;
      }
      values["userid"] = venueEvent.userid
      // save edit user
      dispatch(onUpdateVenue(values));
    } else {
      values['file'] = file;
      if (bannerFile?.size) {
        values['bannerFile'] = bannerFile;
      }
      // save new Venue
      dispatch(onAddNewVenue(values));
    }
    toggle();
  };

  const handleValidFormSubmitSettingConfigure = (values: any) => {
    dispatch(onAddVenueSettingConfigure(values));
    setSettingModel(false);
  };

  const toggle = () => {
    setModal(!modal);
    if (!modal && !isEmpty(venueEvent) && !!isEdit) {
      setTimeout(() => {
        setVenueEvent({})
        setIsEdit(false);
      }, 500);
    }
  };

  const handleUserClicks = () => {
    dispatch(onGetCountryList())
    setIsEdit(false);
    toggle();
  };

  const handleEditVenueClick = (event: any) => {
    dispatch(onGetCountryList())

    // Need Change Here \/ set locations when edit
    setOtherState(true)
    setOtherCity(true)

    setVenueEvent({
      ...event,
      country: `${event.location.country}`,
      state: `${event.location.state}`,
      city: `${event.location.city}`,
      zip: `${event.location.zip}`,
      address: `${event.location.address}`
    });
    setIsEdit(true);
    toggle();
  };

  const handleSettingConfigureVenueClick = (event: any) => {
    let setting: any = { venueId: event.venueId };
    if (event.setting != undefined) {
      setting.reservation = event.setting.reservation;
      setting.reservationTakeOut = event.setting.reservationTakeOut;
      setting.orderTable = event.setting.orderTable;
      setting.modelA = event.setting.modelA;
      setting.modelB = event.setting.modelB;
      setting.postPayment = event.setting.postPayment;
      setting.prePayment = event.setting.prePayment;
      setting.catListView = event.setting.catListView;
      setting.catTabView = event.setting.catTabView;
      setting.itemListView = event.setting.itemListView;
      setting.itemTabView = event.setting.itemTabView;
      setting.activeClientInfo = event.setting.activeClientInfo;
      setting.isRequiredClientInfo = event.setting.isRequiredClientInfo;
      setting.activeTableReservation = event.setting.activeTableReservation;
      setting.isCashOption = event.setting.isCashOption;
      setting.isOnlineOption = event.setting.isOnlineOption;
    } else {
      setting.reservation = false;
      setting.reservationTakeOut = false;
      setting.orderTable = false;
      setting.modelA = false;
      setting.modelB = false;
      setting.postPayment = false;
      setting.prePayment = false;
      setting.catListView = false;
      setting.catTabView = false;
      setting.itemListView = false;
      setting.itemTabView = false;
      setting.activeClientInfo = false;
      setting.isRequiredClientInfo = false;
      setting.activeTableReservation = false;
      setting.isCashOption = false;
      setting.isOnlineOption = false;
    }
    setVenueSettingEvent(setting);
    setSettingModel(true);
  }

  const venuesListColumns = [
    {
      text: "NAME (VAT)",
      dataField: "name",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent: any, venue: any) => <React.Fragment>
        <img
          className="avatar-sm rounded-circle me-2"
          src={venue.profileImage}
          alt=""
        />
        {venue.name} </React.Fragment>,
    },
    {
      text: "INFO",
      dataField: "name",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent: any, venue: any) => <React.Fragment>
        <div>VAT: <span className="text-danger">{venue.vat}</span></div>
        <div>Register Id: <span className="text-danger">{venue.registerId}</span></div>
        {/* <div>Google Link: <span className="text-danger">{venue.reviewLink}</span></div> */}
      </React.Fragment>,
    },
    {
      text: "EMAIL",
      dataField: "email",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent: any, venue: any) => <React.Fragment>{venue.email}</React.Fragment>,
    },
    {
      text: "LOCATION",
      dataField: "country",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent: any, venue: any) => <React.Fragment>
        <div>Country: <span className="text-danger">{venue.location.country}</span></div>
        <div>State: <span className="text-danger">{venue.location.state}</span></div>
        <div>City: <span className="text-danger">{venue.location.city}</span></div>
      </React.Fragment>,
    },
    {
      text: "CREATED DATE",
      dataField: "createDate",
      sort: true,
      // eslint-disable-next-line react/display-name 
      formatter: (cellContent: any, venue: any) => <React.Fragment>{moment(venue.createDate).format('DD MMM Y')}</React.Fragment>,
    },
    {
      text: "STATUS",
      dataField: "status",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent: any, venue: any) => (
        <React.Fragment>
          {venue.status == 1 ?
            <div className="badge bg-success font-size-12">{"Active"}</div>
            : <div className="badge bg-warning font-size-12">{"Inactive"}</div>}
        </React.Fragment>
      ),
    },
    {
      text: "ACTION",
      dataField: "action",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent: any, venue: any) => (
        <React.Fragment>
          <UncontrolledDropdown>
            <DropdownToggle tag="button" className="btn btn-light btn-sm">
              <i className="uil uil-ellipsis-h"></i>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-end dropdown-position">
              <DropdownItem to="#" onClick={() => handleEditVenueClick(venue)}>Edit</DropdownItem>
              <DropdownItem to="#" ><Link to={'/reviews?venueId=' + venue._id}>Reviews</Link></DropdownItem>
              <DropdownItem to="#" onClick={() => handleSettingConfigureVenueClick(venue)}>Setting Configure</DropdownItem>
              <DropdownItem to="#" onClick={() => handleDeleteClick(venue)}>{venue.status == 1 ? "Inactive" : "Active"}</DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </React.Fragment>
      ),
    },
  ];

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteVenue}
        onCloseClick={handleDeleteClose}
        deleteButtonLable={venueEvent.status == 0 ? "Inactive" : "Active"}
      />
      <div className="page-content">
        <MetaTags>
          <title>Venus | Retaurant Booking</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Restaurant" breadcrumbItem="Venues" />
          {venueMsgResponse && venueMsgResponse.message ? (
            <UncontrolledAlert color="primary">
              {venueMsgResponse.message}
            </UncontrolledAlert>
          ) : null}
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="_id"
                        data={venueList}
                        columns={venuesListColumns}
                        bootstrap4
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <Row className="align-items-start">
                              <div className="col-sm">
                                <div>
                                  {(role !== 'Venue') ? <Button color="light" onClick={handleUserClicks} className="mb-4 button-success">
                                    <i className="mdi mdi-plus me-1"></i>
                                    Add Venue</Button> : ''}
                                  {/* <Button color="light" className="mb-4">
                                    <i className="mdi mdi-plus me-1"></i>
                                    Add Area Venue</Button> */}
                                </div>
                              </div>
                            </Row>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                    defaultSorted={defaultSorted}
                                    classes={
                                      "table align-middle table-nowrap table-hover"
                                    }
                                    bordered={false}
                                    striped={false}
                                  />
                                  <Modal isOpen={modal} toggle={toggle} size="lg">
                                    <ModalHeader toggle={toggle} tag="h4">
                                      {!!isEdit ? "Edit Venue" : "Add Venue"}
                                    </ModalHeader>
                                    <ModalBody>
                                      <AvForm
                                        encType='multipart/form-data'
                                        onValidSubmit={(
                                          e: any,
                                          values: any
                                        ) => {
                                          handleValidFormSubmit(values);
                                        }}
                                      >
                                        <Row form>
                                          <Row >
                                            <Col xs={6} md={4}>
                                              <AvField
                                                name="name"
                                                label="Name"
                                                type="text"
                                                placeholder="Enter Valid Venue Name"
                                                errorMessage="Invalid Venue Name"
                                                validate={{
                                                  required: { value: true, },
                                                }}
                                                value={venueEvent.name || ""}
                                              />
                                            </Col>
                                            <Col xs={6} md={4}>
                                              <AvField
                                                name="domain"
                                                label="Domain"
                                                type="text"
                                                placeholder="example.com"
                                                errorMessage="Invalid domain Name"
                                                value={venueEvent.domain || ""}
                                              />
                                            </Col>
                                            <Col xs={6} md={4}>
                                              <AvField
                                                name="address"
                                                label="Address"
                                                type="text"
                                                placeholder="Enter Valid address "
                                                errorMessage="Invalid Address "
                                                validate={{
                                                  required: { value: true },
                                                }}
                                                value={venueEvent.address || ""}
                                              />
                                            </Col>
                                            <Col xs={6} md={4}>
                                              <AvField
                                                name="description"
                                                label="Description"
                                                type="textarea"
                                                placeholder="Enter Valid Description "
                                                errorMessage="Invalid Description "
                                                validate={{
                                                  required: { value: false },
                                                }}
                                                value={venueEvent.description || ""}
                                              />
                                            </Col>

                                            <Col xs={6} md={4}>
                                              <AvField
                                                name="email"
                                                label="Email"
                                                type="email"
                                                placeholder="Enter Valid Email "
                                                errorMessage="Invalid Email "
                                                validate={{
                                                  required: { value: true },
                                                }}
                                                value={venueEvent.email || ""}
                                              />
                                            </Col>
                                            <Col xs={6} md={4}>
                                              {
                                                !!isEdit ?
                                                  <AvField
                                                    name="password"
                                                    label="Password"
                                                    type="password"
                                                    placeholder="Enter Valid Password "
                                                  />
                                                  :
                                                  <AvField
                                                    name="password"
                                                    label="Password"
                                                    type="password"
                                                    placeholder="Enter Valid Password "
                                                    validate={{
                                                      required: { value: true, errorMessage: "Invalid Password" },
                                                      pattern: { value: '/[*@!#%&()^~{ }]+/', errorMessage: 'Your pasword must be composed with one special characters' },
                                                      minLength: { value: 8, errorMessage: 'Your password must be between 6 and 16 characters' },
                                                      maxLength: { value: 16, errorMessage: 'Your password must be between 6 and 16 characters' }
                                                    }}
                                                    value={venueEvent.password || ""}
                                                  />
                                              }
                                            </Col>
                                            <Col xs={6} md={4}>
                                              <AvField
                                                name="vat"
                                                label="VAT"
                                                type="text"
                                                placeholder="Enter Valid VAT "
                                                validate={{
                                                  required: { value: false },
                                                }}
                                                value={venueEvent.vat || ""}
                                              />
                                            </Col>
                                            <Col xs={6} md={4}>
                                              <AvField
                                                name="registerId"
                                                label="Register ID"
                                                type="text"
                                                validate={{
                                                  required: { value: false },
                                                }}
                                                value={venueEvent.registerId || ""}
                                              />
                                            </Col>
                                            {/* <Col xs={6} md={4}>
                                              <AvField
                                                name="reviewLink"
                                                label="Google Review Link"
                                                type="text"
                                                validate={{
                                                  required: { value: false },
                                                }}
                                                value={venueEvent.reviewLink || ""}
                                              />
                                            </Col> */}
                                            <Col xs={6} md={4}>
                                              <div className="mb-3">
                                                {
                                                  !!isEdit ?
                                                    <AvField
                                                      onChange={handleFileChange}
                                                      name="floorPlanImage"
                                                      label="Venue Logo "
                                                      type="file"
                                                      placeholder="Enter Valid Venue Logo "
                                                    />
                                                    :
                                                    <AvField
                                                      onChange={handleFileChange}
                                                      name="floorPlanImage"
                                                      label="Venue Logo "
                                                      type="file"
                                                      placeholder="Enter Valid Venue Logo "
                                                      errorMessage="Invalid Venue Logo "
                                                      validate={{
                                                        required: { value: true },
                                                      }}
                                                    />}

                                              </div>
                                            </Col>
                                            <Col xs={6} md={4}><div className="mb-3">
                                              {
                                                !!isEdit ?
                                                  <AvField
                                                    onChange={handleBannerFileChange}
                                                    name="bannerFile"
                                                    label="Venue Banner Image "
                                                    type="file"
                                                    placeholder="Enter Valid Venue Banner Image "
                                                  />
                                                  :
                                                  <AvField
                                                    onChange={handleBannerFileChange}
                                                    name="bannerFile"
                                                    label="Venue Banner Image "
                                                    type="file"
                                                    placeholder="Enter Valid Venue Banner Image "
                                                    errorMessage="Invalid Venue Banner Image "
                                                    validate={{
                                                      required: { value: false },
                                                    }}
                                                  />}

                                            </div></Col>
                                          </Row>
                                          <Row>
                                            <Col xs={6} md={4}>
                                              <AvField
                                                name="country"
                                                label="Country"
                                                type="select"
                                                placeholder="Select Country"
                                                errorMessage="Select valid Country"
                                                multiple={false}
                                                required
                                                value={venueEvent.country || ""}
                                                onClick={handleCountryClick}
                                                onChange={handleCountryChange}
                                              >
                                                <option key='blankChoice1' hidden value=""> Select Country </option>
                                                {
                                                  countryList.map((country: any, key: any) => (
                                                    <option value={country.name} data-label={country.iso2} key={key}>{country.name}</option>
                                                  ))}
                                                <option key='otherCountry' value="other"> Other </option>
                                              </AvField>
                                            </Col>
                                            {otherCountry && <Col xs={6} md={4}>
                                              <div className="mb-3">
                                                <AvField
                                                  name="country"
                                                  type="text"
                                                  placeholder="Enter Valid country "
                                                  errorMessage="Invalid country "
                                                  validate={{
                                                    required: { value: true },
                                                  }}
                                                  onChange={handleOtherInputChange}
                                                  value={venueEvent.country || ""}
                                                />
                                              </div>
                                            </Col>}
                                            <Col xs={6} md={4}>
                                              <AvField
                                                name="state"
                                                label="State Name"
                                                type="select"
                                                placeholder="Select State"
                                                errorMessage="Select valid State"
                                                multiple={false}
                                                required
                                                value={venueEvent.state || ""}
                                                onClick={handleStateClick}
                                                onChange={handleStateChange}
                                              >
                                                <option key='blankChoice1' hidden value=""> Select State </option>
                                                {
                                                  stateList.map((state: any, key: any) => (
                                                    <option value={state.name} data-label={state.id} key={key}>{state.name}</option>
                                                  ))}
                                                <option key='otherState' value="other"> Other </option>
                                              </AvField>
                                            </Col>
                                            {otherState && <Col xs={6} md={4}>

                                              <div className="mb-3">
                                                <AvField
                                                  name="state"
                                                  type="text"
                                                  placeholder="Enter Valid state "
                                                  errorMessage="Invalid state "
                                                  validate={{
                                                    required: { value: true },
                                                  }}
                                                  onChange={handleOtherInputChange}
                                                  value={venueEvent.state || ""}
                                                />
                                              </div>

                                            </Col>}
                                            <Col xs={6} md={4}>
                                              <AvField
                                                name="city"
                                                label="City Name"
                                                type="select"
                                                placeholder="Select City"
                                                errorMessage="Select valid City"
                                                multiple={false}
                                                required
                                                onChange={handleCityChange}
                                                value={venueEvent.city || ""}
                                              >
                                                <option key='blankChoice1' hidden value=""> Select City </option>
                                                {
                                                  cityList.map((city: any, key: any) => (
                                                    <option value={city.name} key={key}>{city.name}</option>
                                                  ))}
                                                <option key='otherState' value="other"> Other </option>
                                              </AvField>
                                            </Col>
                                            {otherCity && <Col xs={6} md={4}>

                                              <div className="mb-3">
                                                <AvField
                                                  name="city"
                                                  type="text"
                                                  placeholder="Enter Valid City "
                                                  errorMessage="Invalid city "
                                                  validate={{
                                                    required: { value: true },
                                                  }}
                                                  onChange={handleOtherInputChange}
                                                  value={venueEvent.city || ""}
                                                />
                                              </div>

                                            </Col>}
                                            <Col xs={6} md={4}>
                                              <AvField
                                                name="zip"
                                                label="Zip"
                                                type="text"
                                                placeholder="Enter Valid zip "
                                                errorMessage="Invalid zip "
                                                validate={{
                                                  required: { value: false },
                                                }}
                                                value={venueEvent.zip || ""}
                                              />
                                            </Col>
                                          </Row>

                                        </Row>
                                        <Row>
                                          <Col>
                                            <div className="text-end">
                                              <button type="button" className="btn btn-danger" onClick={handleShow}>Close</button>
                                              <button
                                                type="submit"
                                                className="btn btn-success save-user"
                                              >
                                                Save
                                              </button>
                                            </div>
                                          </Col>
                                        </Row>
                                      </AvForm>
                                    </ModalBody>
                                  </Modal>

                                  <Modal isOpen={settingModel}>
                                    <ModalHeader>Venue Setting Configure</ModalHeader>
                                    <AvForm
                                      onValidSubmit={(
                                        e: any,
                                        values: any
                                      ) => {
                                        handleValidFormSubmitSettingConfigure(values);
                                      }}
                                    >
                                      <ModalBody>
                                        <Row form>
                                          <Row >
                                            <Col xs={8} md={8}>
                                              <AvField key="modelA" type="checkbox" name="modelA" label=" Model (A) To make enable only view front menues" value={venueSettingEvent.modelA} />
                                              <AvField key="modelB" type="checkbox" name="modelB" label=" Model (A+B) To make enable front functional call-waiter, make reservations, reviews" value={venueSettingEvent.modelB} />
                                              <AvField key="venueId" type="hidden" name="venueId" value={venueSettingEvent.venueId} />
                                              <AvField key="prePayment" type="checkbox" name="prePayment" label=" To make enable pre payment" value={venueSettingEvent.prePayment} />
                                              <AvField key="postPayment" type="checkbox" name="postPayment" label=" To make enable post payment" value={venueSettingEvent.postPayment} />
                                              <AvField key="catListView" type="checkbox" name="catListView" label=" Categories in List View mode if checked , If NOT checked tabbed view will be enabled" value={venueSettingEvent.catListView} />
                                              <AvField key="catTabView" type="checkbox" name="catTabView" label=" Categories in Tab view" value={venueSettingEvent.catTabView} />
                                              {/* <AvField key="itemListView" type="checkbox" name="itemListView" label=" Items in List View mode if checked , If NOT checked tabbed view will be enabled" value={venueSettingEvent.itemListView} /> */}
                                              <AvField key="itemTabView" type="checkbox" name="itemTabView" label=" Items in Tab view" value={venueSettingEvent.itemTabView} />

                                              <AvField key="activeClientInfo" type="checkbox" name="activeClientInfo" label=" QR WEB in open popup for ask client info" value={venueSettingEvent.activeClientInfo} />
                                              <AvField key="isRequiredClientInfo" type="checkbox" name="isRequiredClientInfo" label=" QR WEB in open popup for ask client info hide 'SKIP' button" value={venueSettingEvent.isRequiredClientInfo} />
                                              <AvField key="activeTableReservation" type="checkbox" name="activeTableReservation" label=" QR WEB in open popup Table Reservation" value={venueSettingEvent.activeTableReservation} />
                                              <AvField key="isCashOption" type="checkbox" name="isCashOption" label=" Enable Cash Payment Option" value={venueSettingEvent.isCashOption} />
                                              <AvField key="isOnlineOption" type="checkbox" name="isOnlineOption" label=" Enable Online Payment Option" value={venueSettingEvent.isOnlineOption} />
                                              {/* <AvField key="reservation" type="checkbox" name="reservation" label=" To make enable reservations" value={venueSettingEvent.reservation} />
                                            <AvField key="reservationTakeOut" type="checkbox" name="reservationTakeOut" label=" To make enable reservations takeout" value={venueSettingEvent.reservationTakeOut} />
                                            <AvField key="orderTable" type="checkbox" name="orderTable" label=" To make enable reservations order on table" value={venueSettingEvent.orderTable}  /> */}
                                            </Col>
                                          </Row>
                                        </Row>
                                      </ModalBody>
                                      <ModalFooter>
                                        <Button color="secondary" onClick={handleSettingModel}>
                                          Cancel
                                        </Button>{' '}
                                        <Button type="submit" color="success">
                                          Submit
                                        </Button>
                                      </ModalFooter>
                                    </AvForm>
                                  </Modal>
                                </div>
                              </Col>
                            </Row>
                            <Row className="align-items-md-center mt-30">
                              <Col className="pagination pagination-rounded justify-content-end mb-2">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row >
        </Container >
      </div >
    </React.Fragment >
  );
};

export default Venues;
