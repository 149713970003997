import { createSlice } from "@reduxjs/toolkit";

import {
  getServingOrder,
  addServingOrder,
  updateServingOrder,
  resetApiServingOrderResponseFlag,
  updateServingOrderStatus
} from "./thunk";

interface SubCategoriesStateType {
  servingOrder: Array<any>,
  catMsgResponse: object,
  error?: string | object | null | undefined | unknown,
  servingOrderUpdate: any

}
export const initialState: SubCategoriesStateType = {
  servingOrder: [],
  error: {},
  catMsgResponse: {},
  servingOrderUpdate: {}
};

const servingOrderSlice = createSlice({
  name: "categoriesType",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(addServingOrder.fulfilled, (state: any, action: any) => {
      state.servingOrder.push(action.payload.data)
      state.catMsgResponse = {
        message: action.payload ? action.payload.message : 'New Category Added Successfully',
        type: action.payload ? 'success' : 'error',
      };

    })
    builder.addCase(addServingOrder.rejected, (state, action) => {
      state.error = action.payload || null;
    })
    builder.addCase(getServingOrder.fulfilled, (state: any, action: any) => {
      state.servingOrder = action.payload.data;
    })
    builder.addCase(getServingOrder.rejected, (state, action) => {
      state.error = action.payload || null;
    })
    builder.addCase(updateServingOrder.fulfilled, (state: any, action: any) => {
      state.servingOrderUpdate = action.payload;
      state.catMsgResponse = {
        message: action.payload ? action.payload.message : 'Category Updated Successfully',
        type: action.payload ? 'success' : 'error',
      };
    })
    builder.addCase(updateServingOrder.rejected, (state, action) => {
      state.error = action.payload || null;
    })
    builder.addCase(resetApiServingOrderResponseFlag.fulfilled, (state, action) => {
      state.catMsgResponse = { message: "" };
    })

    builder.addCase(updateServingOrderStatus.fulfilled, (state: any, action: any) => {
      state.itemAddUpdate = action.payload;
      state.itemMsgResponse = {
        message:action.payload ? action.payload.message : 'Category Status Updated Successfully',
        type: action.payload ? 'success' : 'error',
      };
    })
    builder.addCase(updateServingOrderStatus.rejected, (state, action) => {
      state.error = action.payload || null;
    })

  },
});

export default servingOrderSlice.reducer;