import { createSlice } from "@reduxjs/toolkit";

import {
  getVenueArea,
  getVenueAreaDetail,
  updateVenueAreaStatus,
  addNewVenueArea,
  updateVenueArea,
  resetVenueAreaApiResponseFlag
} from "./thunk";

interface VenueAreaStateType {
    venueArea: Array<any>,
    venueAreaDetail: Array<any>,
    venueAreaMsgResponse: object,
    error?: string | object | null | undefined | unknown,
    venueAreaAddUpdate: any
}

export const initialState: VenueAreaStateType = {
    venueArea: [],
    venueAreaDetail: [],
    error: {},
    venueAreaMsgResponse: {},
    venueAreaAddUpdate: {}
};  

const venueAreaSlice = createSlice({
name: "areas",
initialState,
reducers: {},
extraReducers: (builder) => {

    // Add New Venue Area
    builder.addCase(addNewVenueArea.fulfilled, (state: any, action: any) => {
    state.venueAreaAddUpdate = action.payload;
    state.venueAreaMsgResponse = {
        message:action.payload ? action.payload.message : 'New Venue Area Added Successfully',
        type: action.payload ? 'success' : 'error',
    };
    })
    builder.addCase(addNewVenueArea.rejected, (state, action) => {
    state.error = action.payload || null;
    })

    // Get Venue Areas
    builder.addCase(getVenueArea.fulfilled, (state: any, action: any) => {
    state.venueArea = action.payload.data;
    })
    builder.addCase(getVenueArea.rejected, (state, action) => {
    state.error = action.payload || null;
    })

    // Get Venue Areas Detail
    builder.addCase(getVenueAreaDetail.fulfilled, (state: any, action: any) => {
    state.venueAreaDetail = action.payload.data;
    })
    builder.addCase(getVenueAreaDetail.rejected, (state, action) => {
    state.error = action.payload || null;
    })

    // Area Msg Res
    builder.addCase(resetVenueAreaApiResponseFlag.fulfilled, (state, action) => {
    state.venueAreaMsgResponse = { message: "" };
    })

    // Update Status
    builder.addCase(updateVenueAreaStatus.fulfilled, (state: any, action: any) => {
    state.venueAreaAddUpdate = action.payload;
    state.venueAreaMsgResponse = {
        message:action.payload ? action.payload.message : 'Venue Area Status Updated Successfully',
        type: action.payload ? 'success' : 'error',
    };
    })
    builder.addCase(updateVenueAreaStatus.rejected, (state, action) => {
    state.error = action.payload || null;
    })

    // Update Venue Area
    builder.addCase(updateVenueArea.fulfilled, (state: any, action: any) => {
    state.venueAreaAddUpdate = action.payload;
    state.venueAreaMsgResponse = {
        message:action.payload ? action.payload.message : 'Venue Area Updated Successfully',
        type: action.payload ? 'success' : 'error',
    };
    })
    builder.addCase(updateVenueArea.rejected, (state, action) => {
    state.error = action.payload || null;
    })
},
});

export default venueAreaSlice.reducer;