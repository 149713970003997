import { createAsyncThunk } from "@reduxjs/toolkit";

import{
    addVenueHardware as addVenueHardwareApi,
    getVenueHardware as getVenueHardwareApi,
    deleteVenueHardwareLocation as deleteVenueHardwareLocationApi,
    updateVenueHardwareLocation as updateVenueHardwareLocationApi,
    getVenueHardwareDetail as getVenueHardwareDetailApi
} from "../../helpers/backend_helper"

export const addVenueHardware = createAsyncThunk(
    "hardwares/addVenueHardware",
    async (event : any) =>{
        try{
            const response = addVenueHardwareApi(event);
            console.log("Response from addvenuehardware");
            return response;
        }catch(error){
            return error;
        }
    }
);

export const deleteVenueHardwareLocation = createAsyncThunk(
    "hardwares/deleteVenueHardwareLocation",
    async(event : any) =>{
        try{
            const response = deleteVenueHardwareLocationApi(event);
            const modifiedEvent = await response;
            return modifiedEvent;
        }catch(error){
            return error;
        }
    }
);

export const getVenueHardwareDetail = createAsyncThunk(
    "hardwares/getVenueHardwareDetail",
    async(event: any)=>{
        try{
            
            const response = getVenueHardwareDetailApi(event);
            console.log("Response from Thunk Hardware",response)
            return response;
        }catch(error){
            return error;
        }
    }
);

export const getVenueHardware =  createAsyncThunk(
    "hardwares/getVenueHardware",
    async (event: any)=>{
        try{
            const response = getVenueHardwareApi(event);
            return response;
        }catch(error){
            return error;
        }
    }
);

export const resetHardwareApiResponseFlag = createAsyncThunk(
    "hardwares/resetHardwareApiResponseFlag",
    async() =>{
        try{
            return true;
        }catch(error){
            return error;
        }
    }
)

export const updateVenueHardwareLocation = createAsyncThunk(
    "hardwares/updateVenueHardwareLocation",
    async(event : any) =>{
        try{
            const response = updateVenueHardwareLocationApi(event);
            const modifiedEvent = await response;
            return modifiedEvent;
        }catch(error){
            return error;
        }
    }
);