import { createSlice } from "@reduxjs/toolkit";

import {
  getCategoriesType,
  addNewCategoryType,
  updateCategoryType,
  resetApiCategoryTypeResponseFlag,
  updateCategoryTypeStatus
} from "./thunk";

interface SubCategoriesStateType {
  categoriesType: Array<any>,
  catMsgResponse: object,
  error?: string | object | null | undefined | unknown,
  categoriesTypeUpdate: any

}
export const initialState: SubCategoriesStateType = {
  categoriesType: [],
  error: {},
  catMsgResponse: {},
  categoriesTypeUpdate: {}
};

const categoriesTypeSlice = createSlice({
  name: "categoriesType",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(addNewCategoryType.fulfilled, (state: any, action: any) => {
      state.categoriesType.push(action.payload.data)
      state.catMsgResponse = {
        message: action.payload ? action.payload.message : 'New Category Added Successfully',
        type: action.payload ? 'success' : 'error',
      };

    })
    builder.addCase(addNewCategoryType.rejected, (state, action) => {
      state.error = action.payload || null;
    })
    builder.addCase(getCategoriesType.fulfilled, (state: any, action: any) => {
      state.categoriesType = action.payload.data;
    })
    builder.addCase(getCategoriesType.rejected, (state, action) => {
      state.error = action.payload || null;
    })
    builder.addCase(updateCategoryType.fulfilled, (state: any, action: any) => {
      state.categoriesTypeUpdate = action.payload;
      state.catMsgResponse = {
        message: action.payload ? action.payload.message : 'Category Updated Successfully',
        type: action.payload ? 'success' : 'error',
      };
    })
    builder.addCase(updateCategoryType.rejected, (state, action) => {
      state.error = action.payload || null;
    })
    builder.addCase(resetApiCategoryTypeResponseFlag.fulfilled, (state, action) => {
      state.catMsgResponse = { message: "" };
    })

    builder.addCase(updateCategoryTypeStatus.fulfilled, (state: any, action: any) => {
      state.itemAddUpdate = action.payload;
      state.itemMsgResponse = {
        message:action.payload ? action.payload.message : 'Category Status Updated Successfully',
        type: action.payload ? 'success' : 'error',
      };
    })
    builder.addCase(updateCategoryTypeStatus.rejected, (state, action) => {
      state.error = action.payload || null;
    })

  },
});

export default categoriesTypeSlice.reducer;