import React, { useEffect, useState, ChangeEvent } from "react";
import MetaTags from "react-meta-tags";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Spinner
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import {
  getItemList as onGetitemList,
  getVenueList as onGetVenueList,
  getMenuCategories as onGetgetCategories,
  addNewItem as onAddNewItem,
  updateItemStatus as onUpdateItemStatus,
  updateItem as onUpdateItem,
  resetItemApiResponseFlag as onResetApiResponseFlag,
  getCategoriesType as OnGetCategoriesType,
  getSubCategoriesType as OnGetSubCategoriesType,
  getItemSize as onGetItemSize,
  getServingOrder as onGetServingOrder,
  uploadItem as OnUploadItem,
  updateMenuItem as onUpdateMenuItem
} from "../../slices/thunks";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { isEmpty } from "lodash";
import { ToastContainer, toast } from 'react-toastify';
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import 'react-toastify/dist/ReactToastify.css';
import BootstrapTable from "react-bootstrap-table-next";
import DeleteModal from "../Common/DeleteModal";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import 'quill/dist/quill.snow.css'
import ReactQuill from 'react-quill'
import Icon from "@ailibs/feather-react-ts/dist/Icon";

const Items = () => {
  const dispatch = useDispatch();

  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [itemEvent, setItemEvent] = useState<any>({});
  const [modal, setModal] = useState<boolean>(false);
  const [preparationModal, setPreparationModal] = useState<boolean>(false);
  const [uploadModel, setUploadModel] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const handleShow = () => setModal(false);
  const handleUploadModel = () => setUploadModel(false);
  const [file, setFile] = useState<File>();
  const [videoFile, setVideoFile] = useState<File | null>(null);
  const [contentText, setContentText] = useState('');
  const [videoUrl, setVideoUrl] = useState<any>({});
  const [isOpen, setOpen] = useState(false);
  const handleVideoModel = () => setOpen(false);
  const [isVenueId, setVenueId] = useState('');
  const [venueIdSel, setvenueIdSel] = useState('');
  const [isCategoryId, setCategoryId] = useState('');
  const [page, setPage] = useState<any>(1);
  const [defaultPrice, setDefaultPrice] = useState<any>(0);
  const [items, setItems] = useState<any>([]);
  const [searchInput, setSearchInput] = useState('');

  let { venueList, categories, itemList, itemMsgResponse, itemAddUpdate, getCategoriesType, subCategories, itemSizes, servingOrder, itemAdd } = useSelector((state: any) => ({
    venueList: state.venues.venueList,
    categories: state.categories.categories,
    subCategories: state.subCategories.subCategories,
    itemList: state.items.itemList,
    itemMsgResponse: state.items.itemMsgResponse,
    itemAddUpdate: state.items.itemAddUpdate,
    itemAdd: state.items.itemAdd,
    getCategoriesType: state.categoriesType.categoriesType,
    itemSizes: state.itemSize.itemSize,
    servingOrder: state.servingOrder.servingOrder
  }));

  useEffect(() => {
    if (page === 1) {
      setItems(itemList)
    }
    else {
      setItems((prevItems: any) => ([...prevItems, ...itemList]));
    }
  }, [itemList])

  useEffect(() => {
    dispatch(onGetitemList({ catId: `${isCategoryId}`, venueId: isVenueId, pageNumber: `${page}`, keyward: searchInput }));
  }, [page, dispatch])

  useEffect(() => {
    dispatch(onGetgetCategories({}));
    dispatch(OnGetCategoriesType());
    dispatch(onGetVenueList());
  }, [dispatch]);

  useEffect(() => {
    window.addEventListener("scroll", handlePaggination);
    return () => window.removeEventListener("scroll", handlePaggination);
  }, [])

  useEffect(() => {
    dispatch(onGetitemList({ catId: `${isCategoryId}`, pageNumber: `${page}`, venueId: isVenueId, keyward: searchInput }));
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      dispatch(onResetApiResponseFlag());
    }, 5000);
    return () => clearTimeout(timer);
  }, [itemMsgResponse]);

  const handlePaggination = async () => {
    try {
      if (
        Number(window.innerHeight) + Number(document.documentElement.scrollTop) + 1 >=
        Number(document.documentElement.scrollHeight)) {
        setPage((prev: any) => Number(prev) + 1)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleVideoChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setVideoFile(file);
    }
  };

  const handleProcedureContentChange = (content: any) => {
    setContentText(content)
  };

  function togglePreparation() {
    setPreparationModal(!preparationModal);
  }

  const defaultSorted: any = [
    {
      dataField: "_id", // if dataField is not match to any column you defined, it will be ignored.
      order: "asc", // desc or asc
    },
  ];

  const filterItems = (catId: string) => {
    setCategoryId(catId);
    setPage(1)
    dispatch(onGetitemList({ catId: catId, venueId: isVenueId, pageNumber: `${page}` }));
  };

  const getPrice = (price: any) => {
    setDefaultPrice(price)
  };
  const filterItemsByVenue = (venueId: string) => {
    setVenueId(venueId)
    setPage(1)
    dispatch(onGetitemList({ venueId: venueId, catId: isCategoryId, pageNumber: `${page}` }));
    dispatch(onGetgetCategories({ venueId: venueId }));
  };

  const filterVenue = (venueId: string) => {
    setvenueIdSel(venueId);
    dispatch(onGetgetCategories({ venueId: venueId }));
  }

  const getCategory = (categoryTypeId: string) => {
    dispatch(onGetgetCategories({ categoryTypeId: categoryTypeId, venueId: venueIdSel }));
    dispatch(OnGetSubCategoriesType({ categoryId: categoryTypeId }));
  }

  const createMarkup = (e: string) => {
    return { __html: e };
  }

  const getSubCategory = (categoryId: string) => {
    dispatch(OnGetSubCategoriesType({ categoryId: categoryId }));
  }

  const setName = (keyward: string) => {
    setSearchInput(keyward)
    setPage(1)
    dispatch(onGetitemList({ keyward: keyward, venueId: isVenueId, pageNumber: `${page}` }));
  };

  const handleDeleteClick = (event: any) => {
    setItemEvent({
      id: event._id,
      status: event.status == 1 ? 0 : 1
    });
    setDeleteModal(true);
  };

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
  };
  /**
  * On delete event
  */
  const handleDeleteAsset = () => {
    dispatch(onUpdateItemStatus(itemEvent));
    setDeleteModal(false);
    setPage(1)
    dispatch(onGetitemList({ pageNumber: `${page}` }));
  };

  const updateMenuInfo = (menu: any, calories: any) => {
    let e: any = {
      id: menu._id,
      calories: calories
    }
    dispatch(onUpdateMenuItem(e));
  };

  const updateMenuInfoGluten = (menu: any, isGlutenFree: any) => {
    let e: any = {
      id: menu._id,
      isGlutenFree: isGlutenFree
    }
    dispatch(onUpdateMenuItem(e));
  };

  const handleDeleteClose = () => {
    setDeleteModal(false)
    setItemEvent({})
  };

  const handleValidFormSubmit = (values: any) => {
    const name = { en: `${values.enName}`, gr: `${values.grName}` }
    const description = { en: `${values.enDescription}`, gr: `${values.grDescription}` }
    values['name'] = JSON.stringify(name);
    values['description'] = JSON.stringify(description);
    if (isEdit) {
      values['prepareDescription'] = contentText;
      values["id"] = itemEvent._id
      if (file?.size) {
        values['file'] = file;
      }
      if (videoFile?.size) {
        values['videoFile'] = videoFile;
      }
      dispatch(onUpdateItem(values));
    } else {
      values['file'] = file;
      values['videoFile'] = videoFile;
      values['prepareDescription'] = contentText;
      dispatch(onAddNewItem(values));
    }
  };

  useEffect(() => {
    /** item update */
    if (itemAddUpdate.statusCode == 200) {
      setIsEdit(false);
      setItemEvent({});
      setModal(!modal);
      toast.success(itemAddUpdate.message)
      const timer = setTimeout(() => {
        // window.location.reload();
        setPage(1)
        dispatch(onGetitemList({ venueId: isVenueId, pageNumber: `${page}` }));
      }, 1000);
      return () => clearTimeout(timer);
    } else if (itemAddUpdate.statusCode == 400) {
      setIsEdit(true);
      setModal(modal);
      toast.error(itemAddUpdate.message)
    }

    /** item add */
    if (itemAdd.statusCode == 200) {
      setItemEvent({});
      setModal(!modal);
      toast.success(itemAdd.message)
      const timer = setTimeout(() => {
        window.location.reload();
      }, 1000);
      return () => clearTimeout(timer);
    } else if (itemAdd.statusCode == 400) {
      setModal(modal);
      toast.error(itemAdd.message)
    }
  }, [itemAddUpdate, itemAdd])

  const handleValidUploadFormSubmit = (values: any) => {
    values['file'] = file;
    dispatch(OnUploadItem(values));
  };

  const toggle = () => {
    setModal(!modal);
    if (!modal && !isEmpty(itemEvent) && !!isEdit) {
      setTimeout(() => {
        setItemEvent({})
        setIsEdit(false);
      }, 500);
    }
  };

  const playVide = (e: any) => {
    setVideoUrl(e)
    setOpen(true)
  }

  const handleAddItemClicks = () => {
    setIsEdit(false);
    setItemEvent({})
    setModal(!modal);
    dispatch(onGetItemSize());
    dispatch(onGetServingOrder());
  };

  const handleUploadItemClicks = () => {
    setUploadModel(true);
  };

  const handleEditItemClick = (event: any) => {
    dispatch(onGetItemSize());
    dispatch(onGetServingOrder());
    setContentText(event.prepareDescription)
    setItemEvent(event);
    setIsEdit(true);
    setModal(!modal);

  };

  const venuesListColumns = [
    {
      text: "IMAGE",
      dataField: "image",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent: any, item: any) => (
        <React.Fragment>
          <img
            className="avatar-sm rounded-circle me-2"
            src={item.image}
            alt=""
          />
          <Button onClick={() => playVide(item)}><Icon className="mdi-file-video" name={"video"} /></Button>
        </React.Fragment>
      ),
    },
    {
      text: "ITEM CODE",
      dataField: "code ",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent: any, item: any) => <React.Fragment>{item.code}</React.Fragment>,
    },
    {
      text: "VAT CATEGORY",
      dataField: "vatCategoryInfo.name",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent: any, item: any) => <React.Fragment>{!isEmpty(item.vatCategoryInfo) ? item.vatCategoryInfo.name.en + " (" + (item.vatCategoryInfo.vat) + "%)" : "NA"}</React.Fragment>,
    },
    {
      text: "CATEGORY",
      dataField: "categoryInfo.name",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent: any, item: any) => <React.Fragment>{!isEmpty(item.categoryInfo) ? item.categoryInfo.name.en : "NA"}</React.Fragment>,
    },
    {
      text: "SUBCATEGORY",
      dataField: "subcategoryInfo.name",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent: any, item: any) => <React.Fragment>{!isEmpty(item.subcategoryInfo) ? item.subcategoryInfo.name.en : "NA"}</React.Fragment>,
    },
    {
      text: "ITEM",
      dataField: "name",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent: any, item: any) => <React.Fragment>{item.name.en} </React.Fragment>,
    },
    {
      text: "PRICE",
      dataField: "price",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent: any, item: any) => <React.Fragment>{item.price}€ </React.Fragment>,
    },
    {
      text: "Gluten Free",
      dataField: "isGlutenFree",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent: any, item: any) => <React.Fragment>
        <input type="checkbox" key={item._id} name="isGlutenFree" className="inputBox" defaultChecked={(item.isGlutenFree != undefined && item.isGlutenFree != "") ? item.isGlutenFree : false}
          onChange={(e: any) => updateMenuInfoGluten(item, e.target.checked)} />
        {/* <Button color="primary"   >Add</Button> */}
      </React.Fragment>,
    },
    {
      text: "Calories",
      dataField: "calories",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent: any, item: any) => <React.Fragment>
        <input name="calories" className="inputBox" defaultValue={item.calories}
          placeholder="calories" onChange={(e: any) => updateMenuInfo(item, e.target.value)} />
        {/* <Button color="primary"   >Add</Button> */}
      </React.Fragment>,
    },
    {
      text: "STATUS",
      dataField: "status",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent: any, item: any) => (
        <React.Fragment>
          {item.status == 1 ?
            <div className="badge bg-success font-size-12">{"Active"}</div>
            : <div className="badge bg-warning font-size-12">{"Inactive"}</div>}
        </React.Fragment>
      ),
    },
    {
      text: "CREATED DATE",
      dataField: "createDate",
      sort: true,
      // eslint-disable-next-line react/display-name 
      formatter: (cellContent: any, item: any) => <React.Fragment>{moment(item.createDate).format('DD MMM Y')}</React.Fragment>,
    },
    {
      text: "ACTION",
      dataField: "action",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent: any, item: any) => (
        <React.Fragment>
          <UncontrolledDropdown>
            <DropdownToggle tag="button" className="btn btn-light btn-sm">
              <i className="uil uil-ellipsis-h"></i>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-end dropdown-position">
              <DropdownItem to="#" onClick={() => handleEditItemClick(item)}>Edit</DropdownItem>
              <DropdownItem to="#" onClick={() => handleDeleteClick(item)}>{item.status == 1 ? "Inactive" : "Active"}</DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </React.Fragment>
      ),
    },
  ];

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteAsset}
        onCloseClick={handleDeleteClose}
        deleteButtonLable={itemEvent.status == 0 ? "Inactive" : "Active"}
      />
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className="page-content">
        <MetaTags>
          <title>Menu-items | Retaurant Booking</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Menu" breadcrumbItem="Items" />
          {/* {itemMsgResponse && itemMsgResponse.message ? (
            <UncontrolledAlert color="primary">
              {itemMsgResponse.message}
            </UncontrolledAlert>
          ) : null} */}
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <ToolkitProvider
                    keyField="_id"
                    data={items}
                    columns={venuesListColumns}
                    bootstrap4
                    search
                  >
                    {toolkitProps => (
                      <React.Fragment>
                        <Row className="align-items-start">
                          <Col xs={2} md={2}>
                            <Button color="light" onClick={handleAddItemClicks} className="mb-4 button-success">
                              <i className="mdi mdi-plus me-1"></i>
                              Add Item</Button>
                          </Col>

                          <Col xs={2} md={2}>
                            <Button color="light" onClick={handleUploadItemClicks} className="mb-4 button-success">
                              <i className="mdi mdi-cloud-upload"></i>
                              Upload </Button>
                          </Col>
                          <Col xs={3} md={3}>
                            <select className="input-select col-sm"
                              onChange={(e) => filterItemsByVenue(e.target.value)}
                            >
                              <option value=""> Venue </option>
                              {
                                venueList.map((venue: any, key: any) => (
                                  <option value={venue._id} key={key}>{venue.name}</option>
                                ))}
                            </select></Col>
                          <Col xs={3} md={3}>
                            <select className="input-select" onChange={(e) => filterItems(e.target.value)}>
                              <option value=""> Select Category </option>
                              {
                                categories.map((category: any, key: any) => (
                                  <option value={category._id} key={key}>{category.name.en}</option>
                                ))}
                            </select>
                          </Col>
                          <Col xs={2} md={2}>
                            <input className="search-input" type="search" placeholder="Search..." onChange={(e) => setName(e.target.value)} />
                          </Col>
                        </Row>
                        <Row>
                          <Col xl="12">
                            <div className="table-responsive">
                              <BootstrapTable
                                {...toolkitProps.baseProps}
                                defaultSorted={defaultSorted}
                                classes={
                                  "table align-middle table-nowrap table-hover"
                                }
                                bordered={false}
                                striped={false}
                              />
                              <Modal isOpen={modal} toggle={toggle} size="lg" >
                                <ModalHeader toggle={toggle} tag="h4">
                                  {!!isEdit ? "Edit Item" : "Add Item"}
                                </ModalHeader>
                                <ModalBody>

                                  <AvForm
                                    onValidSubmit={(
                                      e: any,
                                      values: any
                                    ) => {
                                      handleValidFormSubmit(values);
                                    }}
                                  >
                                    <Row form>
                                      <Row >
                                        <Col xs={6} md={4}>

                                          <AvField
                                            type="select"
                                            name="venueId"
                                            placeholder="Select Venue"
                                            errorMessage="Please select venue"
                                            label="Venue"
                                            multiple={false}
                                            required
                                            onChange={(e: any) => filterVenue(e.target.value)}
                                            value={itemEvent.venueId || ""}
                                          >
                                            <option key='blankChoice' hidden value=""> Select Venue </option>
                                            {
                                              venueList.map((venue: any, index: number) => (
                                                <option value={venue._id} key={index}>{venue.name}</option>
                                              ))}
                                          </AvField>

                                        </Col>
                                        <Col xs={6} md={4}>
                                          <AvField
                                            name="enName"
                                            label="Name (En)"
                                            type="text"
                                            placeholder="Enter Valid Item Name (En)"
                                            errorMessage="Invalid Item Name"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            value={itemEvent.enName || ""}
                                          />
                                        </Col>
                                        <Col xs={6} md={4}>
                                          <AvField
                                            name="grName"
                                            label="Name (Gr)"
                                            type="text"
                                            placeholder="Enter Valid Item Name (Gr)"
                                            errorMessage="Invalid Item Name"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            value={itemEvent.grName || ""}
                                          />
                                        </Col>
                                      </Row>

                                      <Row >
                                        <Col xs={6} md={4}>

                                          <AvField
                                            type="select"
                                            name="categoryTypeId"
                                            placeholder="Select VAT Category"
                                            errorMessage="Select valid VAT Catrgory"
                                            label="VAT Category"
                                            multiple={false}
                                            required
                                            onChange={(e: any) => getCategory(e.target.value)}
                                            value={itemEvent.categoryTypeId || ""}
                                          >
                                            <option key='blankChoice1' hidden value=""> Select VAT Category </option>
                                            {
                                              getCategoriesType.map((categoryType: any, key: any) => (
                                                <option value={categoryType._id} key={key}>{categoryType.name.en} ({categoryType.name.gr}) - {categoryType.vat}%</option>
                                              ))}
                                          </AvField>
                                        </Col>
                                        <Col xs={6} md={4}>
                                          <AvField
                                            type="select"
                                            name="categoryId"
                                            placeholder="Select Category"
                                            errorMessage="Select valid Catrgory"
                                            label="Category"
                                            multiple={false}
                                            required
                                            onChange={(e: any) => getSubCategory(e.target.value)}
                                            value={itemEvent.categoryId || ""}
                                          >
                                            <option key='blankChoice1' hidden value=""> Select Category </option>
                                            {
                                              categories.map((category: any, key: any) => (
                                                <option value={category._id} key={key}>{category.name.en}</option>
                                              ))}
                                          </AvField>

                                        </Col>
                                        <Col xs={6} md={4}>
                                          <AvField
                                            type="select"
                                            name="subCategoryId"
                                            placeholder="Select Sub Category"
                                            errorMessage="Select valid Sub Catrgory"
                                            label="Sub Category"
                                            multiple={false}
                                            value={itemEvent.subCategoryId || ""}
                                          >
                                            <option key='blankChoice1' hidden value=""> Select SubCategory </option>
                                            {
                                              subCategories.map((category: any, key: any) => (
                                                <option value={category._id} key={key}>{category.name.en}</option>
                                              ))}
                                          </AvField>

                                        </Col>
                                        <Col xs={6} md={4}>
                                          <AvField
                                            type="select"
                                            name="classificationCategory"
                                            placeholder="Select Classification Category"
                                            errorMessage="Select valid Classification Category"
                                            label="Classification Category"
                                            multiple={false}
                                            required
                                          >
                                            <option value="Product" key="Product">Product</option>
                                          </AvField>

                                        </Col>
                                      </Row>

                                      <Row >
                                        <Col xs={6} md={4}>
                                          <AvField
                                            name="price"
                                            label="Price €"
                                            type="number"
                                            placeholder="0.00"
                                            errorMessage="Invalid Item Price"
                                            onChange={(e: any) => getPrice(e.target.value)}
                                            validate={{
                                              required: { value: true },
                                            }}
                                            value={itemEvent.price || 0}
                                          />
                                        </Col>
                                        <Col xs={6} md={4}>
                                          <AvField
                                            name="takeOutPrice"
                                            label="Take Out Price €"
                                            type="number"
                                            placeholder="0.00"
                                            errorMessage="Invalid Take Out Price"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            value={itemEvent.takeOutPrice || defaultPrice}
                                          />
                                        </Col>
                                        <Col xs={6} md={4}>
                                          <AvField
                                            name="deliveryPrice"
                                            label="Delivery Price €"
                                            type="number"
                                            placeholder="0.00"
                                            errorMessage="Invalid Delivery Price"
                                            validate={{
                                              required: { value: false },
                                            }}
                                            value={itemEvent.deliveryPrice || defaultPrice}
                                          />
                                        </Col>
                                        <Col xs={6} md={4}>
                                          <AvField
                                            name="marketPlacePrice"
                                            label="Market Place Price €"
                                            type="number"
                                            placeholder="0.00"
                                            errorMessage="Invalid Market Place Price"
                                            validate={{
                                              required: { value: false },
                                            }}
                                            value={itemEvent.marketPlacePrice || defaultPrice}
                                          />
                                        </Col>

                                      </Row>

                                      <Row>
                                        <Col xs={6} md={6}>
                                          <AvField
                                            name="enDescription"
                                            label="Description  (En)"
                                            type="textarea"
                                            placeholder="Enter Valid Description  (En)"
                                            errorMessage="Invalid Description "
                                            validate={{
                                              required: { value: true },
                                            }}
                                            value={itemEvent.enDescription || ""}
                                          />
                                        </Col>
                                        <Col xs={6} md={6}>
                                          <AvField
                                            name="grDescription"
                                            label="Description  (Gr)"
                                            type="textarea"
                                            placeholder="Enter Valid Description  (Gr)"
                                            errorMessage="Invalid Description "
                                            validate={{
                                              required: { value: false },
                                            }}
                                            value={itemEvent.grDescription || ""}
                                          />
                                        </Col>
                                        <Col xs={6} md={4}>
                                          <AvField
                                            type="select"
                                            name="size"
                                            placeholder="Select Size"
                                            errorMessage="Select valid Size"
                                            label="Size"
                                            multiple={false}
                                            required
                                            value={JSON.stringify(itemEvent.size) || ""}
                                          >
                                            <option key='blankChoice1' hidden value=""> Select Size </option>
                                            {
                                              itemSizes.map((size: any, key: any) => (
                                                <option value={JSON.stringify(size)} key={key}>{size.name}</option>
                                              ))}
                                          </AvField>
                                        </Col>
                                        <Col xs={6} md={4}>
                                          <AvField
                                            type="select"
                                            menuPlacement="top"
                                            name="servingOrder"
                                            placeholder="Select Serving Order"
                                            errorMessage="Select valid Serving Order"
                                            label="Serving Order"
                                            multiple={false}
                                            validate={{
                                              required: { value: false },
                                            }}
                                            value={JSON.stringify(itemEvent.servingOrder) || ""}
                                          >
                                            {/* <option key='blankChoice1' hidden value=""> Select Serving Order </option> */}
                                            {
                                              servingOrder.map((sOrder: any, key: any) => (
                                                <option value={JSON.stringify(sOrder)} key={key}>{sOrder.name}</option>
                                              ))}
                                          </AvField>
                                        </Col>

                                        <Col xs={6} md={4} >
                                          <AvField
                                            type="select"
                                            name="rStatus"
                                            placeholder="Select Status"
                                            errorMessage="Select valid Status"
                                            label="Status"
                                            multiple={false}
                                            required
                                            value={itemEvent.status || "1"}
                                          >
                                            <option key='blankChoice1' hidden value=""> Select Status </option>
                                            <option value="1" key="1">Active</option>
                                            <option value="0" key="0">InActive</option>
                                          </AvField>
                                        </Col>
                                        <Col xs={12} className="mt-1">
                                          <h6>Prepare Tutorial Video</h6>
                                          <input type="file" name="videoFile" accept="video/*" onChange={handleVideoChange} />
                                          {videoFile && (
                                            <div className="py-1 w-50" >
                                              <video className="w-100" controls>
                                                <source src={URL.createObjectURL(videoFile)} type={videoFile.type} />
                                                Your browser does not support the video tag.
                                              </video>
                                            </div>
                                          )}
                                        </Col>
                                        <Col xs={12} className="mt-1">
                                          <div className="mb-5">
                                            <h6>Procedure</h6>
                                            <ReactQuill
                                              theme="snow"
                                              // modules={modules}
                                              // formats={formats}
                                              placeholder="write your content ...."
                                              onChange={handleProcedureContentChange}
                                              style={{ height: "10rem" }}
                                              value={contentText}
                                            >
                                            </ReactQuill>
                                          </div>
                                        </Col>
                                      </Row>
                                      <Row>
                                        {/* <Col xs={6} md={4}> </Col>
                                            <Col xs={6} md={4}> </Col> */}
                                        <Col xs={6} md={4}>
                                          <div className="mb-3">
                                            {
                                              !!isEdit ?
                                                <AvField
                                                  onChange={handleFileChange}
                                                  name="image"
                                                  label="Item Image "
                                                  type="file"
                                                  accept="image/png, image/jpeg, image/jpg"
                                                  placeholder="Enter Valid Item Image "
                                                />
                                                :
                                                <AvField
                                                  onChange={handleFileChange}
                                                  name="image"
                                                  label="Item Image "
                                                  type="file"
                                                  accept="image/png, image/jpeg, image/jpg"
                                                  placeholder="Enter Valid Item Image "
                                                  errorMessage="Invalid Item Image "
                                                  validate={{
                                                    required: { value: true },
                                                  }}
                                                />}
                                          </div>
                                        </Col>
                                      </Row>

                                    </Row>
                                    <Row>
                                      <Col>
                                        <div className="text-end">
                                          <button type="button" className="btn btn-light w-sm" onClick={handleShow}>Close</button>
                                          <button
                                            type="submit"
                                            className="btn btn-success save-user"
                                          >
                                            Save
                                          </button>
                                        </div>
                                      </Col>
                                    </Row>
                                  </AvForm>
                                </ModalBody>
                              </Modal>
                            </div>
                            <Modal isOpen={uploadModel} toggle={toggle}>
                              <ModalHeader toggle={handleUploadModel}>Upload Items</ModalHeader>
                              <ModalBody>

                                <AvForm
                                  onValidSubmit={(
                                    e: any,
                                    values: any
                                  ) => {
                                    handleValidUploadFormSubmit(values);
                                  }}
                                >
                                  <Row>
                                    <Col xs={8} md={8}>

                                      <AvField
                                        type="select"
                                        name="venueId"
                                        placeholder="Select Venue"
                                        errorMessage="Please select venue"
                                        label="Venue"
                                        multiple={false}
                                        required
                                        value={itemEvent.venueId || ""}
                                      >
                                        <option key='blankChoice' hidden value=""> Select Venue </option>
                                        {
                                          venueList.map((venue: any, index: number) => (
                                            <option value={venue._id} key={index}>{venue.name}</option>
                                          ))}
                                      </AvField>

                                    </Col>
                                    <Col xs={8} md={8}>
                                      <AvField
                                        onChange={handleFileChange}
                                        name="file"
                                        label="Upload File"
                                        type="file"
                                        placeholder="Enter Valid CSV File "
                                        errorMessage="Invalid Item CSV File "
                                        validate={{
                                          required: { value: true },
                                        }}
                                      /></Col>
                                  </Row>
                                  <Row>
                                    <Col>
                                      <div className="text-end">
                                        <Button color="secondary" onClick={handleUploadModel}>
                                          Cancel
                                        </Button>
                                        <button
                                          type="submit"
                                          className="btn btn-success save-user"
                                        >
                                          Save
                                        </button>
                                      </div>
                                    </Col>
                                  </Row>

                                </AvForm>
                              </ModalBody>
                            </Modal>
                            <Modal isOpen={isOpen} toggle={handleVideoModel} size="lg" >
                              <ModalHeader toggle={handleVideoModel} tag="h4">
                                Item Preparation
                              </ModalHeader>
                              <ModalBody>
                                <Row>
                                  <Col sm={12} className="mt-1">
                                    <h6>Prepare Tutorial</h6>
                                    {/* <div className="py-1" > */}
                                    <video width="100%" height="auto" controls>
                                      <source src={videoUrl.videoUrl} type="video/mp4" />
                                      Your browser does not support the video tag.
                                    </video>
                                    {/* </div> */}
                                  </Col>
                                  <Col sm={12} className="mt-1">
                                    <div className="mb-5">
                                      <div dangerouslySetInnerHTML={createMarkup(videoUrl.prepareDescription)}></div>
                                    </div>
                                  </Col>
                                </Row>
                              </ModalBody>
                            </Modal>
                          </Col>
                        </Row>
                      </React.Fragment>
                    )}
                  </ToolkitProvider>
                </CardBody>
              </Card>
            </Col>
          </Row >
        </Container >
      </div >
    </React.Fragment >
  );
};

export default Items;
