import React, { useEffect } from "react";
import { Bounce, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import socket from '../Common/websocket'
import { Link } from "react-router-dom";
import {
 Alert
} from "reactstrap";
//import components
import Header from "./Header";
import Sidebar from "./Sidebar";
import Footer from "./Footer";

//constants
import {
  layoutTypes
} from "../../constants/layout";

//import actions
import {
  changeLayout,
  changeSidebarTheme,
  changeSidebarType,
  changeTopbarTheme,
  changeLayoutWidth,
  changelayoutMode,
} from "../../slices/thunks";

//redux
import { useSelector, useDispatch } from "react-redux";

interface LayoutProps {
  children: any;
}
const Layout = (props: LayoutProps) => {
  const dispatch = useDispatch();

  const {
    layoutMode,
    layoutWidth,
    leftSideBarType,
    topbarTheme,
    layoutType,
    leftSideBarTheme,
  } = useSelector((state: any) => ({
    layoutMode: state.Layout.layoutMode,
    leftSideBarType: state.Layout.leftSideBarType,
    layoutWidth: state.Layout.layoutWidth,
    topbarTheme: state.Layout.topbarTheme,
    layoutType: state.Layout.layoutType,
    leftSideBarTheme: state.Layout.leftSideBarTheme,
  }));

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  const CustomToastWithLink = () => (
    <div>
      <Link to="/toasttest">This is a link</Link>
    </div>
  );

  useEffect(() => {
    socket.on('callWaiter', (res: any) => {
      let payload = JSON.parse(res);
      const CustomToastWithLink = (payload:any) => (
        <div className="">
           <div className="text-alert">🔔 {payload.title}</div>
          <Link className="text-dark" to="/notifications">   {payload.message}</Link>
        </div>
      );
      toast.error(CustomToastWithLink(payload),{
        position: "top-right",
        autoClose: 15000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
        });
    })
    socket.on('newOrder', (res: any) => {
      let payload = JSON.parse(res);
      const orderMessage = (payload:any) => (
        <div className="">
           <div className="text-alert">🔔 {payload.title}</div>
          <Link className="text-dark" to={'/assets/kitchen?kdsId=&area='+payload.venueAreasId+'&venueId='+payload.venueId}>{payload.message}</Link>
        </div>
      );
      toast.success(orderMessage(payload),{
        position: "top-right",
        autoClose: 15000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
        });
    })
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    dispatch(changeLayout(layoutTypes.VERTICAL));
  }, [dispatch]);

  useEffect(() => {
    if (leftSideBarTheme) {
      dispatch(changeSidebarTheme(leftSideBarTheme));
    }
  }, [leftSideBarTheme, dispatch]);

  useEffect(() => {
    if (layoutMode) {
      dispatch(changelayoutMode(layoutMode, layoutType));
    }
  }, [layoutMode, dispatch]);

  useEffect(() => {
    if (layoutWidth) {
      dispatch(changeLayoutWidth(layoutWidth));
    }
  }, [layoutWidth, dispatch]);

  useEffect(() => {
    if (leftSideBarType) {
      dispatch(changeSidebarType(leftSideBarType));
    }
  }, [leftSideBarType, dispatch]);

  useEffect(() => {
    if (topbarTheme) {
      dispatch(changeTopbarTheme(topbarTheme));
    }
  }, [topbarTheme, dispatch]);

  return (
    <React.Fragment>
      <div id="layout-wrapper">
        <Header />
        <Sidebar
          theme={leftSideBarTheme}
          type={leftSideBarType}
          isMobile={isMobile}
        />
        <div className="main-content">
          {props.children}
          <Footer />
        </div>
      </div>
    </React.Fragment>
  );
};

export default Layout;