import React, { useEffect, useState, ChangeEvent } from "react";
import MetaTags from "react-meta-tags";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { withRouter, Link } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  UncontrolledAlert,
  PaginationItem
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import {
//   getHardwareLocationList as onGetHardwareLocationList,

  getHardwareLocation as onGetHardwareLocation,
  updateHardwareLocation as onUpdateHardwareLocation,
  updateHardwareLocationStatus as onUpdateHardwareLocationStatus,
  getHardwareLocationDetail as onGetHardwareLocationDetail,
//   updateHardwareStatus as onUpdateHardwareStatus,
//   addNewHardware as onAddNewHardware,
  addNewHardwareLocation as onAddNewHardwareLocation,
//   updateHardware as onUpdateHardware,
  resetHardwareApiResponseFlag as onResetApiResponseFlag,
  resetHardwareLocationApiResponseFlag as onResetHardwareLocationApiResponseFlag,

  getVenueList as onGetVenueList,
  getVenueArea as onGetVenueArea,
//   getHardwareList
} from "../../slices/thunks";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { isEmpty } from "lodash";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";


import DeleteModal from "../Common/DeleteModal";
//redux
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { json } from "stream/consumers";

const HardwareLocation = () => {
  const dispatch = useDispatch();
  const { SearchBar } = Search;
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [hardwareEvent, setHardwareEvent] = useState<any>({});
  const [modal, setModal] = useState<boolean>(false);
  const [hardwareLocationModel, setHardwareLocationModel] = useState<boolean>(false);
  const [isFormUpdated, setIsFormUpdated] = useState<boolean>(true);
  const [hardwareLocationId, setHardwareLocationId] = useState<any>({});
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [isDelete, setIsDelete] = useState<boolean>(false);
  const [file, setFile] = useState<File>();
  const [workingHours, setWorkingHours] = useState<Array<any>>([])
  const [workingDay, setWorkingDay] = useState<any>({})
  const [venueId, setVenueId] = useState<any>('');
  const [venueAreasId, setVenueAreasId] = useState<any>('');

  // const formData = new FormData();
  const handleShow = () => setModal(false);
  const { HardwareLocation,hardwareLocationMsgResponse,  HardwareLocationList,  HardwareLocationAddUpdate,  HardwareMsgResponse, HardwareAddUpdate, HardwareLocationDetail, venueList, venueArea } = useSelector((state: any) => ({
    hardwareLocationMsgResponse: state.hardwareLocations.hardwareLocationMsgResponse,
    HardwareLocation: state.hardwareLocations.hardwareLocation,
    HardwareLocationDetail: state.hardwareLocations.hardwareLocationDetail,
    HardwareLocationAddUpdate: state.hardwareLocations.hardwareLocationAddUpdate,
    
    HardwareLocationList: state.hardware.hardwareLocationList,
    HardwareMsgResponse: state.hardware.hardwareMsgResponse,
    HardwareAddUpdate: state.hardware.hardwareAddUpdate,
    
    venueList: state.venues.venueList,
    venueArea: state.areas.venueArea,
  }));
  let isForm = false;
  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
      // formData.append("floorPlanImage", e.target.files[0]);
    }
  };

  // get all hardware areas 
  useEffect(() => {
    dispatch(onGetVenueList())  
    dispatch(onGetVenueArea({ id: '' }));
    dispatch(onGetHardwareLocation({venueId: '', venueAreasId: ''}));
  }, [dispatch, HardwareLocationAddUpdate]);
  // console.log("Hardware Location", HardwareLocation)

  useEffect(() => {
    const timer = setTimeout(() => {
      dispatch(onResetHardwareLocationApiResponseFlag());
    }, 5000);
    return () => clearTimeout(timer);
  }, [hardwareLocationMsgResponse]);
  
  const pageOptions = {
    sizePerPage: 10,
    totalSize: HardwareLocation.length, // replace later with size(users) HardwareLocationList.length,
    custom: true,
  };

  const defaultSorted: any = [
    {
      dataField: "_id", // if dataField is not match to any column you defined, it will be ignored.
      order: "asc", // desc or asc
    },
  ];

  const handleDeleteClick = (event: any) => {
    setHardwareEvent({
      id: event._id,
      status: event.status == 1 ? 0 : 1
    });
    setDeleteModal(true);
  };
  /**
  * On delete event
  */
 const handleDeletehardwareLocation = () => {
    dispatch(onUpdateHardwareLocationStatus(hardwareEvent));
    setDeleteModal(false)
  };

  const handleDeleteClose = () => {
    setDeleteModal(false)
    setHardwareEvent({})
  };

  const handleValidFormSubmit = (values: any) => {
    const name = {en: `${values.enName}`,gr: `${values.grName}`}
    values['name'] = JSON.stringify(name);
    // values.venueAresId.length>1 &&
    //   values['venueAresId'] = values.venueAresId[0]
    // }
    {if(values.venueAreasId.length===1){
      values['venueAreasId'] = values.venueAreasId[0]
    }}
    delete values.enName
    delete values.grName
    if (isEdit) {
      values["hardwareLocationId"] = hardwareEvent._id
      // save edit user
      dispatch(onUpdateHardwareLocation(values));
      console.log('msg h l upd',HardwareMsgResponse)
      setIsEdit(false)
    } else {  
      // save new hardware Area
      dispatch(onAddNewHardwareLocation(values));
    }
    // setIsFormUpdated(true);
    // console.log("ISFormUpdated Values",isFormUpdated);
    isForm = true;
    toggleGetArea();
  };

  const toggleGetArea = () => {
    if (isForm) {
      // Display success message using react-toastify
      toast.success('Updated Successfully', {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
        onClose: () => {
          // Reset the form update flag and close the form
          isForm = false;
          setModal(!modal);
          if(isEdit) {
            setIsEdit(false)
            setHardwareEvent({})
          }
          if (!modal && !isEmpty(hardwareEvent) && !!isEdit) {
            setTimeout(() => {
              setHardwareEvent({})
              setIsEdit(false);
            }, 500);
          }
          // ... (other code)
        }, // Automatically close after 3 seconds
      });
     
    }else{
        isForm = false;
        setModal(!modal);
        if(isEdit) {
          setIsEdit(false)
          setHardwareEvent({})
        }
        if (!modal && !isEmpty(hardwareEvent) && !!isEdit) {
          setTimeout(() => {
            setHardwareEvent({})
            setIsEdit(false);
          }, 500);
        }
    }
  };

  const handleAddHardwareClick = () => {
    toggleGetArea();
  };

  // View And Close View Areas Model
  const toggleViewArea = () => {
    setHardwareLocationModel(!hardwareLocationModel);
    setIsEdit(!isEdit)
  };

  const handleEdithardwareLocationClick = (event: any) => {
    setHardwareEvent({...event, enName: event.name.en, grName: event.name.gr});
    setIsEdit(true);
    toggleGetArea();
  };

  
  // Filter Hardware Location
  const filterHardwareLocation = () => {
    dispatch(onGetVenueArea({ id: `${venueId}` }));
    dispatch(onGetHardwareLocation({venueId: `${venueId}`, venueAreasId: `${venueAreasId}`}));
  };

  const filterArea = (venueId: string) => {
    dispatch(onGetVenueArea({ id: venueId }));
  };

  const HardwareLocationListColumns = [
    {
      text: "HARDWARE CODE",
      dataField: "code",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent: any, hardwareLocation: any) => <React.Fragment>{hardwareLocation.code}</React.Fragment>,
    },
    {
      text: "HARDWARE NAME",
      dataField: "name",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent: any, hardwareLocation: any) => <React.Fragment>{hardwareLocation.name.en}</React.Fragment>,
    },
    {
      text: "VENUE NAME",
      dataField: "venueInfo",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent: any, hardwareLocation: any) => <React.Fragment>{hardwareLocation.venueInfo.name}</React.Fragment>,
    },
    {
      text: "VENUE AREAS",
      dataField: "venueAreaInfo",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent: any, hardwareLocation: any) =>
        <React.Fragment>
          {hardwareLocation.venueAreaInfo.map((area: any, index: any) => (
            <div key={index}>{area.areaName}</div>
          ))}
        </React.Fragment>,
    },
    {
      text: "ACTION",
      dataField: "action",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent: any, hardwareLocation: any) => (
        <React.Fragment>
          <UncontrolledDropdown>
            <DropdownToggle tag="button" className="btn btn-light btn-sm">
              <i className="uil uil-ellipsis-h"></i>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-end">
              <DropdownItem to="#" onClick={() => handleEdithardwareLocationClick(hardwareLocation)}>Edit Location</DropdownItem>
              <DropdownItem to="#" onClick={() => handleDeleteClick(hardwareLocation)}>Delete</DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </React.Fragment>
      ),
    },
  ];

  return (
    <React.Fragment>
      
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeletehardwareLocation}
        onCloseClick={handleDeleteClose}
        deleteButtonLable={"Delete"}
      />
      <div className="page-content">
        <MetaTags>
          <title>Hardware Location | Retaurant Booking</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Restaurant" breadcrumbItem="Hardware Location" />
          {hardwareLocationMsgResponse && hardwareLocationMsgResponse.message ? (
            <UncontrolledAlert color="primary">
              {hardwareLocationMsgResponse.message}
            </UncontrolledAlert>
          ) : null}
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="_id"
                        data={HardwareLocation}
                        columns={HardwareLocationListColumns}
                        bootstrap4
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            
                            <Row className="align-items-start">
                              <div className="col-sm">
                                <div>
                                  <Button color="light" onClick={handleAddHardwareClick} className="mb-4 button-success">
                                    <i className="mdi mdi-plus me-1"></i>
                                    Add Hardware Location</Button>
                                </div>
                              </div>
                              <Col xs={3} md={3}>        <select className="input-select" onClick={filterHardwareLocation} onChange={(e) => setVenueId(e.target.value)}>
                                    <option value=""> Select Venue </option>
                                    {
                                      venueList.map((venue: any, key: any) => (
                                        <option value={venue._id} key={key}>{venue.name}</option>
                                      ))}
                                  </select>  </Col>
                              <Col xs={3} md={3}>    <select className="input-select" onClick={filterHardwareLocation} onChange={(e) => setVenueAreasId(e.target.value)}>
                                    <option value=""> Select Area </option>
                                    {
                                      venueArea.map((area: any, key: any) => (
                                        <option value={area._id} key={key}>{area.areaName}</option>
                                      ))}
                                  </select>  </Col>
                          
                            </Row>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                    defaultSorted={defaultSorted}
                                    classes={
                                      "table align-middle table-nowrap table-hover"
                                    }
                                    bordered={false}
                                    striped={false}
                                  />
                                  <Modal isOpen={modal} toggle={toggleGetArea}>
                                    <ModalHeader toggle={toggleGetArea} tag="h4">
                                      {!!isEdit ? "Edit Hardware Location" : "Add Hardware Location"}
                                    </ModalHeader>
                                    <ModalBody>
                                      <AvForm
                                        encType='multipart/form-data'
                                        onValidSubmit={(
                                          e: any,
                                          values: any
                                        ) => {
                                          
                                          handleValidFormSubmit(values);
                                        }}
                                      >
                                        <Row form>
                                          <Col xs={12}>
                                            <div className="mb-3">
                                              <AvField
                                                name="venueId"
                                                label="Select Venue"
                                                type="select"
                                                placeholder="Select Venue"
                                                errorMessage="Select valid Venue"
                                                multiple={false}
                                                required
                                                onChange={(e:any) => filterArea(e.target.value)}
                                                value={hardwareEvent.venueId || ""}
                                              >
                                                <option key = 'blankChoice' hidden value=""> Select Venue </option>
                                                { 
                                                venueList.map(({ _id,name }: any, key: any) => (
                                                  <option value={_id}  key={key}>{name}</option>
                                                ))}
                                              </AvField>
                                            </div>
                                            <div className="mb-3">
                                              <AvField
                                                name="venueAreasId"
                                                label="Select Area"
                                                type="select"
                                                placeholder="Select Area"
                                                errorMessage="Select valid Area"
                                                multiple={false}
                                                required
                                                value={Array.isArray(hardwareEvent.venueAreasId) ? hardwareEvent.venueAreasId[0] : hardwareEvent.venueAreasId || ""}
                                              >
                                                <option key = 'blankChoice' hidden value=""> Select Venue Area </option>
                                                { 
                                                 venueArea.map(({ _id,areaName }: any, key: any) => (
                                                   <option value={_id}  key={key}>{areaName}</option>
                                                  ))}
                                              </AvField>
                                            </div>
                                            <div className="mb-3" >
                                              <AvField
                                                name="enName"
                                                label="Hardware Name (English)"
                                                type="text"
                                                placeholder="Enter Valid Hardware Name"
                                                errorMessage="Invalid Hardware Name"
                                                validate={{
                                                  required: { value: true, },
                                                }}
                                                value={hardwareEvent.enName || ""}
                                              />
                                            </div>
                                            <div className="mb-3" >
                                              <AvField
                                                name="grName"
                                                label="Hardware Name (Greece)"
                                                type="text"
                                                placeholder="Enter Valid Hardware Name"
                                                errorMessage="Invalid Hardware Name"
                                                validate={{
                                                  required: { value: true, },
                                                }}
                                                value={hardwareEvent.grName || ""}
                                              />
                                            </div>
                                            <div className="mb-3" >
                                              <AvField
                                                name="code"
                                                label="Hardware Code"
                                                type="text"
                                                placeholder="Enter Valid Hardware Code"
                                                errorMessage="Invalid Hardware Code"
                                                validate={{
                                                  required: { value: true, },
                                                }}
                                                value={hardwareEvent.code || ""}
                                              />
                                            </div>
                                          </Col>
                                        </Row>
                                        <Row>
                                          <Col>
                                            <div className="text-end">
                                              <button type="button" className="btn btn-light w-sm" onClick={handleShow}>Close</button>
                                              <button
                                                type="submit"
                                                className="btn btn-success save-user"
                                              >
                                                Save
                                              </button>
                                            </div>
                                          </Col>
                                        </Row>
                                      </AvForm>
                                    </ModalBody>
                                  </Modal>
                                </div>
                              </Col>
                            </Row>
                            <Row className="align-items-md-center mt-30">
                              <Col className="pagination pagination-rounded justify-content-end mb-2">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </Col>
                            </Row>
                            <ToastContainer />
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row >
        </Container >
      </div >
      
    </React.Fragment >
  );
};

export default HardwareLocation;