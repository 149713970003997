import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from "react-redux";
import moment from 'moment';
import MetatTags from "react-meta-tags"
import { Card, Spinner, CardImg, Col, Container, Row, Table, Button, Nav, NavItem, Alert, TabContent, TabPane, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { CiFlag1 } from "react-icons/ci";
import { FaRegArrowAltCircleUp } from "react-icons/fa";
import { FaRegArrowAltCircleDown } from "react-icons/fa";
import { FaPrint, FaCcMastercard } from "react-icons/fa";
import { FaRegFlag } from "react-icons/fa";
import { ImCancelCircle } from "react-icons/im";
import Icon from "@ailibs/feather-react-ts";
import {
  getAllOrderList as onGetAllOrderList,
  updateSetOrderStatus as onUpdateSetOrderStatus,
  getVenueList as onGetVenueList,
  getVenueArea as onGetVenueArea,
  getAssetsList as onGetAssetsList,
  updateSetOrderItemStatus as onUpdateSetOrderItemStatus,
  getVenueHardware as onGetHardware
} from "../../slices/thunks";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import orderImg from "../../assets/images/empty.png";
import { updateOrderItemStatus } from "src/helpers/backend_helper";
const Kitchen = () => {
  const dispatch = useDispatch();
  const { orderAllList, orderStatus, venueList, venueArea, assetsList, stausCode, hardwareList } = useSelector((state: any) => ({
    orderAllList: state.orders.orderAllList,
    orderStatus: state.orders.orderStatus,
    venueList: state.venues.venueList,
    venueArea: state.areas.venueArea,
    assetsList: state.assets.assetsList,
    stausCode: state.orders.stausCode,
    hardwareList: state.hardware.hardwareList,
  }));
  const [currentNavigation, setCurrentNavigation] = useState('All Activity')
  const [currentCard, setCurrentCard] = useState(null)
  const kitchenNavigation = ['All Activity', 'New Order', 'Preparing', 'Done', 'Archived', 'Cancelled']
  const [modal, setModal] = useState(false);
  const [statusModel, setStatusModal] = useState(false);
  const [nestedModal, setNestedModal] = useState(false);
  const [closeAll, setCloseAll] = useState(false);
  const [orderEvent, setOrderEvent] = useState<any>({});
  const [itemEvent, setItemEvent] = useState<any>({});
  const [paramItem, setparamItem] = useState<any>({});
  const [videoUrl, setVideoUrl] = useState<any>({});
  const [isOpen, setOpen] = useState(false);
  const [spin, setSpin] = useState(false);
  const handleVideoModel = () => setOpen(false);

  const getOrder = (tab: any) => {
    if (tab === "All Activity") {
      dispatch(onGetAllOrderList({ status: "All Activity", kdsId: paramItem.kdsId }));
    } else if (tab === "New Order") {
      dispatch(onGetAllOrderList({ status: "New Order", kdsId: paramItem.kdsId }));
    } else if (tab === "Preparing") {
      dispatch(onGetAllOrderList({ status: "Preparing", kdsId: paramItem.kdsId }));
    } else if (tab === "Done") {
      dispatch(onGetAllOrderList({ status: "Done", kdsId: paramItem.kdsId }));
    } else if (tab === "Archived") {
      dispatch(onGetAllOrderList({ status: "Archived", kdsId: paramItem.kdsId }));
    } else if (tab === "Cancelled") {
      dispatch(onGetAllOrderList({ status: "Cancelled", kdsId: paramItem.kdsId }));
    }
  };

  const toggle = () => setModal(!modal);
  const handleShowClose = () => setStatusModal(false);
  const openModelStatus = () => setStatusModal(true);

  const updateOrderStatus = () => {
    if (orderEvent.hasOwnProperty("status")) {
      if (orderEvent.status === "New Order") {
        dispatch(onUpdateSetOrderStatus({ orderId: orderEvent.orderId, status: "Preparing" }));
      } else if (orderEvent.status === "Preparing") {
        dispatch(onUpdateSetOrderStatus({ orderId: orderEvent.orderId, status: "Completed" }));
      } else {
        dispatch(onUpdateSetOrderStatus({ orderId: orderEvent.orderId, status: orderEvent.status }));
      }
    }
    const timer = setTimeout(() => {
      getOrder(currentNavigation)
    }, 1000);
    toggle();
  }

  const filterAssetArea = (venueId: string) => {
    dispatch(onGetVenueArea({ id: venueId }));
    dispatch(onGetAllOrderList({ status: currentNavigation, venueId: venueId }));
  };

  const filterAssetAreaList = (venueAreasId: string) => {
    dispatch(onGetAssetsList({ venueAreasId: venueAreasId }));
    dispatch(onGetHardware({ id: venueAreasId }));
    //dispatch(onGetAllOrderList({ status: currentNavigation, venueAreasId: venueAreasId }));
  };

  const playVide = (e: any) => {
    setVideoUrl(e)
    setOpen(true)
  }

  const getOrderByFilter = (kdsId: string) => {
    dispatch(onGetAllOrderList({ status: currentNavigation, kdsId: kdsId }));
  }

  const searchOrder = (keyward: string) => {
    dispatch(onGetAllOrderList({ status: currentNavigation, keyward: keyward }));
  }

  const setOrderEvents = (orderId: string, status: string) => {
    if (status === "New Order") {
      setOrderEvent({ orderId: orderId, status: status, confirmStatus: "Preparing" })
    } else if (status === "Preparing") {
      setOrderEvent({ orderId: orderId, status: status, confirmStatus: "Complete" })
    } else {
      setOrderEvent({ orderId: orderId, status: status, confirmStatus: status })
    }
  }

  const setOrderItemEvents = (orderId: string, eventMenuId: string, status: string) => {
    setItemEvent({ orderId: orderId, status: status, eventMenuId: eventMenuId })
    dispatch(onUpdateSetOrderItemStatus({ orderId: orderId, eventMenuId: eventMenuId, status: status }))
    const timer = setTimeout(() => {
      getOrder(currentNavigation)
    }, 1000);
  }

  const updateOrderItem = () => {
    dispatch(onUpdateSetOrderItemStatus({ orderId: itemEvent.orderId, eventMenuId: itemEvent.eventMenuId, status: itemEvent.status }))
    const timer = setTimeout(() => {
      getOrder(currentNavigation)
    }, 1000);
    handleShowClose();
  }

  const toggleAll = () => {
    setCloseAll(true);
  };

  const onEventClick = (tab: any) => {
    setCurrentNavigation(tab)
    getOrder(tab)
  };

  const getOrdertimeCount = (date: any) => {
    if (date == undefined || date == null)
      return "00Hr 00Min"
    let timeStart = new Date(date).getTime();
    let timeEnd = new Date().getTime();
    let hourDiff = timeEnd - timeStart; //in ms
    let secDiff = hourDiff / 1000; //in s
    let minDiff = hourDiff / 60 / 1000; //in minutes
    let hDiff = hourDiff / 3600 / 1000; //in hours
    let h: number = Number(Math.floor(hDiff));
    let prepareTime = `${h}Hr  ${Math.trunc(minDiff - 60 * h)}min`
    return prepareTime;
  };

  const createMarkup = (e: string) => {
    return { __html: e };
  }

  useEffect(() => {

    dispatch(onGetVenueList());
    const urlSearchString = window.location.search;
    const params = new URLSearchParams(urlSearchString);
    setparamItem({
      'kdsId': params.get('kdsId'),
      'venueAreaId': params.get('area'),
      'venueId': params.get('venueId')
    })

    if (params.get('venueId'))
      dispatch(onGetVenueArea({ id: params.get('venueId') }));

    if (params.get('kdsId')) {
      dispatch(onGetAllOrderList({ kdsId: params.get('kdsId'), venueAreaId: params.get('area'), status: "All Activity" }));
    } else {
      dispatch(onGetAllOrderList({ status: "All Activity" }));
    }
    if (params.get('area'))
      dispatch(onGetHardware({ id: params.get('area') }));
  }, [dispatch]);

  // styles
  const orderBox: Record<string, string> = { 'Done': 'ready-section', 'Preparing': 'preparing-section', 'New Order': 'new-order-section', 'Archived': 'bg-archived', 'Cancelled': 'bg-cancelled' }
  return (
    <React.Fragment>
      <div className="page-content">
        <Modal
          isOpen={modal}
          toggle={toggle}
          onClosed={closeAll ? toggle : undefined}
        >
          <ModalHeader>Are you sure want to update order {orderEvent.confirmStatus}?</ModalHeader>
          <ModalFooter>
            <Button color="danger" onClick={toggle}>
              Cancel
            </Button>{' '}
            <Button color="secondary" onClick={() => { updateOrderStatus() }}>
              Yes
            </Button>
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={statusModel}
          toggle={handleShowClose}
          onClosed={closeAll ? handleShowClose : undefined}
        >
          <ModalHeader>Are you sure want to update order item {orderEvent.confirmStatus}?</ModalHeader>
          <ModalFooter>
            <Button color="danger" onClick={handleShowClose}>
              Cancel
            </Button>{' '}
            <Button color="secondary" onClick={() => { updateOrderItem() }}>
              Yes
            </Button>
          </ModalFooter>
        </Modal>

        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <MetatTags>
          <title>Kitchen | Retaurant Booking</title>
        </MetatTags>

        <Container fluid style={{ fontWeight: 600 }}>
          {/* <Row>
            <Col xs={3} md={3}>KDS Connection Info:</Col>
          </Row> */}
          <Row>
            <Col xs={3} md={3}>
              Venue :
              <select className="input-select" onChange={(e) => filterAssetArea(e.target.value)}>
                <option value=""> Select Venue </option>
                {
                  venueList.map((venue: any, key: any) => (
                    <option value={venue._id} key={key} selected={(paramItem.venueId)}>{venue.name}</option>
                  ))}
              </select>
            </Col>
            <Col xs={3} md={3}>
              Area :
              <select className="input-select" onChange={(e) => filterAssetAreaList(e.target.value)}>
                <option value=""> Select Area </option>
                {
                  venueArea.map((area: any, key: any) => (
                    <option value={area._id} key={key} selected={(paramItem.venueAreaId)}>{area.areaName}</option>
                  ))}
              </select>  </Col>
            <Col xs={3} md={3}>
              KDS :
              <select className="input-select" value={paramItem.kdsId} onChange={(e) => getOrderByFilter(e.target.value)}>
                <option value=""> Select KDS </option>
                {
                  hardwareList.map((kds: any, key: any) => (
                    <option value={kds._id} key={key}>{kds.name.en}</option>
                  ))
                }
              </select>  </Col>
            {/* <Col xs={3} md={3}>    <select className="input-select" onChange={(e) => getOrderByFilter(e.target.value)}>
              <option value=""> Select Asset </option>
              {
                assetsList.map((area: any, key: any) => (
                  <option value={area._id} key={key}>{area.assetName}</option>
                ))}
            </select>  </Col> */}
            <Col xs={3} md={3}>
              Search
              <input className="search-input" type="search" placeholder="Search by Order ID..." onChange={(e) => searchOrder(e.target.value)} />
            </Col>
          </Row>
          <Row className='kds-box'>
            <div className='d-none d-md-block '>
              <Row className='p-2 nav nav-pills d-none d-md-flex'>
                {kitchenNavigation.map((navItem: any, index: any) => (
                  <Col key={index} className='text-center cursor-pointer nav-item'>
                    {currentNavigation === `${navItem}` ?
                      <span className='border border-9 border-color-card p-1 rounded nav-link'>
                        {navItem}
                      </span>
                      :
                      <span className='' onClick={() => onEventClick(navItem)}>
                        {navItem}
                      </span>}
                  </Col>
                ))}
              </Row>
            </div>

            <div className='d-block d-md-none'>
              <select className="input-select border border-9" onChange={(e) => setCurrentNavigation(e.target.value)}>
                {
                  kitchenNavigation.map((navItem: any, index: any) => (
                    <option value={navItem} key={index}>{navItem}</option>
                  ))}
              </select>
            </div>
            {currentNavigation === 'All Activity' ?
              <Row className='py-3'>
                {
                  (orderAllList.length > 0) ?
                    orderAllList.map((item: any, index: any) => (
                      <Col key={index} xs="12" sm="8" md="6" onClick={() => setCurrentCard(index)}>
                        <Card className='medium-border-radius cursor-pointer'>
                          <div className={`${currentCard === index && 'bg-selected'} medium-border-radius p-2`}>
                            <div className={`${currentCard === index ? 'bg-selected' : orderBox[item.status]} py-1 top-border-radius`}>
                              <Row className='text-light d-flex justify-content-evenly'>
                                <Col xs={6} md={8} className='d-flex justify-content-evenly'>
                                  <div>#{index + 1}</div>
                                  <div>{item.status}</div>
                                  <div>{getOrdertimeCount(item.startDate)}</div>
                                  {(item.paymentStatus == "Completed") ?
                                    <div className='d-flex align-items-center  text-light'>
                                      <FaCcMastercard className='mx-2' />
                                      {item.paymentStatus}
                                    </div>
                                    :
                                    (item.paymentStatus == "Pending") ? <div className='d-flex align-items-center text-light'>
                                      <FaCcMastercard className='mx-2' />
                                      {item.paymentStatus}
                                    </div> : <div className='d-flex align-items-center  text-light'>
                                      <FaCcMastercard className='mx-2' />
                                      {item.paymentStatus}
                                    </div>
                                  }
                                </Col>
                                <Col>
                                  {//currentCard === index &&
                                    <div className='text-light d-flex justify-content-evenly'>
                                      {
                                        (item.status === "Done") ? "" : <div className='cursor-pointer' onClick={() => { toggle(), setOrderEvents(item._id, item.status) }}><FaRegArrowAltCircleUp /></div>
                                      }
                                      {
                                        (item.status === "Done" || item.status === "Cancelled") ? <div className='cursor-pointer' onClick={() => { toggle(), setOrderEvents(item._id, "Archived") }}><FaRegArrowAltCircleDown /></div> :
                                          <div className='cursor-pointer' onClick={() => { toggle(), setOrderEvents(item._id, "Cancelled") }}><FaRegArrowAltCircleDown /></div>
                                      }

                                      <div className='cursor-pointer'><FaRegFlag /></div>
                                      <div className='cursor-pointer'><i className='mdi mdi-printer'></i></div>
                                    </div>
                                  }
                                </Col>
                              </Row>
                            </div>
                            <div className='medium-border-radius'>
                              <div className={`${orderBox[item.status]} p-3`}>
                                <div className='d-flex justify-content-evenly '>
                                  <Table>
                                    <tbody>
                                      <tr>
                                        <th scope="row" className='text-light'>
                                          #{item.orderNumber}
                                        </th>
                                        <td className='text-light'>
                                          Date: {moment(item.orderDate).format('DD-MM-YYYY hh:mm A')}
                                        </td>
                                        {/* <td><i className='mdi mdi-printer'></i></td> */}
                                      </tr>
                                    </tbody>
                                  </Table>
                                  {/* <div>Order: #{item.orderNumber}</div>
                                  <div></div>
                                  <div>Time: {moment(item.orderDate).format('hh:mm A')}</div>
                                  <div className='btn btn-md btn-muted'><i className='mdi mdi-printer'></i></div> */}
                                </div>
                              </div>

                              <div className='kitchen-card-items p-3 bottom-border-radius'>
                                <Table >
                                  <thead>
                                    <tr>
                                      <th>
                                        Item
                                      </th>
                                      <th>
                                        Quantity
                                      </th>
                                      <th>
                                        Price
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {item.order_details.map((orderItem: any, index: any) => (
                                      <tr className="table-light" key={index}>
                                        <td>
                                          <div className='text-danger'>{orderItem.menuInfo.name.en}
                                            <a className='btn' title='Play Preparation Tutorial' onClick={() => playVide(orderItem.menuInfo)}>
                                              <Icon className="mdi mdi-play-circle-outline " name={"video"} /></a></div>
                                          <div className='text-muted'>Size: {orderItem.menuInfo.size.name.toUpperCase()}</div>
                                          <div className='text-muted'>Supplement: {orderItem.description}</div>

                                          {
                                            (orderItem.status === 'delivered') ?

                                              <Button color='badge bg-blue primary' className="p-0"><Icon name='check' className="my-awesome-class" /> {orderItem.status.toUpperCase()} </Button>
                                              :
                                              orderItem.status === 'pending'
                                                ? <Button color='badge bg-white light' className="p-0"
                                                  onClick={() => {
                                                    //openModelStatus(), 
                                                    setOrderItemEvents(orderItem.reservationId, orderItem.menuId, 'in progress')
                                                  }}><Icon name='shopping-cart' className="my-awesome-class" /> {orderItem.status.toUpperCase()}</Button>
                                                : <>
                                                  {orderItem.status === 'in progress'
                                                    ? <Button color='badge bg-red primary' className="p-0"
                                                      onClick={() => {
                                                        //openModelStatus(), 
                                                        setOrderItemEvents(orderItem.reservationId, orderItem.menuId, 'ready for pickup')
                                                      }}><Icon name='aperture' className="my-awesome-class" />{orderItem.status.toUpperCase()}</Button>
                                                    : <Button color='badge bg-green primary' className="p-0" onClick={() => {
                                                      //openModelStatus(), 
                                                      setOrderItemEvents(orderItem.reservationId, orderItem.menuId, 'delivered')
                                                    }}><Icon name='truck' className="my-awesome-class" /> {orderItem.status.toUpperCase()} </Button>
                                                  }

                                                </>

                                          }
                                        </td>
                                        <td>
                                          {orderItem.quantity}
                                        </td>
                                        <td>
                                          € {orderItem.price}
                                        </td>
                                      </tr>
                                    ))}
                                    {/* <tr>
                                      <th scope="row" colSpan={2} className="text-end">Sub Total</th>
                                      <td className="text-end">&#8364; {item.subTotalAmount}</td>
                                    </tr>

                                    <tr>
                                      <th scope="row" colSpan={2} className="border-0 text-end">
                                        Discount : </th>
                                      <td className="border-0 text-end">-  &#8364; {item.discount}</td>
                                    </tr>
                                    <tr>
                                      <th scope="row" colSpan={2} className="border-0 text-end">
                                        Vat</th>
                                      <td className="border-0 text-end">&#8364; {item.tax}</td>
                                    </tr>

                                    <tr>
                                      <th scope="row" colSpan={2} className="border-0 text-end">Total</th>
                                      <td className="border-0 text-end"><h4 className="m-0 fw-semibold">&#8364; {item.totalAmount}</h4></td>
                                    </tr> */}
                                  </tbody>

                                </Table>
                              </div>
                            </div>
                          </div>
                        </Card>
                      </Col>
                    ))
                    :

                    (stausCode <= 200) ?
                      <Spinner className='setSpinner'
                        color="primary"
                        style={{
                          height: '3rem',
                          width: '3rem'
                        }}
                      >
                        Loading...
                      </Spinner> :
                      <Alert color="danger">
                        Orders Not found!
                      </Alert>
                }
              </Row>
              :
              <div className='py-3'>
                {
                  (orderAllList.length > 0) ?
                    orderAllList.map((item: any, index: any) => (
                      (currentNavigation === `${item.status}` &&
                        <Card key={index}>
                          {/* action bar */}
                          <div className='d-flex justify-content-between p-3'>
                            <div>
                              {
                                (item.status != "Cancelled" && item.status != "Done" && item.status != "Archived") ? <div className='cursor-pointer' onClick={() => { toggle(), setOrderEvents(item._id, "Cancelled") }} > <ImCancelCircle className='mx-2' />  Cancel</div> : ""
                              }

                            </div>
                            <div className='d-flex'>
                              {
                                (item.status != "Archived" && item.status != "Cancelled" && item.status != "Done") ? <div className='d-flex align-items-center cursor-pointer' onClick={() => { toggle(), setOrderEvents(item._id, item.status) }}> <FaRegArrowAltCircleDown className='mx-2' />  New</div> : ""
                              }
                              {
                                (item.status != "Archived" && item.status != "Cancelled" && item.status != "Done") ? <div className='d-flex align-items-center px-3 cursor-pointer' onClick={() => { toggle(), setOrderEvents(item._id, "Done") }}> <FaRegArrowAltCircleUp className='mx-2' />  Done</div> : ""
                              }
                              {
                                (item.status == "New Order" && item.status != "Preparing") ? <div className='d-flex align-items-center cursor-pointer'> <FaRegFlag className='mx-2' />  Prioritize</div> : ""
                              }{' '}
                              <div className='d-flex align-items-center cursor-pointer'>
                                <FaPrint className='mx-2' />
                                Print</div>

                              {(item.paymentStatus == "Completed") ?
                                <div className='d-flex align-items-center cursor-pointer text-success'>
                                  <FaCcMastercard className='mx-2' />
                                  {item.paymentStatus}
                                </div>
                                :
                                (item.paymentStatus == "Pending") ? <div className='d-flex align-items-center cursor-pointer text-info'>
                                  <FaCcMastercard className='mx-2' />
                                  {item.paymentStatus}
                                </div> : <div className='d-flex align-items-center cursor-pointer text-danger'>
                                  <FaCcMastercard className='mx-2' />
                                  {item.paymentStatus}
                                </div>
                              }

                            </div>
                          </div>


                          {/* status bar className='p-3 bg-preparing' */}
                          <div className={`${orderBox[item.status]} p-3`}>
                            <div className='d-flex text-light'>
                              <div>{item.status}</div>
                              <div className='px-3 text-light'>{getOrdertimeCount(item.startDate)}</div>
                            </div>
                            <div className='d-flex mt-1 text-light'>
                              <div>#{index + 1}</div>
                              <div className='px-3 text-light'>#{item.orderNumber}</div>
                              {/* <div>Main Server</div> */}
                            </div>
                          </div>

                          {/* Order Items bg-selected */}
                          <Row className='p-0 m-0'>
                            <Col className='p-0 m-0' xs={12} md={6}>
                              <Table>
                                <thead>
                                  <tr>
                                    <th>
                                      Item
                                    </th>
                                    <th>
                                      Quantity
                                    </th>
                                    <th>
                                      Price
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {item.order_details.map((orderItem: any, index: any) => (
                                    <tr className="table-light" key={index}>
                                      <td>
                                        {orderItem.menuInfo.name.en}
                                        <a className='btn' title='Play Preparation Tutorial' onClick={() => playVide(orderItem.menuInfo)}>
                                          <Icon className="mdi mdi-play-circle-outline " name={"video"} /></a>
                                        <div className='text-muted'>Size: {orderItem.menuInfo.size.name}</div>
                                        <div className='text-muted'>EX: {orderItem.description}</div>

                                        {
                                          (orderItem.status === 'delivered') ?

                                            <Button color='badge bg-blue primary' className="p-0"><Icon name='check' className="my-awesome-class" /> {orderItem.status.toUpperCase()} </Button>
                                            :
                                            orderItem.status === 'pending'
                                              ? <Button color='badge bg-white light' className="p-0"
                                                onClick={() => {
                                                  //openModelStatus(), 
                                                  setOrderItemEvents(orderItem.reservationId, orderItem.menuId, 'in progress')
                                                }}><Icon name='shopping-cart' className="my-awesome-class" /> {orderItem.status.toUpperCase()}</Button>
                                              : <>
                                                {orderItem.status === 'in progress'
                                                  ? <Button color='badge bg-red primary' className="p-0"
                                                    onClick={() => {
                                                      //openModelStatus(), 
                                                      setOrderItemEvents(orderItem.reservationId, orderItem.menuId, 'ready for pickup')
                                                    }}><Icon name='aperture' className="my-awesome-class" />{orderItem.status.toUpperCase()}</Button>
                                                  : <Button color='badge bg-green primary' className="p-0" onClick={() => {
                                                    //openModelStatus(), 
                                                    setOrderItemEvents(orderItem.reservationId, orderItem.menuId, 'delivered')
                                                  }}><Icon name='truck' className="my-awesome-class" /> {orderItem.status.toUpperCase()} </Button>
                                                }

                                              </>

                                        }
                                      </td>
                                      <td>
                                        {orderItem.quantity}
                                      </td>
                                      <td>
                                        € {orderItem.price}
                                      </td>
                                    </tr>
                                  ))}
                                  {/* <tr>
                                    <th scope="row" colSpan={2} className="text-end">Sub Total</th>
                                    <td className="text-end">&#8364; {item.subTotalAmount}</td>
                                  </tr>

                                  <tr>
                                    <th scope="row" colSpan={2} className="border-0 text-end">
                                      Discount : </th>
                                    <td className="border-0 text-end">-  &#8364; {item.discount}</td>
                                  </tr>
                                  <tr>
                                    <th scope="row" colSpan={2} className="border-0 text-end">
                                      Vat</th>
                                    <td className="border-0 text-end">&#8364; {item.tax}</td>
                                  </tr>

                                  <tr>
                                    <th scope="row" colSpan={2} className="border-0 text-end">Total</th>
                                    <td className="border-0 text-end"><h4 className="m-0 fw-semibold">&#8364; {item.totalAmount}</h4></td>
                                  </tr> */}
                                </tbody>

                              </Table>
                            </Col>
                            {/* Selected Item with image */}
                            <Col className='p-0 m-0' xs={12} md={6}>
                              <CardImg
                                top
                                width="100%"
                                src="https://www.eatingwell.com/thmb/TK5LIw0WrWBv0YZXDQBtly0Dl74=/750x0/filters:no_upscale():max_bytes(150000):strip_icc():format(webp)/chopped-salad-7-d93e6b52b9f94831913a8d3cb2a68ced.jpeg"
                                alt="Image"
                              />
                              {/* <div className=' p-2'>
                            <p className='px-2'>S2</p>
                            <div className=' p-2'>Small Caeser Salad</div>
                            <p className='px-2 p-0 m-0 text-muted'>Baby Kale, Avocado Slices, Cucumber, Croutons</p>
                          </div> */}
                            </Col>
                          </Row>
                        </Card>)
                    ))
                    :

                    (stausCode <= 200) ?
                      <Spinner className='setSpinner'
                        color="primary"
                        style={{
                          height: '3rem',
                          width: '3rem'
                        }}
                      >
                        Loading...
                      </Spinner> :
                      <Alert color="danger">
                        Orders Not found!
                      </Alert>

                }
              </div>
            }
            <Modal isOpen={isOpen} toggle={handleVideoModel} size="lg" >
              <ModalHeader toggle={handleVideoModel} tag="h4">
                Item Preparation
              </ModalHeader>
              <ModalBody>
                <Row>
                  <Col sm={12} className="mt-1">
                    <h6>Prepare Tutorial</h6>
                    {/* <div className="py-1" > */}
                    <video width="100%" height="auto" controls>
                      <source src={videoUrl.videoUrl} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                    {/* </div> */}
                  </Col>
                  <Col sm={12} className="mt-1">
                    <div className="mb-5">
                      <div dangerouslySetInnerHTML={createMarkup(videoUrl.prepareDescription)}></div>
                    </div>
                  </Col>
                </Row>
              </ModalBody>
            </Modal>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Kitchen