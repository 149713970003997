import { createAsyncThunk } from "@reduxjs/toolkit";
import {
    getNotification as getNotificationAPi,
    deleteNotification as deleteNotificationApi
} from "../../helpers/backend_helper";

export const getNotification = createAsyncThunk(
    "notification/getNotification",
    async (event: any) => {
        try {
            const response = getNotificationAPi(event);
            return response;
        } catch (error) {
            return error;
        }
    }
);

export const deleteNotification = createAsyncThunk(
    "notification/deleteNotification",
    async (event: any) => {
        try {
            const response = deleteNotificationApi(event);
            return response;
        } catch (error) {
            return error;
        }
    }
);

export const resetApiNotificationResponseFlag = createAsyncThunk(
    "notification/resetApiNotificationResponseFlag",
    async () => {
        try {
            return true;
        } catch (error) {
            return error;
        }
    }
);
