import React, { useEffect, useState, ChangeEvent, useRef } from "react";
import MetaTags from "react-meta-tags";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  UncontrolledAlert
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import {
  getAddOns as onGetAdOnsList,
  addNewAddOns as onAddNewAddOns,
  updateAddOns as onUpdateAddOns,
  getVenueList as onGetVenueList,
  deleteAddOns as onDeleteAddOns,
  getMenuCategories as onGetgetCategories,
  addNewItem as onAddNewItem,
  updateItemStatus as onUpdateItemStatus,
  updateItem as onUpdateItem,
  resetItemApiResponseFlag as onResetApiResponseFlag,
  getCategoriesType as OnGetCategoriesType,
  getSubCategoriesType as OnGetSubCategoriesType,
  getItemSize as onGetItemSize,
  getServingOrder as onGetServingOrder,
  uploadItem as OnUploadItem
} from "../../slices/thunks";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { isEmpty } from "lodash";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { ToastContainer, toast } from 'react-toastify';
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import 'react-toastify/dist/ReactToastify.css';
import BootstrapTable from "react-bootstrap-table-next";
import DeleteModal from "../Common/DeleteModal";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import Categories from "../Categories";
import { Editor } from "@tinymce/tinymce-react";
import 'quill/dist/quill.snow.css'
import ReactQuill from 'react-quill'
import Icon from "@ailibs/feather-react-ts/dist/Icon";

const AddOns = () => {
  const dispatch = useDispatch();

  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [itemEvent, setItemEvent] = useState<any>({});
  const [modal, setModal] = useState<boolean>(false);
  const [preparationModal, setPreparationModal] = useState<boolean>(false);
  const [uploadModel, setUploadModel] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const handleShow = () => setModal(false);
  const handleUploadModel = () => setUploadModel(false);
  const [file, setFile] = useState<File>();
  const [videoFile, setVideoFile] = useState<File | null>(null);
  const [contentText, setContentText] = useState('');
  const [videoUrl, setVideoUrl] = useState<any>({});
  const [isOpen, setOpen] = useState(false);
  const handleVideoModel = () => setOpen(false);
  const [isVenueId, setVenueId] = useState('');
  const [isCategoryId, setCategoryId] = useState('');
  const [page, setPage] = useState<any>(1);
  const [items, setItems] = useState<any>([]);
  
  const { venueList, categories, getAdOnsList, itemMsgResponse, itemAddUpdate } = useSelector((state: any) => ({
    venueList: state.venues.venueList,
    categories: state.categories.categories,
    getAdOnsList: state.categories.getAdOnsList,
    itemMsgResponse: state.items.itemMsgResponse,
    itemAddUpdate: state.items.itemAddUpdate,
  }));

  useEffect(() => {
      if(page===1) {
        setItems(getAdOnsList)
      }
      else {
        setItems((prevItems: any) => ([ ...prevItems, ...getAdOnsList ]));
      }
  }, [getAdOnsList])

  useEffect(() => {
    dispatch(onGetAdOnsList({ catId: `${isCategoryId}`, venueId: isVenueId, pageNumber: `${page}` }));
  }, [page, dispatch])

  useEffect(() => {
    //dispatch(onGetgetCategories({}));
    dispatch(onGetVenueList());
  }, [dispatch]);

  useEffect(()=>{
    window.addEventListener("scroll", handlePaggination);
    return () => window.removeEventListener("scroll", handlePaggination);
  }, [])

  useEffect(() => {
    dispatch(onGetAdOnsList({ catId: `${isCategoryId}`, pageNumber: `${page}`, venueId: isVenueId }));
  }, [itemAddUpdate]);

  useEffect(() => {
    const timer = setTimeout(() => {
      dispatch(onResetApiResponseFlag());
    }, 5000);
    return () => clearTimeout(timer);
  }, [itemMsgResponse]);

  const handlePaggination = async () => {
    try {
    if(
      Number(window.innerHeight) + Number(document.documentElement.scrollTop) + 1 >=
      Number(document.documentElement.scrollHeight)) {
        setPage((prev:any) => Number(prev) + 1)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleVideoChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setVideoFile(file);
    }
  };

  const handleProcedureContentChange = (content: any) => {
    setContentText(content)
  };

  function togglePreparation() {
    setPreparationModal(!preparationModal);
  }

  const defaultSorted: any = [
    {
      dataField: "_id", // if dataField is not match to any column you defined, it will be ignored.
      order: "asc", // desc or asc
    },
  ];

  const filterItems = (catId: string) => {
    setCategoryId(catId);
    setPage(1)
    dispatch(onGetAdOnsList({ catId: catId, venueId: isVenueId, pageNumber: `${page}` }));
  };

  const filterItemsByVenue = (venueId: string) => {
    setVenueId(venueId)
    setPage(1)
    dispatch(onGetAdOnsList({ venueId: venueId, catId: isCategoryId, pageNumber: `${page}` }));
    dispatch(onGetgetCategories({ venueId: venueId }));
  };

  const filterCategoryByVenue = (venueId: string) => {
    dispatch(onGetgetCategories({ venueId: venueId }));
  };

  const getCategory = (categoryTypeId: string) => {
    dispatch(onGetgetCategories({ categoryTypeId: categoryTypeId }));
  }

  const setName = (keyward: string) => {
    dispatch(onGetAdOnsList({ keyward: keyward }));
  };

  const handleDeleteClick = (event: any) => {
    setItemEvent({
      id: event._id,
      status: event.status == 1 ? 0 : 1
    });
    dispatch(onDeleteAddOns(event));
    setTimeout(() => {
      dispatch(onGetAdOnsList({ }));
      setIsEdit(false);
    }, 500);
  };

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
  };
  /**
  * On delete event
  */
  const handleDeleteAsset = () => {
    dispatch(onUpdateItemStatus(itemEvent));
    setDeleteModal(false);
  };

  const handleDeleteClose = () => {
    setDeleteModal(false)
    setItemEvent({})
  };

  const handleValidFormSubmit = (values: any) => {
    if (isEdit) {
      values["id"] = itemEvent._id
      dispatch(onUpdateAddOns(values));
    } else {
      dispatch(onAddNewAddOns(values));
    }
    setItemEvent({});
    setIsEdit(false);
    setModal(!modal);
    setTimeout(() => {
      dispatch(onGetAdOnsList({ }));
      setIsEdit(false);
    }, 500);
  };

  const toggle = () => {
    setModal(!modal);
    if (!modal && !isEmpty(itemEvent) && !!isEdit) {
      setTimeout(() => {
        setItemEvent({})
        setIsEdit(false);
      }, 500);
    }
  };
  
  const handleAddItemClicks = () => {
    setIsEdit(false);
    setItemEvent({})
    setModal(!modal);
  };

  const handleEditItemClick = (event: any) => {
    dispatch(onGetgetCategories({ venueId: event.venueId }));
    setItemEvent(event);
    setIsEdit(true);
    setModal(!modal);

  };

  const venuesListColumns = [
    {
      text: "VENUE",
      dataField: "venueId",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent: any, item: any) => <React.Fragment>{item.venueInfo.name}</React.Fragment>,
    },
    {
      text: "CATEGORY",
      dataField: "categoryId",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent: any, item: any) => <React.Fragment>{item.categoryInfo.name.en}</React.Fragment>,
    },
    {
      text: "TYPE",
      dataField: "type",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent: any, item: any) => <React.Fragment>{(item.type == 'addons') ? 'Add-Ons' : "Condiments"}</React.Fragment>,
    },
    {
      text: "Name",
      dataField: "name",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent: any, item: any) => <React.Fragment>{item.name}</React.Fragment>,
    },
    {
      text: "PRICE",
      dataField: "price",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent: any, item: any) => <React.Fragment>{item.price}€ </React.Fragment>,
    },
    {
      text: "CREATED DATE",
      dataField: "createDate",
      sort: true,
      // eslint-disable-next-line react/display-name 
      formatter: (cellContent: any, item: any) => <React.Fragment>{moment(item.createDate).format('DD MMM Y')}</React.Fragment>,
    },
    {
      text: "ACTION",
      dataField: "action",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent: any, item: any) => (
        <React.Fragment>
          <UncontrolledDropdown>
            <DropdownToggle tag="button" className="btn btn-light btn-sm">
              <i className="uil uil-ellipsis-h"></i>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-end dropdown-position">
              <DropdownItem to="#" onClick={() => handleEditItemClick(item)}>Edit</DropdownItem>
              <DropdownItem to="#" onClick={() => handleDeleteClick(item)}>Delete</DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </React.Fragment>
      ),
    },
  ];

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteAsset}
        onCloseClick={handleDeleteClose}
        deleteButtonLable={itemEvent.status == 0 ? "Inactive" : "Active"}
      />
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <div className="page-content">
        <MetaTags>
          <title>Add-Ons | Retaurant Booking</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Menu" breadcrumbItem="Add-Ons" />
          {itemMsgResponse && itemMsgResponse.message ? (
            <UncontrolledAlert color="primary">
              {itemMsgResponse.message}
            </UncontrolledAlert>
          ) : null}
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                      <ToolkitProvider
                        keyField="_id"
                        data={items}
                        columns={venuesListColumns}
                        bootstrap4
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <Row className="align-items-start">
                              <Col xs={2} md={2}>
                                <Button color="light" onClick={handleAddItemClicks} className="mb-4 button-success">
                                  <i className="mdi mdi-plus me-1"></i>
                                  Add Add-Ons</Button>
                              </Col>

                              <Col xs={3} md={3}>
                                <select className="input-select col-sm"
                                  onChange={(e) => filterItemsByVenue(e.target.value)}
                                >
                                  <option value=""> Venue </option>
                                  {
                                    venueList.map((venue: any, key: any) => (
                                      <option value={venue._id} key={key}>{venue.name}</option>
                                    ))}
                                </select></Col>
                              <Col xs={3} md={3}>
                                <select className="input-select" onChange={(e) => filterItems(e.target.value)}>
                                  <option value=""> Select Category </option>
                                  {
                                    categories.map((category: any, key: any) => (
                                      <option value={category._id} key={key}>{category.name.en}</option>
                                    ))}
                                </select>
                              </Col>
                              <Col xs={2} md={2}>
                                <input className="search-input" type="search" placeholder="Search..." onChange={(e) => setName(e.target.value)} />
                              </Col>
                            </Row>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    {...toolkitProps.baseProps}
                                    defaultSorted={defaultSorted}
                                    classes={
                                      "table align-middle table-nowrap table-hover"
                                    }
                                    bordered={false}
                                    striped={false}
                                  />
                                  <Modal isOpen={modal} toggle={toggle} size="lg" >
                                    <ModalHeader toggle={toggle} tag="h4">
                                      {!!isEdit ? "Edit AddOns" : "Add AddOns"}
                                    </ModalHeader>
                                    <ModalBody>

                                      <AvForm
                                        onValidSubmit={(
                                          e: any,
                                          values: any
                                        ) => {
                                          handleValidFormSubmit(values);
                                        }}
                                      >
                                        <Row form>
                                          <Row >
                                            <Col xs={6} md={4}>
                                              <AvField
                                                type="select"
                                                name="venueId"
                                                placeholder="Select Venue"
                                                errorMessage="Please select venue"
                                                label="Venue"
                                                multiple={false}
                                                onChange={(e: any) => filterCategoryByVenue(e.target.value)}
                                                required
                                                value={itemEvent.venueId || ""}
                                              >
                                                <option key='blankChoice' hidden value=""> Select Venue </option>
                                                {
                                                  venueList.map((venue: any, index: number) => (
                                                    <option value={venue._id} key={index}>{venue.name}</option>
                                                  ))}
                                              </AvField>

                                            </Col>
                                            <Col xs={6} md={4}>
                                              <AvField
                                                type="select"
                                                name="categoryId"
                                                placeholder="Select Category"
                                                errorMessage="Select valid Catrgory"
                                                label="Category"
                                                multiple={false}
                                                required
                                                value={itemEvent.categoryId || ""}
                                              >
                                                <option key='blankChoice1' hidden value=""> Select Category </option>
                                                {
                                                  categories.map((category: any, key: any) => (
                                                    <option value={category._id} key={key}>{category.name.en}</option>
                                                  ))}
                                              </AvField>
                                            </Col>
                                            <Col xs={6} md={4}>
                                              <AvField
                                                type="select"
                                                name="type"
                                                placeholder="Select Type"
                                                errorMessage="Select valid Type"
                                                label="Type"
                                                multiple={false}
                                                required
                                                value={itemEvent.type || ""}
                                              >
                                                <option key='blankChoice1' hidden value=""> Select Type </option>
                                                <option value="condiments" key="Condiments">Condiments</option>
                                                <option value="addons" key="addons">Add-Ons</option>
                                              </AvField>
                                            </Col>
                                            <Col xs={6} md={4}>
                                              <AvField
                                                name="name"
                                                label="Name"
                                                type="text"
                                                placeholder="Enter Valid Name"
                                                errorMessage="Invalid Name"
                                                validate={{
                                                  required: { value: true },
                                                }}
                                                value={itemEvent.name || ""}
                                              />
                                            </Col>
                                            <Col xs={6} md={4}>
                                              <AvField
                                                name="price"
                                                label="Price € (Optional)"
                                                type="number"
                                                placeholder="0.00"
                                                errorMessage="Invalid Price"
                                                validate={{
                                                  required: { value: false },
                                                }}
                                                value={itemEvent.price || 0}
                                              />
                                            </Col>
                                          </Row>
                                        </Row>
                                        <Row>
                                          <Col>
                                            <div className="text-end">
                                              <button type="button" className="btn btn-light w-sm" onClick={handleShow}>Close</button>
                                              <button
                                                type="submit"
                                                className="btn btn-success save-user"
                                              >
                                                Save
                                              </button>
                                            </div>
                                          </Col>
                                        </Row>
                                      </AvForm>
                                    </ModalBody>
                                  </Modal>
                                </div>
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                </CardBody>
              </Card>
            </Col>
          </Row >
        </Container >
      </div >
    </React.Fragment >
  );
};

export default AddOns;
