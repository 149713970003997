import axios from "axios";
import * as url from "./url_helper";
import { ApiCore } from "./api_helper";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const api = new ApiCore();
// Gets the logged in user data from local session
const getLoggedInUser = () => {
  const user = localStorage.getItem("user");
  if (user) return JSON.parse(user);
  return null;
};

//is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser() !== null;
};

// Register Method
const postJwtRegister = (url: string, data: any) => {
  return api
    .create(url, data)
    .then(response => {
      if (response.status >= 200 || response.status <= 299)
        return response.data;
      throw response.data;
    })
    .catch(err => {
      var message;
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found";
            break;
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team";
            break;
          case 401:
            message = "Invalid credentials";
            break;
          default:
            message = err[1];
            break;
        }
      }
      throw message;
    });
};

//----------------------------------------

// Login Method
const postJwtLogin = (data: any) => {
  return api.loginUser(url.LOGIN_URL, data)
};

// Login Method
const findDomain = (data: any) => {
  return api.get(`${url.REST_VENUE}/find-domain?domain=${data}`);
};

//-------------- Menu Categories : start ------
// get Menu Categories
const getMenuCategories = (event: any) => {
  let categoryTypeId = (event.categoryTypeId) ? event.categoryTypeId : ""
  let venueId = (event.venueId) ? event.venueId : ""
  let keyward = (event.keyward) ? event.keyward : ""
  return api.get(`${url.REST_MENU_CATEGORIES}?pageNo=1&size=10&categoryTypeId=${categoryTypeId}&venueId=${venueId}&keyward=${keyward}`);
};

// get item list
const getAddOnsList = (event: any) => {
  const catId = (event.catId) ? event.catId : '';
  const venueId = (event.venueId) ? event.venueId : '';
  const keyward = (event.keyward) ? event.keyward : '';
  const pageNumber = (event.pageNumber) ? event.pageNumber : '';
  return api.get(`${url.REST_MENU_CATEGORIES}/add-ons/list?pageNo=${pageNumber}&categoryId=${catId}&keyward=${keyward}&venueId=${venueId}&size=10`);
};

const addNewAddOns = (event: any) => {
  return api.create(`${url.REST_MENU_CATEGORIES}/add-ons/add`, event).then((response: any) => {
    (response.statusCode === 200) ? toast.success(response.message) : toast.error(response.message)
  });
};

const updateAddOns = (event: any) => {
  return api.update(`${url.REST_MENU_CATEGORIES}/add-ons/update`, event).then((response: any) => {
    (response.statusCode === 200) ? toast.success(response.message) : toast.error(response.message)
  });
};

// delete menu category
const deleteAddOns = (event: any) => {
  return api.delete(`${url.REST_MENU_CATEGORIES}/add-ons/delete?id=${event._id}`, event).then((response: any) => {
    (response.statusCode === 200) ? toast.success(response.message) : toast.error(response.message)
  });
};

// delete menu category
const updateCategoryStatus = (event: any) => {
  return api.update(`${url.REST_MENU_CATEGORIES}/status/update`, event).then((response: any) => {
    (response.statusCode === 200) ? toast.success(response.message) : toast.error(response.message)
  });
};


// add menu category
const addNewCategory = (event: any) => {
  return api.createWithMultipartCategory(url.REST_MENU_CATEGORIES, event).then((response: any) => {
    (response.statusCode === 200) ? toast.success(response.message) : toast.error(response.message)
  });
};

// edit menu user
const updateCategory = (event: any) => {
  return api.updateWithMultipartCategory(`${url.REST_MENU_CATEGORIES}/${event.id}`, event).then((response: any) => {
    (response.statusCode === 200) ? toast.success(response.message) : toast.error(response.message)
  });
};

/**
 * 
 * 
 * Category Type
 * 
 */
const addNewCategoryType = (event: any) => {
  return api.create(`${url.REST_MENU_CATEGORIES_TYPE}/add-category-type`, event).then((response: any) => {
    (response.statusCode === 200) ? toast.success(response.message) : toast.error(response.message)
  });
};

const updateCategoryType = (event: any) => {
  return api.update(`${url.REST_MENU_CATEGORIES_TYPE}/update-category-type/${event.id}`, event).then((response: any) => {
    (response.statusCode === 200) ? toast.success(response.message) : toast.error(response.message)
  });
};

// delete Item
const updateCategoryTypeStatus = (event: any) => {
  return api.update(`${url.REST_MENU_CATEGORIES_TYPE}/category-type/status/update`, event).then((response: any) => {
    (response.statusCode === 200) ? toast.success(response.message) : toast.error(response.message)
  });
};


// get Menu Categories
const getCategoriesType = () => {
  return api.get(`${url.REST_MENU_CATEGORIES_TYPE}/get-category/type?pageNo=1&size=10`);
};

// get Menu Categories
const getItemSize = () => {
  return api.get(`${url.REST_MENU_CATEGORIES_TYPE}/get-category/size?pageNo=1&size=10`);
};

const getNotification = (event: any) => {
  let venueId = (event.venueId) ? event.venueId : ""
  let assetId = (event.assetId) ? event.assetId : ""
  return api.get(`${url.REST_NOTIFICATION}/?pageNo=1&size=10&venueId=${venueId}&assetId=${assetId}`);
};

const deleteNotification = (event: any) => {
  const id = event.id;
  delete event.id;
  return api.delete(`${url.REST_NOTIFICATION}/${id}`).then((response: any) => {
    (response.statusCode === 200) ? toast.success(response.message) : toast.error(response.message)
  });
};

const addItemSize = (event: any) => {
  return api.create(`${url.REST_MENU_CATEGORIES_TYPE}/add-category/size`, event).then((response: any) => {
    (response.statusCode === 200) ? toast.success(response.message) : toast.error(response.message)
  });
};

const updateItemSizeStatus = (event: any) => {
  return api.update(`${url.REST_MENU_CATEGORIES_TYPE}/size/status/update`, event).then((response: any) => {
    (response.statusCode === 200) ? toast.success(response.message) : toast.error(response.message)
  });
};

const updateItemSize = (event: any) => {
  return api.update(`${url.REST_MENU_CATEGORIES_TYPE}/update-subcategory/size/${event.id}`, event).then((response: any) => {
    (response.statusCode === 200) ? toast.success(response.message) : toast.error(response.message)
  });
};


// get Menu Categories
const getServingOrder = () => {
  return api.get(`${url.REST_MENU_CATEGORIES_TYPE}/get-category/serving-order?pageNo=1&size=10`);
};

const addServingOrder = (event: any) => {
  return api.create(`${url.REST_MENU_CATEGORIES_TYPE}/add-category/serving-order`, event).then((response: any) => {
    (response.statusCode === 200) ? toast.success(response.message) : toast.error(response.message)
  });
};

const updateServingOrderStatus = (event: any) => {
  return api.update(`${url.REST_MENU_CATEGORIES_TYPE}/serving-order/status/update`, event).then((response: any) => {
    (response.statusCode === 200) ? toast.success(response.message) : toast.error(response.message)
  });
};

const updateServingOrder = (event: any) => {
  return api.update(`${url.REST_MENU_CATEGORIES_TYPE}/update-subcategory/serving-order/${event.id}`, event).then((response: any) => {
    (response.statusCode === 200) ? toast.success(response.message) : toast.error(response.message)
  });
};

/**
 * 
 * 
 * SubCategory
 * 
 */

// get Menu Categories
const getSubCategories = (event: any) => {
  let categoryId = (event.categoryId) ? event.categoryId : ""
  let venueId = (event.venueId) ? event.venueId : ""
  return api.get(`${url.REST_MENU_CATEGORIES_TYPE}/get-category/subcategory?pageNo=1&size=10&categoryId=${categoryId}&venueId=${venueId}`);
};

const addNewSubCategory = (event: any) => {
  return api.create(`${url.REST_MENU_CATEGORIES_TYPE}/add-category/subcategory`, event).then((response: any) => {
    (response.statusCode === 200) ? toast.success(response.message) : toast.error(response.message)
  });
};

const updateSubCategory = (event: any) => {
  return api.update(`${url.REST_MENU_CATEGORIES_TYPE}/update-subcategory/subcategory/${event.id}`, event).then((response: any) => {
    (response.statusCode === 200) ? toast.success(response.message) : toast.error(response.message)
  });
};

// delete Item
const updateSubCategoryStatus = (event: any) => {
  return api.update(`${url.REST_MENU_CATEGORIES_TYPE}/sub-category/status/update`, event).then((response: any) => {
    (response.statusCode === 200) ? toast.success(response.message) : toast.error(response.message)
  });
};


// get Venues
const getVenueList = () => {
  return api.get(`${url.REST_VENUE}/get-venue`);
};

const getVenues = (e: any) => {
  let isReview = (e.isReview != undefined && e.isReview != "") ? e.isReview : "No";
  let venueId = (e.venueId != undefined && e.venueId != "") ? e.venueId : "";
  return api.get(`${url.REST_VENUE}/get-venue/list?isReview=${isReview}&venueId=${venueId}`);
};

// delete venue
const updateVenueStatus = (event: any) => {
  return api.update(`${url.REST_VENUE}/status/update`, event).then((response: any) => {
    (response.statusCode === 200) ? toast.success(response.message) : toast.error(response.message)
    // console.log('res ', response)
  });
};

// add venue
const addNewVenue = (event: any) => {
  return api.createWithMultipart(`${url.REST_VENUE}/add-venue`, event).then((response: any) => {
    (response.statusCode === 200) ? toast.success(response.message) : toast.error(response.message)
  });
};

// add venue setting configure
const addVenueSettingConfigure = (event: any) => {
  return api.create(`${url.REST_VENUE}/setting/add-update`, event).then((response: any) => {
    (response.statusCode === 200) ? toast.success(response.message) : toast.error(response.message)
  });
};

// Update Venue
const updateVenue = (event: any) => {
  return api.updateWithMultipart(`${url.REST_VENUE}/update-venue`, event).then((response: any) => {
    (response.statusCode === 200) ? toast.success(response.message) : toast.error(response.message)
  });
};

const updateVenueReview = (event: any) => {
  return api.update(`${url.REST_VENUE}/venue/review/update`, event).then((response: any) => {
    (response.statusCode === 200) ? toast.success(response.message) : toast.error(response.message)
  });
};

// ------- Venue Areas Start-------

// get Venue Area
const getVenueArea = (event: any) => {
  const id = event.id;
  delete event.id
  return api.get(`${url.REST_AREA}/get-venue-area?venueId=${id}`);
};

// get Venue Area
const getVenueAreaDetail = (event: any) => {
  const venueId = event.venueId;
  const venueAreaId = event.venueAreaId;
  delete event.venueId
  delete event.venueAreaId
  return api.get(`${url.REST_AREA}/get-venue-area-detail?venueId=${venueId}&venueAreaId=${venueAreaId}`);
};

// delete venue Areas
const updateVenueAreaStatus = (event: any) => {
  return api.update(`${url.REST_AREA}/status/update`, event).then((response: any) => {
    (response.statusCode === 200) ? toast.success(response.message) : toast.error(response.message)
  });
};

// add venue Areas
const addNewVenueArea = (event: any) => {
  return api.createWithMultipartVenueArea(`${url.REST_AREA}/add-venue-area`, event).then((response: any) => {
    (response.statusCode === 200) ? toast.success(response.message) : toast.error(response.message)
  });
};

// Update Venue Areas
const updateVenueArea = (event: any) => {
  return api.updateWithMultipartVenueArea(`${url.REST_AREA}/update-venue-area`, event).then((response: any) => {
    (response.statusCode === 200) ? toast.success(response.message) : toast.error(response.message)
  });
};

// ------ Venue Areas End ------------

// get assets
const getAssetsList = (event: any) => {
  const venueId = (event.venueId) ? event.venueId : '';
  const venueAreasId = (event.venueAreasId) ? event.venueAreasId : '';
  const combinationType = (event.combinationType) ? event.combinationType : '';
  return api.get(`${url.REST_ASSET}?&venueId=${venueId}&venueAreasId=${venueAreasId}&combinationType=${combinationType}`);
};

// get assets
const getAssetsListWithTypes = (event: any) => {
  const venueId = (event.venueId) ? event.venueId : '';
  const venueAreasId = (event.venueAreasId) ? event.venueAreasId : '';
  const combinationType = (event.combinationType) ? event.combinationType : '';
  return api.get(`${url.REST_ASSET}/withTypes?&venueId=${venueId}&venueAreasId=${venueAreasId}&combinationType=${combinationType}`);
};

// add new asset
const addNewAsset = (event: any) => {
  return api.createWithMultipartAsset(url.REST_ASSET, event).then((response: any) => {
    (response.statusCode === 200) ? toast.success(response.message) : toast.error(response.message)
  });
};

// udapte Asset
const updateAsset = (event: any) => {
  const id = event.id;
  delete event.id
  return api.updateWithMultipartAsset(`${url.REST_ASSET}/${id}`, event).then((response: any) => {
    (response.statusCode === 200) ? toast.success(response.message) : toast.error(response.message)
  });
};

// delete asset
const updateAssetStatus = (event: any) => {
  return api.update(`${url.REST_ASSET}/status/update`, event).then((response: any) => {
    (response.statusCode === 200) ? toast.success(response.message) : toast.error(response.message)
  });
};


// get item list
const getItemList = (event: any) => {
  const catId = (event.catId) ? event.catId : '';
  const venueId = (event.venueId) ? event.venueId : '';
  const keyward = (event.keyward) ? event.keyward : '';
  const pageNumber = (event.pageNumber) ? event.pageNumber : '';
  return api.get(`${url.REST_MENU_ITEM}/get-menu?pageNo=${pageNumber}&categoryId=${catId}&keyward=${keyward}&venueId=${venueId}&size=10`);
};

// get item list
const getMenuList = (event: any) => {
  const catId = (event.catId) ? event.catId : '';
  const keyward = (event.keyward) ? event.keyward : '';
  const venueId = (event.venueId) ? event.venueId : '';
  return api.get(`${url.REST_MENU_ITEM}/get-menu-to-filter?pageNo=1&categoryId=${catId}&keyward=${keyward}&venueId=${venueId}&size=100`);
};

// add new item
const addNewItem = (event: any) => {
  return api.createWithMultipartItem(`${url.REST_MENU_ITEM}/add-menu`, event);
};

// add new item
const uploadItem = (event: any) => {
  return api.createWithMultipartUploadItem(`${url.REST_MENU_ITEM}/upload-menu`, event).then((response: any) => {
    (response.statusCode === 200) ? toast.success(response.message) : toast.error(response.message)
  });
};


// udapte item
const updateItem = (event: any) => {
  const id = event.id;
  delete event.id
  return api.updateWithMultipartItem(`${url.REST_MENU_ITEM}/update-menu/${id}`, event);
};

// udapte item
const updateMenuItem = (event: any) => {
  return api.put(`${url.REST_MENU_ITEM}/update/item/info`, event).then((response: any) => {
    // (response.statusCode === 200) ? toast.success(response.message) : toast.error(response.message)
    return response;
  });
};

// Update Menu Item Status
const updateOrderStatus = (orderId: string, status: string) => {
  const data = {
    status: status,
    orderId: orderId
  }
  return api.put(`${url.UPDATE_ORDER}`, data).then((response: any) => {
    (response.statusCode === 200) ? toast.success(response.message) : toast.error(response.message)
  });
}

// Update Menu Item Status
const updateMenuStatus = (orderId: string, status: string) => {
  const data = {
    status: status,
    orderId: orderId
  }
  return api.put(`${url.UPDATE_ORDER}`, data).then((response: any) => {
    (response.statusCode === 200) ? toast.success(response.message) : toast.error(response.message)
  });
}
const updateOrderItemStatus = (orderId: string, eventMenuId: string, status: string) => {
  const data = {
    status: status,
    orderId: orderId,
    menuId: eventMenuId
  }
  api.put(`${url.UPDATE_ORDER_ITEM}`, data).then((response: any) => {
    (response.statusCode === 200) ? toast.success(response.message) : toast.error(response.message)
  });

  const timer = setTimeout(() => {
    window.location.reload();
  }, 2000);
  return () => clearTimeout(timer);
}
// Update Menu Order Item Status
const updateSetOrderItemStatusData = (event: any) => {
  const data = {
    status: event.status,
    orderId: event.orderId,
    menuId: event.eventMenuId
  }
  return api.put(`${url.UPDATE_ORDER_ITEM}`, data).then((response: any) => {
    (response.statusCode === 200) ? toast.success(response.message) : toast.error(response.message)
  });
}

// delete Item
const updateItemStatus = (event: any) => {
  return api.update(`${url.REST_MENU_ITEM}/status/update`, event).then((response: any) => {
    (response.statusCode === 200) ? toast.success(response.message) : toast.error(response.message)
  });
};

//Order List
const getOrderList = (event: any) => {
  const assetId = (event.assetId) ? event.assetId : "";
  return api.get(`${url.REST_ORDER}?assetId=${assetId}`);
};


//Order List
const getTodayOrderList = () => {
  return api.get(`${url.REST_ORDER}/today`);
};

//Order List
const getAllOrderList = (event: any) => {
  const venueId = (event.venueId) ? event.venueId : "";
  const assetId = (event.assetId) ? event.assetId : "";
  const kdsId = (event.kdsId) ? event.kdsId : "";
  const venueAreasId = (event.venueAreasId) ? event.venueAreasId : "";
  const status = (event.status) ? event.status : "";
  const keyward = (event.keyward) ? event.keyward : "";
  return api.get(`${url.REST_ORDER}/orders/all?kdsId=${kdsId}&assetId=${assetId}&keyward=${keyward}&venueId=${venueId}&venueAreasId=${venueAreasId}&status=${status}`);
};

//Order List
const getDashboardReport = () => {
  return api.get(`${url.REST_ORDER}/get-reports/report`);
};

// -------- Location : Start -------
// get Countries
const getCountryList = () => {
  return api.get(`${url.REST_LOCATION}/countries`);
};

const getStateList = (event: any) => {
  const countryCode = event.countryCode;
  return api.get(`${url.REST_LOCATION}/states?countryCode=${countryCode}`);
};

const getCityList = (event: any) => {
  const countryCode = event.countryCode;
  const stateCode = event.stateCode;
  return api.get(`${url.REST_LOCATION}/cities?countryCode=${countryCode}&stateCode=${stateCode}`);
};

// -------- Location : End -------


// ------- Hardware Location : Start-------
const getHardwareLocation = (event: any) => {
  const venueId = (event.venueId) ? event.venueId : "";
  const venueAreasId = (event.venueAreasId) ? event.venueAreasId : "";
  return api.get(`${url.REST_HARDWARE_LOCATION}/get-venue-hardware-location?venueId=${venueId}&venueAreasId=${venueAreasId}`);
};
const getHardwareLocationDetail = (event: any) => {
  const id = event.id;
  delete event.id
  return api.get(`${url.REST_HARDWARE_LOCATION}/get-venue-hardware-location-detail?hardwareLocationId=${id}`);
};

const updateHardwareLocationStatus = (event: any) => {
  const id = event.id;
  delete event.id;
  return api.delete(`${url.REST_HARDWARE_LOCATION}/delete-hardware-location/${id}`).then((response: any) => {
    (response.statusCode === 200) ? toast.success(response.message) : toast.error(response.message)
  });
};

const addNewHardwareLocation = (event: any) => {
  return api.create(`${url.REST_HARDWARE_LOCATION}/add-venue-hardware-location`, event).then((response: any) => {
    (response.statusCode === 200) ? toast.success(response.message) : toast.error(response.message)
  });
};

const updateHardwareLocation = (event: any) => {
  return api.put(`${url.REST_HARDWARE_LOCATION}/update-venue-hardware-location`, event).then((response: any) => {
    (response.statusCode === 200) ? toast.success(response.message) : toast.error(response.message)
  });
};
// ------- Hardware Location : End-------


// ------- Hardware Start ---------

// get Venue Hardware
const getVenueHardware = (event: any) => {
  const id = (event.id) ? event.id : "";
  const venueAreasId = (event.id) ? event.id : "";
  delete event.id
  return api.get(`${url.REST_HARDWARE}/get-venue-hardware?venueAreasId=${venueAreasId}`);
};

const getVenueKds = (event: any) => {
  const venueId = (event.venueId) ? event.venueId : "";
  const venueAreasId = (event.venueAreasId) ? event.venueAreasId : "";
  return api.get(`${url.REST_HARDWARE}/kds/get?venueAreasId=${venueAreasId}&venueId=${venueId}`);
};


// add venue Hardware
const addVenueHardware = (event: any) => {
  return api.create(`${url.REST_HARDWARE}/add-venue-hardware`, event).then((response: any) => {
    (response.statusCode === 200) ? toast.success(response.message) : toast.error(response.message)
  });
};

// get venue Hardware detail
const getVenueHardwareDetail = (event: any) => {
  // const venueHardwareId = "654dfa685785b60520a1766e";
  const venueHardwareId = event.id
  delete event.venueHardwareId
  return api.get(`${url.REST_HARDWARE}/get-venue-hardware-location-detail?hardwareLocationId=${venueHardwareId}`);
};

// update venue hardware location
const updateVenueHardwareLocation = (event: any) => {
  return api.update(`${url.REST_HARDWARE}/update-venue-hardware`, event).then((response: any) => {
    (response.statusCode === 200) ? toast.success(response.message) : toast.error(response.message)
  });
};

// delete venue hardware location
const deleteVenueHardwareLocation = (event: any) => {
  const id = event.id;
  delete event.id
  return api.delete(`${url.REST_HARDWARE}/delete-hardware/${id}`, event).then((response: any) => {
    (response.statusCode === 200) ? toast.success(response.message) : toast.error(response.message)
  });
}
// ------- Hardware End ---------


// ------- Menu Area : Start -------

// get Menue Area
const getMenuArea = (event: any) => {
  const categoryId = event.categoryId ? event.categoryId : '';
  const venueId = event.venueId ? event.venueId : ''
  const venueAreasId = event.venueAreasId ? event.venueAreasId : ''
  const keyward = (event.keyward) ? event.keyward : '';
  return api.get(`${url.REST_MENU_AREA}/get-menu-area?categoryId=${categoryId}&venueId=${venueId}&keyward=${keyward}&venueAreasId=${venueAreasId}`);
};

// Delete Menu Area
const deleteMenuArea = (event: any) => {
  const menuAreaId = event.menuAreaId;
  return api.delete(`${url.REST_MENU_AREA}/delete-area-menu/${menuAreaId}`)
}

// add Menu Areas
const addNewMenuArea = (event: any) => {
  return api.create(`${url.REST_MENU_AREA}/add-menu-area`, event).then((response: any) => {
    (response.statusCode === 200) ? toast.success(response.message) : toast.error(response.message)
  });
};

// delete Menu Areas
const updateMenuArea = (event: any) => {
  const menuAreaId = event.menuAreaId;
  delete event.menuAreaId
  return api.update(`${url.REST_MENU_AREA}/update-menu/${menuAreaId}`, event).then((response: any) => {
    (response.statusCode === 200) ? toast.success(response.message) : toast.error(response.message)
  });
};

// get Menu Area Detail : Not Completed
const getMenuAreaDetail = (event: any) => {
  // require correct api
};

// ------- Menu Area : End -------


/*********** staff ******/

// get Venues
const getStaffList = () => {
  return api.get(`${url.REST_STAFF}/get/staff`);
};

// get Venues
const getModuleList = () => {
  return api.get(`${url.REST_STAFF}/get/module`);
};

// delete venue
const updateStaff = (event: any) => {
  return api.updateWithMultipartStaff(`${url.REST_STAFF}/update/staff`, event).then((response: any) => {
    (response.statusCode === 200) ? toast.success(response.message) : toast.error(response.message)
  });
};

// add venue
const addNewStaff = (event: any) => {
  return api.createWithMultipartStaff(`${url.REST_STAFF}/add/staff`, event);
};

// Update Venue
const updateStaffPermission = (event: any) => {
  return api.update(`${url.REST_STAFF}/update/permission`, event).then((response: any) => {
    (response.statusCode === 200) ? toast.success(response.message) : toast.error(response.message)
  });
};

// delete Item
const updateStaffStatus = (event: any) => {
  return api.update(`${url.REST_STAFF}/status/update`, event).then((response: any) => {
    (response.statusCode === 200) ? toast.success(response.message) : toast.error(response.message)
  });
};

//------------------------------------



export {
  getLoggedInUser,
  isUserAuthenticated,
  postJwtRegister,
  // working
  postJwtLogin,

  // Category API
  getMenuCategories,
  updateCategoryStatus,
  addNewCategory,
  updateCategory,

  //staff
  getStaffList,
  updateStaff,
  addNewStaff,
  updateStaffPermission,
  updateStaffStatus,
  getModuleList,

  // Venue API
  getVenueList,
  updateVenueStatus,
  addNewVenue,
  updateVenue,

  // Venue Area API
  getVenueArea,
  getVenueAreaDetail,
  updateVenueAreaStatus,
  addNewVenueArea,
  updateVenueArea,

  // Asset API
  getAssetsList,
  addNewAsset,
  updateAsset,
  updateAssetStatus,

  // Items API
  getItemList,
  addNewItem,
  updateItem,
  updateItemStatus,

  // Order API
  getOrderList,
  getTodayOrderList,
  getDashboardReport,
  updateMenuStatus,
  updateOrderItemStatus,

  // Location Functions
  getCountryList,
  getStateList,
  getCityList,

  // Hardware
  getVenueHardware,
  addVenueHardware,
  getVenueHardwareDetail,

  // Venue Hardware Location
  deleteVenueHardwareLocation,
  updateVenueHardwareLocation,
  updateHardwareLocationStatus,
  updateHardwareLocation,
  getHardwareLocation,
  getHardwareLocationDetail,
  addNewHardwareLocation,

  // Menu Area API
  getMenuArea,
  getMenuAreaDetail,
  addNewMenuArea,
  updateMenuArea,
  deleteMenuArea,
  updateOrderStatus,

  getCategoriesType,
  addNewCategoryType,
  updateCategoryType,
  updateCategoryTypeStatus,
  getSubCategories,
  addNewSubCategory,
  updateSubCategory,
  updateSubCategoryStatus,

  getItemSize,
  addItemSize,
  updateItemSizeStatus,
  updateItemSize,

  getServingOrder,
  addServingOrder,
  updateServingOrderStatus,
  updateServingOrder,
  getMenuList,
  getAllOrderList,
  uploadItem,
  getAssetsListWithTypes,
  updateSetOrderItemStatusData,
  findDomain,
  getNotification,
  deleteNotification,
  addVenueSettingConfigure,
  getVenueKds,
  updateVenueReview,
  getVenues,
  getAddOnsList,
  addNewAddOns,
  updateAddOns,
  deleteAddOns,
  updateMenuItem
};
