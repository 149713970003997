import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  getVenueArea as getVenueAreaApi,
  getVenueAreaDetail as getVenueAreaDetailApi,
  updateVenueAreaStatus as updateVenueAreaStatusApi,
  addNewVenueArea as addNewVenueAreaApi,
  updateVenueArea as updateVenueAreaApi,
} from "../../helpers/backend_helper";

export const getVenueArea = createAsyncThunk(
  "venues/getVenueArea",
  async (event: any) => {
    try {
      const response = getVenueAreaApi(event);
      return response;
    } catch (error) {
      return error;
    }
  }
);
export const getVenueAreaDetail = createAsyncThunk(
  "venues/getVenueAreaDetail",
  async (event: any) => {
    try {
      const response = getVenueAreaDetailApi(event);
      return response;
    } catch (error) {
      return error;
    }
  }
);
export const updateVenueAreaStatus = createAsyncThunk(
  "venues/updateVenueAreaStatus",
  async (event: any) => {
    try {
      const response = updateVenueAreaStatusApi(event);
      const modifiedEvent = await response;
      return modifiedEvent;
    } catch (error) {
      return error;
    }
  }
);
export const addNewVenueArea = createAsyncThunk(
  "venues/addNewVenueArea",
  async (event: any) => {
    try {
      const response = addNewVenueAreaApi(event);
      return response;
    } catch (error) {
      return error;
    }
  }
);
export const updateVenueArea = createAsyncThunk(
  "venues/updateVenueArea",
  async (event: any) => {
    try {
      const response = updateVenueAreaApi(event);
      const modifiedEvent = await response;
      return modifiedEvent;
    } catch (error) {
      return error;
    }
  }
);

export const resetVenueAreaApiResponseFlag = createAsyncThunk(
  "areas/resetVenueAreaApiResponseFlag",
  async () => {
    try {
      return true;
    } catch (error) {
      return error;
    }
  }
);