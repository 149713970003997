import React, { useEffect } from "react";
import MetaTags from "react-meta-tags";

//import Breadcrumbs
import Breadcrumbs from "../../components/Common/Breadcrumb";

import { Col, Container, Row, Card, CardBody } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";

import {
    getDashboardReport as onGetDashboardReport
} from "../../slices/thunks";


const Sales = () => {
    const dispatch = useDispatch();

    const { dashboardReport } = useSelector((state: any) => ({
        dashboardReport: state.orders.dashboardReport,
    }));

    useEffect(() => {
        dispatch(onGetDashboardReport());
    }, [dispatch]);

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Sales |  Restaurant Booking</title>
                </MetaTags>

                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <Breadcrumbs title="Dashboard" breadcrumbItem="Sales" />
                    <Row>
                        <Col xl={4} sm={6}>
                            <Card>
                                <CardBody>
                                    <div className="d-flex align-items-center ">
                                        <div className="flex-shrink-0 me-3">
                                            <div className="avatar-sm">
                                                <div className="avatar-title bg-soft-primary text-primary rounded-circle font-size-18">
                                                    <i className="uil uil-list-ul"></i>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="flex-grow-1 overflow-hidden">
                                            <h6 className="font-size-xs text-uppercase">Today&apos;s Orders </h6>
                                            <h5 className="font-size-16 mb-0">{dashboardReport.todayOrders || 0}</h5>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col xl={4} sm={6}>
                            <Card>
                                <CardBody>
                                    <div className="d-flex align-items-center">
                                        <div className="flex-shrink-0 me-3">
                                            <div className="avatar-sm">
                                                <div className="avatar-title bg-soft-primary text-primary rounded-circle font-size-18">
                                                    <i className="uil uil-check-circle"></i>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="flex-grow-1 overflow-hidden">
                                            <h6 className="font-size-xs text-uppercase">Total Orders</h6>
                                            <h5 className="font-size-16 mb-0">{dashboardReport.totalOrders || 0}</h5>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col xl={4} sm={6}>
                            <Card>
                                <CardBody>
                                    <div className="d-flex align-items-center">
                                        <div className="flex-shrink-0 me-3">
                                            <div className="avatar-sm">
                                                <div className="avatar-title bg-soft-primary text-primary rounded-circle font-size-18">
                                                    <i className="uil uil-users-alt"></i>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="flex-grow-1 overflow-hidden">
                                            <h6 className="font-size-xs text-uppercase">Total Income </h6>
                                            <h5 className="font-size-16 mb-0">&#8364; {dashboardReport.totalIncome || 0}</h5>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
}

export default Sales;