import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { createAsyncThunk } from "@reduxjs/toolkit";
import {
    apiError,
    loginSuccess
} from "./reducer";
import {    
    postJwtLogin,
    findDomain
} from "../../../helpers/backend_helper";

export const loginUser = (user: any, history: any) => async (dispatch: any) => {
    try {
        let response;
        response = postJwtLogin({
            email: user.email,
            password: user.password
        });
        const data: any = await response;
        if(data.statusCode == 400){
           toast.error(data.error)
        }else{
            if (data != undefined) {
                localStorage.setItem("authUser", JSON.stringify(data));
                dispatch(loginSuccess(data));
                history.push("/home");
            }
        }
    } catch (error) {
        dispatch(apiError(error));
    }
};7

export const findVenueDomain = (domain: string) => async (dispatch: any) => {
    try {
        let response;
        response = findDomain({
            domain: domain
        });
        const data: any = await response;
        return data;
    } catch (error) {
        dispatch(apiError(error));
    }
};

export const findVenueDomains = createAsyncThunk(
    "venuelogin",
    async (event: any) => {
      try {
        const response = findDomain(event);
        return response;
      } catch (error) {
        return error;
      }
    }
  );

export const logoutUser = (history: any) => async (dispatch: any) => {
    try {
        localStorage.removeItem("authUser");
        history.push("/login");
    } catch (error) {
        dispatch(apiError(error));
    }
};