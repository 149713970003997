import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getStaffList as getStaffListApi,
  updateStaffStatus as updateStaffStatusApi,
  addNewStaff as addNewStaffApi,
  updateStaff as updateStaffApi,
  updateStaffPermission as updateStaffPermissionApi,
  getModuleList as getModuleListApi,
} from "../../helpers/backend_helper";


export const addNewStaff = createAsyncThunk(
  "staff/addNewStaff",
  async (event: any) => {
    try {
      const response = addNewStaffApi(event);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const updateStaffPermissions = createAsyncThunk(
  "staff/updateStaffPermissions",
  async (event: any) => {
    try {
      const response = updateStaffPermissionApi(event);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getStaffList = createAsyncThunk(
  "staff/getStaffList",
  async () => {
    try {
      const response = getStaffListApi();
      const modifiedEvent = await response;
      return modifiedEvent;
    } catch (error) {
      return error;
    }
  }
);

export const getModuleList = createAsyncThunk(
  "staff/getModuleList",
  async () => {
    try {
      const response = getModuleListApi();
      const modifiedEvent = await response;
      return modifiedEvent;
    } catch (error) {
      return error;
    }
  }
);

export const updateStaffStatus = createAsyncThunk(
  "staff/updateStaffStatus",
  async (event: any) => {
    try {
      const response = updateStaffStatusApi(event);
      const modifiedEvent = await response;
      return modifiedEvent;
    } catch (error) {
      return error;
    }
  }
);

export const resetStaffApiResponseFlag = createAsyncThunk(
  "staff/resetStaffApiResponseFlag",
  async () => {
    try {
      return true;
    } catch (error) {
      return error;
    }
  }
);

export const updateStaff = createAsyncThunk(
  "staff/updateStaff",
  async (event: any) => {
    try {
      const response = updateStaffApi(event);
      const modifiedEvent = await response;
      return modifiedEvent;
    } catch (error) {
      return error;
    }
  }
);


export const updateStaffPermission = createAsyncThunk(
  "staff/updateStaffPermission",
  async (event: any) => {
    try {
      const response = updateStaffPermissionApi(event);
      const modifiedEvent = await response;
      return modifiedEvent;
    } catch (error) {
      return error;
    }
  }
);