import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import Icon from "@ailibs/feather-react-ts";
import { Card, CardBody, Col, Container, Row, Button } from "reactstrap";

import moment from 'moment';
import OrderDetail from "../Orders/order-details";
import { updateMenuStatus, updateOrderItemStatus, updateOrderStatus } from "src/helpers/backend_helper";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

interface CardProjectProps {
  todayOrderList: any;
  orderType: any;
}

const CardOrderDetail = ({ todayOrderList, orderType }: CardProjectProps) => {


  return (
    <React.Fragment>
      {
        todayOrderList
          .filter(
            ({ status }: any) => orderType === status ||
              orderType === "all"
          )
          .map(({
            assetInfo,
            status,
            orderDate,
            order_details,
            totalIteam,
            subTotalAmount,
            discount,
            tax,
            totalAmount,
            orderNumber,
            _id
          }
            : any, key: any) => (

            <Container fluid key={key}>
              <Row>
                <Col lg={12}>
                  <Card>
                    <CardBody>
                      <div className="invoice-title">
                        <h4 className="float-end font-size-15">
                          <div className="badge  font-size-12">
                            <Button color=' bg-all light ' className="button-info"
                              onClick={() => updateOrderItemStatus('aa', 'aa', 'in progress')}><Icon name='phone-outgoing' className="my-awesome-class" />Call Waiter for Pickup</Button>
                          </div>
                          <div className="badge  font-size-12">
                            <Button color=' bg-all light ' className="button-info"
                              onClick={() => updateOrderStatus(_id, 'Done')}><Icon name='shopping-cart' className="my-awesome-class" />Click Here Complete Order?</Button>
                          </div>
                          {/* <div className="badge bg-success font-size-12">Completed Order</div>
                          <div className="badge bg-danger font-size-12 mx-1">Cancel Order</div> */}
                          <span>
                            {status === 'Active' ?
                              <div className="badge bg-warning font-size-12">{status}</div>
                              : <div className="badge bg-success font-size-12">{status}</div>}
                          </span>

                        </h4>
                        <div className="text-muted">
                          <h4 className="font-size-15">Order No : #{orderNumber} </h4>
                          <h4 className="font-size-15">Asset Info : {assetInfo.assetName}({assetInfo.assetType}) </h4>
                          <h4 className="font-size-15">Order Date&Time : {moment(orderDate).format('DD MMM Y hh:mm A')} </h4>
                        </div>
                      </div>
                      <ToastContainer
                        position="top-right"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                      />
                      <hr className="my-4" />

                      <Row>
                        <Col sm={6}>
                          <div>
                            {/* <h5 className="font-size-15 mb-1">Table No: <span className="text-color">{assetInfo.assetName}({assetInfo.assetType})</span></h5> */}
                          </div>
                        </Col>

                        {/* <Col sm={6}>
                          <div className="text-muted text-sm-end">

                            <div className="mt-4">
                              <h5 className="font-size-15 mb-1">Order Date&Time: </h5>
                              <p>{moment(orderDate).format('DD MMM Y hh:mm A')}</p>
                            </div>
                          </div>
                        </Col> */}

                      </Row>


                      <div className="py-2">
                        <h5 className="font-size-15">Order Summary ({totalIteam} Items)</h5>

                        <div className="table-responsive">
                          <table className="table align-middle table-nowrap table-centered mb-0">
                            <thead>
                              <tr>
                                <th style={{ width: "70px" }}>No.</th>
                                <th>Item</th>
                                <th>Vat</th>
                                <th>Price</th>
                                <th>Quantity</th>
                                <th className="text-end" style={{ width: "120px" }}>Total</th>
                                <th>Cooking Action</th>
                                <th>Action Time</th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                order_details.map(({ _id, reservationId, menuId, description, menuInfo, vatInfo, quantity, price, totalPrice, status, updateDate }: any, key: any) => (
                                  <tr key={key}>
                                    <th scope="row">{key}</th>
                                    <td>
                                      <div>
                                        <h5 className="text-truncate font-size-14 mb-1">{menuInfo.name.en || "NA"}</h5>
                                        <p className="text-muted mb-0">{menuInfo.size ? `Size:- ${menuInfo.size.name}` : ""}</p>
                                        <p className="text-muted mb-0" style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>{description ? `EX:- ${description}` : ""}</p>
                                      </div>
                                    </td>
                                    <th scope="row">{vatInfo.vat}%</th>
                                    <td>&#8364; {price}</td>
                                    <td>{quantity}</td>
                                    <td className="text-end">&#8364; {(Number((Math.floor(totalPrice * 100) / 100))).toFixed(2)}</td>
                                    <td>


                                      {status === 'pending'
                                        ? <Button color='badge bg-white light' className="p-0"
                                          onClick={() => updateOrderItemStatus(reservationId, menuId, 'in progress')}>{status}</Button>
                                        : <>
                                          {status === 'in progress'
                                            ? <Button color='badge bg-blue primary' className="p-0"
                                              onClick={() => updateOrderItemStatus(reservationId, menuId, 'ready for pickup')}><Icon name='aperture' className="my-awesome-class" />{status}</Button>
                                            : <Button color='badge bg-green primary' className="p-0"><Icon name='check' className="my-awesome-class" /> {status} </Button>
                                          }

                                        </>
                                      }

                                    </td>
                                    <td>{updateDate !== null ? moment(updateDate).format('DD MMM Y hh:mm A') : "Waiting"}</td>
                                  </tr>

                                ))}
                              <tr>
                                <th scope="row" colSpan={4} className="text-end">Sub Total</th>
                                <td className="text-end">&#8364; {subTotalAmount}</td>
                              </tr>

                              <tr>
                                <th scope="row" colSpan={4} className="border-0 text-end">
                                  Discount : </th>
                                <td className="border-0 text-end">-  &#8364; {discount}</td>
                              </tr>
                              <tr>
                                <th scope="row" colSpan={4} className="border-0 text-end">
                                  Vat</th>
                                <td className="border-0 text-end">&#8364; {tax}</td>
                              </tr>

                              <tr>
                                <th scope="row" colSpan={4} className="border-0 text-end">Total</th>
                                <td className="border-0 text-end"><h4 className="m-0 fw-semibold">&#8364; {totalAmount}</h4></td>
                              </tr>

                            </tbody>
                          </table>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          ))
      }
    </React.Fragment>
  )
}

export default CardOrderDetail