import { createSlice } from "@reduxjs/toolkit";

import {
  getOrderList,
  getTodayOrderList,
  getDashboardReport,
  getAllOrderList,
  updateSetOrderStatus,
  updateSetOrderItemStatus,
  getVenuekds
} from "./thunk";

interface OrdersStateType {
  orderList: Array<any>,
  venueKdsList: Array<any>,
  orderAllList: Array<any>,
  todayOrderList: Array<any>,
  error?: string | object | null | undefined | unknown,
  dashboardReport: object,
  orderStatus: object,
  stausCode: number,
}
export const initialState: OrdersStateType = {
  orderList: [],
  orderAllList: [],
  todayOrderList: [],
  venueKdsList:[],
  error: {},
  dashboardReport: {},
  orderStatus: {},
  stausCode:0
};

const OrdersSlice = createSlice({
  name: "orders",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getOrderList.fulfilled, (state: any, action: any) => {
      state.orderList = action.payload.data;
    })
    builder.addCase(getOrderList.rejected, (state, action) => {
      state.error = action.payload || null;
    })

    builder.addCase(getVenuekds.fulfilled, (state: any, action: any) => {
      state.venueKdsList = action.payload.data;
    })
    builder.addCase(getVenuekds.rejected, (state, action) => {
      state.error = action.payload || null;
    })

    // Update menu Area
    builder.addCase(updateSetOrderStatus.fulfilled, (state: any, action: any) => {
      state.orderStatus = action.payload;
    })
    builder.addCase(updateSetOrderStatus.rejected, (state, action) => {
      state.error = action.payload || null;
    })

    builder.addCase(updateSetOrderItemStatus.fulfilled, (state: any, action: any) => {
      state.orderStatus = action.payload;
    })
    builder.addCase(updateSetOrderItemStatus.rejected, (state, action) => {
      state.error = action.payload || null;
    })

    builder.addCase(getAllOrderList.fulfilled, (state: any, action: any) => {
      state.orderAllList = action.payload.data;
      state.stausCode = action.payload.statusCode;
    })
    builder.addCase(getAllOrderList.rejected, (state, action) => {
      state.error = action.payload || null;
    })

    builder.addCase(getTodayOrderList.fulfilled, (state: any, action: any) => {
      state.todayOrderList = action.payload.data;
    })
    builder.addCase(getTodayOrderList.rejected, (state, action) => {
      state.error = action.payload || null;
    })
    builder.addCase(getDashboardReport.fulfilled, (state: any, action: any) => {
      state.dashboardReport = action.payload.data;
    })
    builder.addCase(getDashboardReport.rejected, (state, action) => {
      state.error = action.payload || null;
    })
  }
});

export default OrdersSlice.reducer;