import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  getHardwareLocation as getHardwareLocationApi,
  getHardwareLocationDetail as getHardwareLocationDetailApi,
  updateHardwareLocationStatus as updateHardwareLocationStatusApi,
  addNewHardwareLocation as addNewHardwareLocationApi,
  updateHardwareLocation as updateHardwareLocationApi,
} from "../../helpers/backend_helper";

export const getHardwareLocation = createAsyncThunk(
  "HardwareLocation/getHardwareLocation",
  async (event: any) => {
    try {
      const response = getHardwareLocationApi(event);
      return response;
    } catch (error) {
      return error;
    }
  }
);
export const getHardwareLocationDetail = createAsyncThunk(
  "HardwareLocation/getHardwareLocationDetail",
  async (event: any) => {
    try {
      const response = getHardwareLocationDetailApi(event);
      return response;
    } catch (error) {
      return error;
    }
  }
);
export const updateHardwareLocationStatus = createAsyncThunk(
  "HardwareLocation/updateHardwareLocationStatus",
  async (event: any) => {
    try {
      const response = updateHardwareLocationStatusApi(event);
      const modifiedEvent = await response;
      return modifiedEvent;
    } catch (error) {
      return error;
    }
  }
);
export const addNewHardwareLocation = createAsyncThunk(
  "HardwareLocation/addNewHardwareLocation",
  async (event: any) => {
    try {
      const response = addNewHardwareLocationApi(event);
      return response;
    } catch (error) {
      return error;
    }
  }
);
export const updateHardwareLocation = createAsyncThunk(
  "HardwareLocation/updateHardwareLocation",
  async (event: any) => {
    try {
      const response = updateHardwareLocationApi(event);
      const modifiedEvent = await response;
      return modifiedEvent;
    } catch (error) {
      return error;
    }
  }
);

export const resetHardwareLocationApiResponseFlag = createAsyncThunk(
  "HardwareLocation/resetHardwareLocationApiResponseFlag",
  async () => {
    try {
      return true;
    } catch (error) {
      return error;
    }
  }
);