
import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  getItemSize as getItemSizeApi,
  updateItemSizeStatus as updateItemSizeStatusApi,
  addItemSize as addItemSizeApi,
  updateItemSize as updateItemSizeApi
} from "../../helpers/backend_helper";

export const getItemSize = createAsyncThunk(
  "itemSize/getItemSize",
  async () => {
    try {
      const response = getItemSizeApi();
      return response;
    } catch (error) {
      return error;
    }
  }
);


export const addItemSize = createAsyncThunk(
  "itemSize/addItemSize",
  async (event: any) => {
    try {
      const response = addItemSizeApi(event);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const updateItemSize = createAsyncThunk(
  "itemSize/updateItemSize",
  async (event: any) => {
    try {
      const response = updateItemSizeApi(event);
      const modifiedEvent = await response;
      return modifiedEvent;
    } catch (error) {
      return error;
    }
  }
);


export const updateItemSizeStatus = createAsyncThunk(
  "itemSize/updateItemSizeStatus",
  async (event: any) => {
    try {
      const response = updateItemSizeStatusApi(event);
      const modifiedEvent = await response;
      return modifiedEvent;
    } catch (error) {
      return error;
    }
  }
);

export const resetApiItemSizeResponseFlag = createAsyncThunk(
  "itemSize/resetApiItemSizeResponseFlag",
  async () => {
    try {
      return true;
    } catch (error) {
      return error;
    }
  }
);
