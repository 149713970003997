import { combineReducers } from 'redux'

import layoutReducer from "./Layout/reducer"

//login
import loginReducer from "./Auth/Login/reducer";

// Menu Category
import categoriesReducer from "./Categories/reducer";

// Menu Category
import subCategoriesReducer from "./SubCategories/reducer";

// Menu Category
import categoriesTypeReducer from "./CategoryType/reducer";

// Notification
import notificationReducer from "./Notifications/reducer";

// Venue Location
import venuesReducer from "./Venues/reducer";

// Staff
import staffReducer from "./Staff/reducer";

// Venue Area 
import areasReducer from "./Areas/reducer";

// Assets/Table
import assetsReducer from "./Assets/reducer";

// Menu Items
import itemsReducer from "./Items/reducer";

// Menu Items
import ordersReducer from "./Orders/reducer";

// Locations
import locationsReducer from "./Location/reducer";

// Hardware
import hardwareReducer from "./Hardware/reducer"

// Hardware Location 
import hardwareLocationReducer from "./HardwareLocation/reducer";

// Hardware Location 
import menuAreasReducer from "./MenuArea/reducer";

import itemSizeReduser from "./ItemSize/reducer";

import servingOrderReducer from "./ServingOrder/reducer";

const rootReducer = combineReducers({
    Layout: layoutReducer,
    
    // Working 
    login: loginReducer,
    categories: categoriesReducer,
    venues: venuesReducer,
    areas: areasReducer,
    assets: assetsReducer,
    items:itemsReducer,
    orders:ordersReducer,
    locations:locationsReducer,
    hardware: hardwareReducer,
    hardwareLocations : hardwareLocationReducer,
    menuAreas : menuAreasReducer,
    subCategories: subCategoriesReducer,
    categoriesType: categoriesTypeReducer,
    itemSize: itemSizeReduser,
    servingOrder: servingOrderReducer,
    staff: staffReducer,
    notification: notificationReducer
})

export default rootReducer