
import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  getCategoriesType as getCategoriesTypeApi,
  updateCategoryStatus as updateCategoryStatusApi,
  addNewCategoryType as addNewCategoryTypeApi,
  updateCategoryType as updateCategoryTypeApi,
  updateCategoryTypeStatus as updateCategoryTypeStatusApi
} from "../../helpers/backend_helper";

export const getCategoriesType = createAsyncThunk(
  "categoryType/getCategoriesType",
  async () => {
    try {
      const response = getCategoriesTypeApi();
      return response;
    } catch (error) {
      return error;
    }
  }
);


export const addNewCategoryType = createAsyncThunk(
  "categoryType/addNewCategoryType",
  async (event: any) => {
    try {
      const response = addNewCategoryTypeApi(event);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const updateCategoryType = createAsyncThunk(
  "categoryType/updateCategoryType",
  async (event: any) => {
    try {
      const response = updateCategoryTypeApi(event);
      const modifiedEvent = await response;
      return modifiedEvent;
    } catch (error) {
      return error;
    }
  }
);


export const updateCategoryTypeStatus = createAsyncThunk(
  "categoryType/updateCategoryTypeStatus",
  async (event: any) => {
    try {
      const response = updateCategoryTypeStatusApi(event);
      const modifiedEvent = await response;
      return modifiedEvent;
    } catch (error) {
      return error;
    }
  }
);

export const resetApiCategoryTypeResponseFlag = createAsyncThunk(
  "categoryType/resetApiCategoriesTypeResponseFlag",
  async () => {
    try {
      return true;
    } catch (error) {
      return error;
    }
  }
);
