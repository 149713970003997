import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  getItemList as getItemListApi,
  addNewItem as addNewItemApi,
  uploadItem as uploadItemApi,
  updateItemStatus as updateItemStatusApi,
  updateItem as updateItemApi,
  updateMenuItem as updateMenuItemApi
} from "../../helpers/backend_helper";


export const addNewItem = createAsyncThunk(
  "items/addNewItem",
  async (event: any) => {
    try {
      const response = addNewItemApi(event);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const uploadItem = createAsyncThunk(
  "items/uploadItem",
  async (event: any) => {
    try {
      const response = uploadItemApi(event);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getItemList = createAsyncThunk(
  "items/getItemList",
  async (event: any) => {
    try {
      const response = getItemListApi(event);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const updateItemStatus = createAsyncThunk(
  "items/updateItemStatus",
  async (event: any) => {
    try {
      const response = updateItemStatusApi(event);
      const modifiedEvent = await response;
      return modifiedEvent;
    } catch (error) {
      return error;
    }
  }
);

export const resetItemApiResponseFlag = createAsyncThunk(
  "items/resetItemApiResponseFlag",
  async () => {
    try {
      return true;
    } catch (error) {
      return error;
    }
  }
);

export const updateItem = createAsyncThunk(
  "items/updateItem",
  async (event: any) => {
    try {
      const response = updateItemApi(event);
      const modifiedEvent = await response;
      return modifiedEvent;
    } catch (error) {
      return error;
    }
  }
);

export const updateMenuItem = createAsyncThunk(
  "items/updateMenuItem",
  async (event: any) => {
    try {
      const response = updateMenuItemApi(event);
      const modifiedEvent = await response;
      return modifiedEvent;
    } catch (error) {
      return error;
    }
  }
);