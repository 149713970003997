import { createSlice } from "@reduxjs/toolkit";

import {
  getItemSize,
  addItemSize,
  updateItemSize,
  updateItemSizeStatus,
  resetApiItemSizeResponseFlag
} from "./thunk";

interface SubCategoriesStateType {
  itemSize: Array<any>,
  catMsgResponse: object,
  error?: string | object | null | undefined | unknown,
  itemSizeUpdate: any

}
export const initialState: SubCategoriesStateType = {
  itemSize: [],
  error: {},
  catMsgResponse: {},
  itemSizeUpdate: {}
};

const itemSizeSlice = createSlice({
  name: "categoriesType",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(addItemSize.fulfilled, (state: any, action: any) => {
      state.itemSize.push(action.payload.data)
      state.catMsgResponse = {
        message: action.payload ? action.payload.message : 'New Category Added Successfully',
        type: action.payload ? 'success' : 'error',
      };

    })
    builder.addCase(addItemSize.rejected, (state, action) => {
      state.error = action.payload || null;
    })
    builder.addCase(getItemSize.fulfilled, (state: any, action: any) => {
      state.itemSize = action.payload.data;
    })
    builder.addCase(getItemSize.rejected, (state, action) => {
      state.error = action.payload || null;
    })
    builder.addCase(updateItemSize.fulfilled, (state: any, action: any) => {
      state.itemSizeUpdate = action.payload;
      state.catMsgResponse = {
        message: action.payload ? action.payload.message : 'Category Updated Successfully',
        type: action.payload ? 'success' : 'error',
      };
    })
    builder.addCase(updateItemSize.rejected, (state, action) => {
      state.error = action.payload || null;
    })
    builder.addCase(resetApiItemSizeResponseFlag.fulfilled, (state, action) => {
      state.catMsgResponse = { message: "" };
    })

    builder.addCase(updateItemSizeStatus.fulfilled, (state: any, action: any) => {
      state.itemSizeUpdate = action.payload;
      state.itemMsgResponse = {
        message:action.payload ? action.payload.message : 'Category Status Updated Successfully',
        type: action.payload ? 'success' : 'error',
      };
    })
    builder.addCase(updateItemSizeStatus.rejected, (state, action) => {
      state.error = action.payload || null;
    })

  },
});

export default itemSizeSlice.reducer;