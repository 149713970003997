import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  getCountryList as getCountryListApi,
  getStateList as getStateListApi,
  getCityList as getCityListApi,
} from "../../helpers/backend_helper";

export const getCountryList = createAsyncThunk(
  "locations/getCountryList",
  async () => {
    try {
      const response = getCountryListApi();
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getStateList = createAsyncThunk(
  "locations/getStateList",
  async (event: any) => {
    try {
      const response = getStateListApi(event);
      return response;
    } catch (error) {
      return error;
    }
  }
);
export const getCityList = createAsyncThunk(
  "locations/getCityList",
  async (event: any) => {
    try {
      const response = getCityListApi(event);
      return response;
    } catch (error) {
      return error;
    }
  }
);