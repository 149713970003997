import React, { useEffect, useState, ChangeEvent } from "react";
import MetaTags from "react-meta-tags";
import { ToastContainer, toast } from 'react-toastify';
import { withRouter, Link } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  UncontrolledAlert,
  Table,
  ModalFooter
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import {
  addVenueHardware as onAddNewHardware,
  deleteVenueHardwareLocation as onDeleteHardwareLocation,
  getVenueHardwareDetail as onGetHardwareDetail,
  getVenueHardware as onGetHardware,
  resetHardwareApiResponseFlag as onResetApiResponseFlag,
  updateVenueHardwareLocation as onUpdateHardware,
  getHardwareLocation as onGetHardwareLocation,
  getVenueList as onGetVenueList,
  getVenueArea as onGetVenueArea,
  getMenuCategories as onGetgetCategories,
  getSubCategoriesType as OnGetSubCategoriesType,
} from "../../slices/thunks";
import BreadCrumbs from "../../components/Common/Breadcrumb";
import { isEmpty } from "lodash";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import DeleteModal from "../Common/DeleteModal";
// redux
import { useSelector, useDispatch } from "react-redux";
const Hardwares = () => {
  const dispatch = useDispatch();
  const { SearchBar } = Search;

  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [hardwareEvent, setHardwareEvent] = useState<any>({});
  const [hardwareLocationId, setHardwareLocationId] = useState<any>({});
  const [hardwareModel, setHardwareModel] = useState<boolean>(false);
  const [modal, setModal] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [otherhardwareType, setOtherhardwareType] = useState<boolean>(false);
  const [categoryEvent, setCategoryEvent] = useState<any>([]);
  const [categoryModal, setCategoryModal] = useState<boolean>(false);
  // const formData = new FormData();
  const handleShow = () => setModal(false);
  const handleShowCategory = () => setCategoryModal(false);
  const {
    hardwareList,
    hardwareMsgResponse,
    hardwareAddUpdate,
    hardwareTypeList,
    hardwareLocation,
    venueList,
    venueArea,
    categories,
    subCategories
  } = useSelector((state: any) => ({
    hardwareList: state.hardware.hardwareList,
    hardwareMsgResponse: state.hardware.hardwareMsgResponse,
    hardwareAddUpdate: state.hardware.hardwareAddUpdate,
    hardwareTypeList: state.locations.hardwareTypeList,
    hardwareLocation: state.hardwareLocations.hardwareLocation,
    venueList: state.venues.venueList,
    venueArea: state.areas.venueArea,
    categories: state.categories.categories,
    subCategories: state.subCategories.subCategories,
  }));

  // list of hardware types
  // KDS','POS','PRINTER','CASHIERPOS','RESERVATIONSTABLET','ROUTER','ACCESSPOINT','CUSTOMERDISPLAY','FISCAL'
  const hadwaretypeLists: string[] = [
    "KDS",
    "POS",
    "PRINTER",
    "CASHIERPOS",
    "RESERVATIONSTABLET",
    "ROUTER",
    "ACCESSPOINT",
    "CUSTOMERDISPLAY",
    "FISCAL",
  ];

  // Filter Hardware Location
  const filterHardware = (id: any) => {
    dispatch(onGetHardware({ id: id }));
  };

  // get hardware type
  const handlehardwareType = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedOption = event.target.options[event.target.selectedIndex];
    const eventValue = selectedOption.value;

    if (eventValue === "other") {
      setOtherhardwareType(true);
    } else {
      setOtherhardwareType(false);
    }
  };

  // Handle other input value change
  const handleOtherInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setHardwareEvent((prevData: any) => ({ ...prevData, [name]: value }));
  };

  const pageOptions = {
    sizePerPage: 10,
    totalSize: hardwareList.length, // replace later with size(users),
    custom: true,
  };


  const defaultSorted: any = [
    {
      dataField: "_id", // if dataField is not match to any column you defined, it will be ignored.
      order: "asc", // desc or asc
    },
  ];

  const filterArea = (venueId: string) => {
    dispatch(onGetVenueArea({ id: venueId }));
    dispatch(onGetgetCategories({venueId:venueId}));
  };

  const filterHardwareLocation = (id: string) => {
    dispatch(onGetHardwareLocation({ venueAreasId: id }));
  };

  // ----- 1. Get Hardware List -----------
  useEffect(() => {
    dispatch(onGetHardware({ id: '' }));
    dispatch(onGetVenueList())
    dispatch(onGetgetCategories({}));
  }, [dispatch, hardwareAddUpdate]);

  // ----- 2. Get Response Message -----

  useEffect(() => {
    const timer = setTimeout(() => {
      dispatch(onResetApiResponseFlag());
    }, 5000);
    return () => clearTimeout(timer);
  }, [hardwareMsgResponse]);

  const handleViewCategory = (categories: any) => {
    setCategoryEvent(categories)
    setCategoryModal(true)
};

  // ------- Use Effect : End -----


  const handleValidFormSubmit = (values: any) => {
    const name = { en: `${values.enName}`, gr: `${values.grName}` }
    values['name'] = JSON.stringify(name);
    delete values.enName
    delete values.grName
    const network = { ip: `${values.ip}`, port: `${values.port}`, gateway: `${values.gateway}`, subnet: `${values.subnet}` }
    values['network'] = JSON.stringify(network);
    delete values.ip
    delete values.port
    delete values.gateway
    delete values.subnet
    if (isEdit) {
      values['hardwareId'] = hardwareEvent._id
      dispatch(onUpdateHardware(values));
      setIsEdit(false)
    } else {
      dispatch(onAddNewHardware(values));
    }
    toggle();
  };
  const toggle = () => {
    setModal(!modal);
    if (isEdit) {
      setIsEdit(false)
      setHardwareEvent({})
    }
    if (!modal && !isEmpty(hardwareEvent) && !!isEdit) {
      setTimeout(() => {
        // console.log("Press user");
        setHardwareEvent({});
        setIsEdit(false);
      }, 500);
    }
  };

  const handleUserClicks = () => {
    toggle();
  };

  const handleEditHardwareClick = (event: any) => {
    setHardwareEvent({
      ...event, enName: event.name.en, grName: event.name.gr,
      ip: event.network.ip || '',
      port: event.network.port,
      gateway: event.network.gateway,
      subnet: event.network.subnet
    });
    dispatch(onGetVenueArea({ id: event.venueId }));
    setIsEdit(true);
    toggle();
  };

  const handleDeleteClick = (event: any) => {
    setHardwareEvent({
      id: event._id,
      status: event.status == 1 ? 0 : 1
    });
    setDeleteModal(true);
  };

  /**
  * On delete event
  */
  const handleDeleteHardware = () => {
    dispatch(onDeleteHardwareLocation(hardwareEvent));
    setDeleteModal(false);
  };

  const handleDeleteClose = () => {
    setDeleteModal(false);
    setHardwareEvent({});
  };
  const toggleViewArea = () => {
    setHardwareModel(!hardwareModel);
    setIsEdit(!isEdit)
  };
  const getSubCategory = (categoryId: string) => {
    dispatch(OnGetSubCategoriesType({ categoryId: categoryId }));
  }
  const handleEditVenueClick = (event: any) => {
    setHardwareEvent({
      ...event,
    });
    setIsEdit(true);
    toggle();
  };

  const hardwareListColumns = [
    {
      text: "VENUE",
      dataField: "venueInfo",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent: any, hardware: any) => (
        <React.Fragment>{hardware.venueInfo.name}</React.Fragment>
      ),
    },
    {
      text: "VENUE AREA",
      dataField: "venueAreaInfo",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent: any, hardware: any) => (
        <React.Fragment>{hardware.venueAreaInfo.areaName}</React.Fragment>
      ),
    },
    {
      text: "CATEGORY",
      dataField: "categoryInfo",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent: any, hardware: any) => (
        <React.Fragment>
                    <Button
                        color="success"
                        onClick={() => handleViewCategory(hardware.categoryInfo)}
                        className="btn"
                    >
                        View
                    </Button>
          {/* <Button
          color="primary"
          onClick={() => handleModulePermissionClick(hardware)}
            className="btn"
          >
            <i className="mdi mdi-eye me-1"></i>
            Display
          </Button>  {' '}
          <Button
            color="success"
            onClick={() => handleViewGroup(hardware)}
            className="btn"
          >
            <i className="mdi mdi-plus me-1"></i>
            Add
          </Button> */}
          </React.Fragment>
      ),
    },
    {
      text: "KDS NAME",
      dataField: "name",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent: any, hardware: any) => (
        <React.Fragment><div className="font-size-18">{hardware.name.en}</div>
          <Link target="_blank" to={"/assets/kitchen?kdsId=" + hardware._id + "&area=" + hardware.venueAreasId + "&venueId=" + hardware.venueId}>
            <div className="btn btn-danger font-size-12">     <i className="mdi mdi-cast-connected me-1"></i> Connect KDS View Orders</div></Link>

        </React.Fragment>
      ),
    },
    {
      text: "CODE",
      dataField: "code",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent: any, hardware: any) => (
        <React.Fragment>{hardware.code}</React.Fragment>
      ),
    },
    {
      text: "Hardware(KDS) TYPE",
      dataField: "hardwareType",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent: any, hardware: any) => (
        <React.Fragment>{hardware.hardwareType}</React.Fragment>
      ),
    },
    {
      text: "ACTION",
      dataField: "action",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent: any, hardware: any) => (
        <React.Fragment>
          <UncontrolledDropdown>
            <DropdownToggle tag="button" className="btn btn-light btn-sm">
              <i className="uil uil-ellipsis-h"></i>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-end">
              {/* <DropdownItem to="#" onClick={() => handleViewAreaClick(venueArea)}>View Areas</DropdownItem> */}
              {/* <DropdownItem to="#" onClick={() => handleAddAreaClick(venueArea)}>Add Area</DropdownItem> */}
              <DropdownItem to="#" onClick={() => handleEditHardwareClick(hardware)}>Edit Hardware</DropdownItem>
              {/* <DropdownItem to="#" onClick={() => handleDeleteAreaClick(venue)}>Delete Area</DropdownItem> */}
              <DropdownItem to="#" onClick={() => handleDeleteClick(hardware)}>Delete</DropdownItem>
              {/* <DropdownItem to="#" onClick={() => handleDeleteClick(venueArea)}>{venueArea.status == 1 ? "Inactive" : "Active"}</DropdownItem> */}
            </DropdownMenu>
          </UncontrolledDropdown>
        </React.Fragment>
      ),
    },
  ];

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteHardware}//handleDeleteVenue
        onCloseClick={handleDeleteClose}//handleDeleteClose
        deleteButtonLable={hardwareEvent.status == 0 ? "Inactive" : "Active"}
      />
      <div className="page-content">
        <MetaTags>
          <title>Hardware | Retaurant Booking</title>
        </MetaTags>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <Container fluid>
          {/* Render Breadcrumbs */}
          <BreadCrumbs title="Restaurant" breadcrumbItem="Hardware" />
          {hardwareMsgResponse && hardwareMsgResponse.message ? (
            <UncontrolledAlert color="primary">
              {hardwareMsgResponse.message}
            </UncontrolledAlert>
          ) : null}
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="_id"
                        data={hardwareList}
                        columns={hardwareListColumns}
                        bootstrap4
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <Row className="align-items-start">
                              <div className="col-sm">
                                <div>
                                  <Button
                                    color="light"
                                    onClick={handleUserClicks}//
                                    className="mb-4 button-success"
                                  >
                                    <i className="mdi mdi-plus me-1"></i>
                                    Add KDS (Hardware)
                                  </Button>
                                  {/* <Button color="light" className="mb-4">
                                      <i className="mdi mdi-plus me-1"></i>
                                      Add Area Venue</Button> */}
                                </div>
                              </div>
                              <Col xs={3} md={3}>
                                <select className="input-select" onChange={(e) => filterArea(e.target.value)}>
                                  <option value=""> Select Venue </option>
                                  {
                                    venueList.map((venue: any, key: any) => (
                                      <option value={venue._id} key={key}>{venue.name}</option>
                                    ))}
                                </select>  </Col>
                              <Col xs={3} md={3}>
                                <select className="input-select" onChange={(e) => filterHardware(e.target.value)}>
                                  <option value=""> Select Area </option>
                                  {
                                    venueArea.map((area: any, key: any) => (
                                      <option value={area._id} key={key}>{area.areaName}</option>
                                    ))}
                                </select>  </Col>
                              {/* <Col xs={3} md={3}>    
                              <select className="input-select" onChange={(e) => filterHardware(e.target.value)}>
                                    <option value=""> Select Hardware Location </option>
                                    {
                                      hardwareLocation.map((area: any, key: any) => (
                                        <option value={area._id} key={key}>{area.name.en}</option>
                                      ))}
                                  </select>  </Col> */}
                            </Row>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                    defaultSorted={defaultSorted}
                                    classes={
                                      "table align-middle table-nowrap table-hover"
                                    }
                                    bordered={false}
                                    striped={false}
                                  />
                                  <Modal isOpen={modal} toggle={toggle} size="lg">
                                    <ModalHeader toggle={toggle} tag="h4">
                                      {!!isEdit
                                        ? "Edit KDS(Hardware)"
                                        : "Add KDS(Hardware)"}
                                    </ModalHeader>
                                    <ModalBody>
                                      <AvForm
                                        encType="multipart/form-data"
                                        onValidSubmit={(
                                          e: any,
                                          values: any
                                        ) => {
                                          console.log("Submittion", values);
                                          handleValidFormSubmit(values);
                                        }}
                                      >
                                        <Row form>

                                          <Row >
                                            <Col xs={6} md={4}>
                                              <AvField
                                                name="venueId"
                                                label="Select Venue"
                                                type="select"
                                                placeholder="Select Venue"
                                                errorMessage="Select valid Venue"
                                                multiple={false}
                                                required
                                                onChange={(e: any) => filterArea(e.target.value)}
                                                value={hardwareEvent.venueId || ""}
                                              >
                                                <option key='blankChoice' hidden value=""> Select Venue </option>
                                                {
                                                  venueList.map(({ _id, name }: any, key: any) => (
                                                    <option value={_id} key={key}>{name}</option>
                                                  ))}
                                              </AvField>
                                            </Col>
                                            <Col xs={6} md={4}>
                                              <AvField
                                                name="venueAreasId"
                                                label="Select Area"
                                                type="select"
                                                placeholder="Select Area"
                                                errorMessage="Select valid Area"
                                                // onChange={(e:any) => filterHardwareLocation(e.target.value)}
                                                multiple={false}
                                                required
                                                value={Array.isArray(hardwareEvent.venueAreasId) ? hardwareEvent.venueAreasId[0] : hardwareEvent.venueAreasId || ""}
                                              >
                                                <option key='blankChoice' hidden value=""> Select Venue Area </option>
                                                {
                                                  venueArea.map(({ _id, areaName }: any, key: any) => (
                                                    <option value={_id} key={key}>{areaName}</option>
                                                  ))}
                                              </AvField>
                                            </Col>
                                            {/* <Col xs={6} md={4}>
                                          <AvField
                                                type="select"
                                                name="hardwareLocationId"
                                                placeholder="Select Location"
                                                errorMessage="Select Location"
                                                label="Hardware Location"
                                                multiple={false}
                                                required
                                                value={hardwareEvent.hardwareLocationId || ""}
                                              >
                                                <option key='blankChoice1' hidden value=""> Select Location </option>
                                                {
                                                  hardwareLocation.map((location: any, key: any) => (
                                                    <option value={location._id} key={key}>{location.name.en}</option>
                                                  ))}
                                              </AvField>
                                          </Col> */}
                                            <Col xs={6} md={4}>    <AvField
                                              name="enName"
                                              label="Hardware(KDS) Name (EN)"
                                              type="text"
                                              placeholder="Enter Valid Hardware(KDS) Name"
                                              errorMessage="Invalid Hardware Name"
                                              validate={{
                                                required: { value: true, },
                                              }}
                                              value={hardwareEvent.enName || ""}
                                            /></Col>
                                            <Col xs={6} md={4}>
                                              <AvField
                                                name="grName"
                                                label="Hardware(KDS) Name (GR)"
                                                type="text"
                                                placeholder="Enter Valid Hardware(KDS) Name"
                                                errorMessage="Invalid Hardware Name"
                                                validate={{
                                                  required: { value: true, },
                                                }}
                                                value={hardwareEvent.grName || ""}
                                              />
                                            </Col>
                                            <Col xs={6} md={4}>
                                              <AvField
                                                name="code"
                                                label="Code"
                                                type="text"
                                                placeholder="Enter Hardware Code"
                                                errorMessage="Invalid Hardware Code"
                                                validate={{
                                                  required: { value: true },
                                                }}

                                                value={hardwareEvent.code || ""}
                                              />
                                            </Col>
                                            <Col xs={6} md={4}>
                                              <AvField
                                                name="hardwareType"
                                                label="Hardware Type"
                                                type="select"
                                                placeholder="Select Hardware Type"
                                                errorMessage="Select valid Model"
                                                multiple={false}
                                                required
                                                onChange={handlehardwareType}
                                                // onClick={handleCountryClick}
                                                value={
                                                  hardwareEvent.hardwareType ||
                                                  ""
                                                }
                                              >
                                                <option
                                                  key="blankChoice1"
                                                  hidden
                                                  value=""
                                                >
                                                  {" "}
                                                  Select Hardware Type{" "}
                                                </option>
                                                {
                                                  hadwaretypeLists.map(
                                                    (hardwareType: string) => (
                                                      <option
                                                        key={hardwareType}
                                                        value={hardwareType}
                                                      >
                                                        {hardwareType}
                                                      </option>
                                                    )
                                                  )}
                                                <option key='otherhardwareType' value="other">{" "} Other{" "} </option>
                                              </AvField>
                                            </Col>
                                            <Col xs={6} md={4}>
                                              <AvField
                                                name="ip"
                                                label="Network IP"
                                                type="text"
                                                placeholder="Enter Network IP"
                                                errorMessage="Invalid Network IP"
                                                validate={{
                                                  required: { value: true, },
                                                }}
                                                value={hardwareEvent.ip || ""}
                                              />
                                            </Col>
                                            <Col xs={6} md={4}>
                                              <AvField
                                                name="port"
                                                label="Network Port"
                                                type="text"
                                                placeholder="Enter Network Port"
                                                errorMessage="Invalid Network Port"
                                                validate={{
                                                  required: { value: true, },
                                                }}
                                                value={hardwareEvent.port || ""}
                                              />
                                            </Col>
                                            <Col xs={6} md={4}>
                                              <AvField
                                                name="gateway"
                                                label="Network Gateway"
                                                type="text"
                                                placeholder="Enter Network Gateway"
                                                errorMessage="Invalid Network Gateway"
                                                validate={{
                                                  required: { value: true, },
                                                }}
                                                value={hardwareEvent.gateway || ""}
                                              />
                                            </Col>
                                            <Col xs={6} md={4}>
                                              <AvField
                                                name="subnet"
                                                label="Network Subnet"
                                                type="text"
                                                placeholder="Enter Network Subnet"
                                                errorMessage="Invalid Network Subnet"
                                                validate={{
                                                  required: { value: true, },
                                                }}
                                                value={hardwareEvent.subnet || ""}
                                              />
                                            </Col>
                                            <Col xs={6} md={4}>
                                              <AvField
                                                name="model"
                                                label="Model"
                                                type="text"
                                                placeholder="Select Model"
                                                errorMessage="Select valid Model"
                                                multiple={false}
                                                required
                                                // onChange={handlehardwareType}
                                                value={
                                                  hardwareEvent.model || ""
                                                }
                                              ></AvField>
                                            </Col>
                                            <Col xs={6} md={4}>
                                              <AvField
                                                name="serialNumber"
                                                label="Serial Number"
                                                type="text"
                                                placeholder="Enter Valid Serial Number "
                                                errorMessage="Invalid Serial Number "
                                                validate={{
                                                  required: { value: true },
                                                }}
                                                value={
                                                  hardwareEvent.serialNumber || ""
                                                }
                                              />
                                            </Col>
                                            <Col xs={6} md={4}>
                                              <AvField
                                                name="firmwareVersion"
                                                label="Firm Ware Version"
                                                type="text"
                                                placeholder="Enter Firm Ware Verstion "
                                                errorMessage="Invalid Firm Ware Verstion "
                                                validate={{
                                                  required: { value: true },
                                                }}
                                                value={
                                                  hardwareEvent.firmwareVersion || ""

                                                }
                                              />
                                            </Col>
                                            <Col xs={6} md={4}>
                                              <AvField
                                                name="macAddress"
                                                label="MAC Address"
                                                type="text"
                                                placeholder="Enter MAC Address "
                                                errorMessage="Invalid MAC Address "
                                                validate={{
                                                  required: { value: true },
                                                }}
                                                value={
                                                  hardwareEvent.macAddress || ""
                                                }
                                              />
                                            </Col>
                                            <Col xs={8} md={6}>
                                              <AvField
                                                type="select"
                                                name="categoryId"
                                                placeholder="Select Multiple Category"
                                                errorMessage="Select valid Catrgory"
                                                label="Categories"
                                                helpMessage="MULTIPLE!"
                                                className="dropdown-select"
                                                multiple={true}
                                                required
                                                // onChange={(e: any) => getSubCategory(e.target.value)}
                                                value={hardwareEvent.categoryId || ""}
                                              >
                                                <option key='blankChoice1' hidden value=""> Select Multiple Category </option>
                                                {
                                                  categories.map((category: any, key: any) => (
                                                    <option value={category._id} key={key}>{category.name.en}</option>
                                                  ))}
                                              </AvField>

                                            </Col>
                                            <Col xs={6} md={4}>
                                              {otherhardwareType &&
                                                <div className="mb-3">
                                                  <AvField
                                                    name="hardwareType"
                                                    type="text"
                                                    label="Hardware Other Type Name"
                                                    placeholder="Hardware Other Type Name"
                                                    errorMessage="Invalid Hardware Other Type Name"
                                                    validate={{
                                                      required: { value: true },
                                                    }}
                                                    onChange={
                                                      handleOtherInputChange
                                                    }
                                                    value={
                                                      hardwareEvent.hardwareType ||
                                                      ""
                                                    }
                                                  />
                                                </div>
                                              }
                                            </Col>
                                            {/* <Col xs={6} md={4}>
                                              <AvField
                                                type="select"
                                                name="subCategoryId"
                                                placeholder="Select Sub Category"
                                                errorMessage="Select valid Sub Catrgory"
                                                label="Sub Category"
                                                multiple={false}
                                                value={hardwareEvent.subCategoryId || ""}
                                              >
                                                <option key='blankChoice1' hidden value=""> Select SubCategory </option>
                                                {
                                                  subCategories.map((category: any, key: any) => (
                                                    <option value={category._id} key={key}>{category.name.en}</option>
                                                  ))}
                                              </AvField>

                                            </Col> */}
                                          </Row>
                                          <Col xs={12}>
                                            {/* <div className="mb-3">
                                              <AvField
                                                name="name"
                                                label="Name"
                                                type="text"
                                                placeholder="Enter Hardware Name"
                                                errorMessage="Invalid Hardware Name"
                                                validate={{
                                                  required: { value: true },
                                                }}
                                                value={hardwareEvent.name || ""}
                                              />
                                            </div> */}
                                            {/* ----- User Choice for country ------ */}
                                            {/* Get Country */}
                                            {/* <div className="mb-3">
                                              <AvField
                                                name="hardware location id"
                                                label="Hardware Location ID"
                                                type="text"
                                                placeholder="Enter Hardware Location ID"
                                                errorMessage="Invalid Hardware Location ID"
                                                multiple={false}
                                                required
                                                value={
                                                  hardwareEvent.hardwarelocationid ||
                                                  ""
                                                }
                                                // onClick={handleCountryClick}
                                                onChange={handlehardwareType}
                                              ></AvField>
                                            </div> */}

                                            {/* <div className="mb-3">
                                              <AvField
                                                name="network"
                                                label="Network"
                                                type="text"
                                                placeholder="Enter Valid Network"
                                                errorMessage="Invalid Network"
                                                multiple={false}
                                                required
                                                value={
                                                  hardwareEvent.network || ""
                                                }
                                                // onClick={handleStateqClick}
                                              ></AvField>
                                            </div> */}

                                            {/* Hardware Type */}
                                          </Col>
                                        </Row>
                                        <Row>
                                          <Col>
                                            <div className="text-end">
                                              <button
                                                type="button"
                                                className="btn btn-light w-sm"
                                                onClick={handleShow}
                                              >
                                                Close
                                              </button>
                                              <button
                                                type="submit"
                                                className="btn btn-success save-user"
                                              >
                                                Save
                                              </button>
                                            </div>
                                          </Col>
                                        </Row>
                                      </AvForm>
                                    </ModalBody>
                                  </Modal>

                                  <Modal isOpen={categoryModal} toggle={handleShowCategory} size="lg">
                                                                    <ModalHeader toggle={handleShowCategory}>Categories Info</ModalHeader>
                                                                    <ModalBody>
                                                                        <Table responsive>
                                                                            <thead>
                                                                                <tr>
                                                                                    <th>
                                                                                        #
                                                                                    </th>
                                                                                    <th>
                                                                                        Name
                                                                                    </th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {
                                                                                    (categoryEvent != "") ?
                                                                                        categoryEvent.map((m: any, key: any) => (

                                                                                            <tr key={key}>
                                                                                                <th scope="row">
                                                                                                    {key + 1}
                                                                                                </th>
                                                                                                <td>
                                                                                                    {m.name.en}
                                                                                                </td>

                                                                                            </tr>
                                                                                        ))
                                                                                        : <tr><th>No Categories</th><td>No Categories</td><td>No Categories</td></tr>
                                                                                }
                                                                            </tbody>
                                                                        </Table>
                                                                    </ModalBody>
                                                                    <ModalFooter>
                                                                        <Button color="secondary" onClick={handleShowCategory}>
                                                                            Close
                                                                        </Button>
                                                                    </ModalFooter>

                                                                </Modal>
                                </div>
                              </Col>
                            </Row>
                            <Row className="align-items-md-center mt-30">
                              <Col className="pagination pagination-rounded justify-content-end mb-2">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Hardwares;
