import React, { useEffect, useState, ChangeEvent } from "react";
import MetaTags from "react-meta-tags";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import { AvForm, AvField, AvCheckboxGroup, AvCheckbox, AvInput } from "availity-reactstrap-validation";
import {
  getVenuesData as onGetVenuesData,
  updateVenueReview as onUpdateVenueReview,
  getVenueList as onGetVenueList,
} from "../../slices/thunks";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { isEmpty } from "lodash";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
interface LogoutProps {
  history: any;
}
//redux
import { useSelector, useDispatch } from "react-redux";

const Reviews = ({ history }: LogoutProps) => {
  const dispatch = useDispatch();
  const { SearchBar } = Search;

  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [venueEvent, setVenueEvent] = useState<any>({});
  const [modal, setModal] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [paramItem, setparamItem] = useState<any>({});
  const [file, setFile] = useState<File>();
  // const formData = new FormData();
  const handleShow = () => setModal(false);
  const { venueList, statusCode, venueDataList } = useSelector((state: any) => ({
    venueList: state.venues.venuesData,
    statusCode: state.venues.statusCode,
    venueDataList: state.venues.venueList,
  }));

  const pageOptions = {
    sizePerPage: 10,
    totalSize: venueList.length, // replace later with size(users),
    custom: true,
  };

  const defaultSorted: any = [
    {
      dataField: "_id", // if dataField is not match to any column you defined, it will be ignored.
      order: "asc", // desc or asc
    },
  ];
  // ----- 1. Get Venue List -----------
  useEffect(() => {
    const urlSearchString = window.location.search;
    const params = new URLSearchParams(urlSearchString);
    setparamItem({
      'venueId': params.get('venueId')
    })
    dispatch(onGetVenuesData({ "isReview": "Yes", "venueId": params.get('venueId') }));
    dispatch(onGetVenueList());

  }, [dispatch, history]);

  const handleValidFormSubmit = (values: any) => {
    values["id"] = venueEvent._id
    values["userid"] = venueEvent.userid
    // save edit user
    if (isEdit) {
      dispatch(onUpdateVenueReview(values));
    } else {
      dispatch(onUpdateVenueReview(values));
    }
    setTimeout(() => {
      dispatch(onGetVenuesData({ "isReview": "Yes" }));
    }, 2000);
    toggle();
  };

  const handleDeleteVenueClick = (event: any) => {
    const urlSearchString = window.location.search;
    const params = new URLSearchParams(urlSearchString);
    setparamItem({
      'venueId': params.get('venueId')
    })
    dispatch(onUpdateVenueReview({ 'action': "delete", 'venueId': event.venueId, "id": event.id }));
    setTimeout(() => {
      dispatch(onGetVenuesData({ "isReview": "Yes", "venueId": params.get('venueId') }));
    }, 2000);

  };

  const toggle = () => {
    setModal(!modal);
    if (!modal && !isEmpty(venueEvent) && !!isEdit) {
      setTimeout(() => {
        setVenueEvent({})
        setIsEdit(false);
      }, 500);
    }
  };

  const handleUserClicks = () => {
    setIsEdit(false);
    toggle();
  };

  const handleEditVenueClick = (event: any) => {
    setVenueEvent({
      ...event
    });
    setIsEdit(true);
    toggle();
  };

  const venuesListColumns = [
    {
      text: "VENUE",
      dataField: "venue",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent: any, venue: any) => <React.Fragment>
        <img
          className="avatar-sm rounded-circle me-2"
          src={venue.profileImage}
          alt=""
        />
        {venue.venue} </React.Fragment>,
    },
    {
      text: "NAME",
      dataField: "name",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent: any, venue: any) => <React.Fragment>
        {venue.name}
      </React.Fragment>,
    },
    {
      text: "QR",
      dataField: "qr",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent: any, venue: any) => (
        <React.Fragment>
          <img
            className="avatar-sm  me-3"
            src={venue.qr}
            alt=""
          />
          <a
            href={venue.qr}
            download={venue.venue.replace(' ', '-') + "-review-" + venue.name + ".png"}
          >
            <i className="fa fa-download" />
          </a>
        </React.Fragment>
      ),
    },
    {
      text: "RATING",
      dataField: "link",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent: any, venue: any) => <React.Fragment>
        <div className="star-rating">
          {[...Array(parseInt(venue.rating))].map((star) => {
            return (
              <span key='star' className="star text-warning">&#9733;</span>
            );
          })}
        </div>
        <div>{venue.rating}</div>
      </React.Fragment>,
    },
    {
      text: "STATUS",
      dataField: "status",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent: any, venue: any) => (
        <React.Fragment>
          {venue.status == 1 ?
            <div className="badge bg-success font-size-12">{"Active"}</div>
            : <div className="badge bg-warning font-size-12">{"Inactive"}</div>}
        </React.Fragment>
      ),
    },
    {
      text: "ACTION",
      dataField: "action",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent: any, venue: any) => (
        <React.Fragment>
          <UncontrolledDropdown>
            <DropdownToggle tag="button" className="btn btn-light btn-sm">
              <i className="uil uil-ellipsis-h"></i>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-end dropdown-position">
              <DropdownItem to="#" onClick={() => handleEditVenueClick(venue)}>Edit</DropdownItem>
              <DropdownItem to="#" onClick={() => handleDeleteVenueClick(venue)}>Delete</DropdownItem>
              {/* <DropdownItem to="#" onClick={() => handleDeleteClick(venue)}>{venue.status == 1 ? "Inactive" : "Active"}</DropdownItem> */}
            </DropdownMenu>
          </UncontrolledDropdown>
        </React.Fragment>
      ),
    },
  ];

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Review | Retaurant Booking</title>
        </MetaTags>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Restaurant" breadcrumbItem="Review" />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="_id"
                        data={venueList}
                        columns={venuesListColumns}
                        bootstrap4
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <Row className="align-items-start">
                              <div className="col-sm">
                                <div>
                                  <Button color="light" onClick={handleUserClicks} className="mb-4 button-success">
                                    <i className="mdi mdi-plus me-1"></i>
                                    Add Review</Button>
                                  {/* <Button color="light" className="mb-4">
                                    <i className="mdi mdi-plus me-1"></i>
                                    Add Area Venue</Button> */}
                                </div>
                              </div>
                            </Row>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                    defaultSorted={defaultSorted}
                                    classes={
                                      "table align-middle table-nowrap table-hover"
                                    }
                                    bordered={false}
                                    striped={false}
                                  />
                                  <Modal isOpen={modal} toggle={toggle} size="lg">
                                    <ModalHeader toggle={toggle} tag="h4">
                                      {!!isEdit ? "Edit Review" : "Add Review"}
                                    </ModalHeader>
                                    <ModalBody>
                                      <AvForm
                                        encType='multipart/form-data'
                                        onValidSubmit={(
                                          e: any,
                                          values: any
                                        ) => {
                                          handleValidFormSubmit(values);
                                        }}
                                      >
                                        <Row form>
                                          <Row >
                                            <Col xs={6} md={4}>
                                              <AvField
                                                type="select"
                                                name="venueId"
                                                placeholder="Select Venue"
                                                errorMessage="Please select venue"
                                                label="Venue"
                                                multiple={false}
                                                required
                                                value={venueEvent.venueId || ""}
                                              >
                                                <option key='blankChoice' hidden value=""> Select Venue </option>
                                                {
                                                  venueDataList.map((venue: any, index: number) => (
                                                    <option value={venue._id} key={index}>{venue.name}</option>
                                                  ))}
                                              </AvField>
                                              {!!isEdit ?
                                                <AvField
                                                  type="hidden"
                                                  name="reviewId"
                                                  value={venueEvent.id || ""}
                                                >
                                                </AvField> : ""}
                                            </Col>
                                            <Col xs={6} md={4}>
                                              <AvField
                                                name="name"
                                                label="Name"
                                                type="text"
                                                placeholder="Google"
                                                errorMessage="Invalid Name"
                                                validate={{
                                                  required: { value: true, },
                                                }}
                                                value={venueEvent.name || ""}
                                              />
                                            </Col>
                                            <Col xs={6} md={4}>
                                              <AvField
                                                name="link"
                                                label="Link"
                                                type="text"
                                                placeholder="google.com"
                                                errorMessage="Invalid Link"
                                                value={venueEvent.link || ""}
                                              />
                                            </Col>
                                            <Col xs={6} md={4}>
                                              <AvField
                                                name="rating"
                                                label="Rating"
                                                type="text"
                                                placeholder="4.2"
                                                validate={{
                                                  required: { value: false },
                                                }}
                                                value={venueEvent.rating || ""}
                                              />
                                            </Col>
                                          </Row>

                                        </Row>
                                        <Row>
                                          <Col>
                                            <div className="text-end">
                                              <button type="button" className="btn btn-danger" onClick={handleShow}>Close</button>
                                              <button
                                                type="submit"
                                                className="btn btn-success save-user"
                                              >
                                                Save
                                              </button>
                                            </div>
                                          </Col>
                                        </Row>
                                      </AvForm>
                                    </ModalBody>
                                  </Modal>

                                </div>
                              </Col>
                            </Row>
                            <Row className="align-items-md-center mt-30">
                              <Col className="pagination pagination-rounded justify-content-end mb-2">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row >
        </Container >
      </div >
    </React.Fragment >
  );
};

export default Reviews;
