import { createSlice } from "@reduxjs/toolkit";

import {
  getMenuCategories,
  updateCategoryStatus,
  addNewCategory,
  updateCategory,
  resetApiResponseFlag,
  getAddOns,
  deleteAddOns
} from "./thunk";

interface CategoriesStateType {
  categories: Array<any>,
  getAdOnsList: Array<any>,
  catMsgResponse: object,
  error?: string | object | null | undefined | unknown,
  categoriesUpdate: any

}
export const initialState: CategoriesStateType = {
  categories: [],
  getAdOnsList:[],
  error: {},
  catMsgResponse: {},
  categoriesUpdate: {}
};

const categoriesSlice = createSlice({
  name: "categories",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(addNewCategory.fulfilled, (state: any, action: any) => {
      state.categories.push(action.payload.data)
      state.catMsgResponse = {
        message:action.payload ? action.payload.message : 'New Category Added Successfully',
        type: action.payload ? 'success' : 'error',
      };

    })
    builder.addCase(addNewCategory.rejected, (state, action) => {
      state.error = action.payload || null;
    })
    builder.addCase(getMenuCategories.fulfilled, (state: any, action: any) => {
      state.categories = action.payload.data;
    })
    builder.addCase(getMenuCategories.rejected, (state, action) => {
      state.error = action.payload || null;
    })
    builder.addCase(getAddOns.fulfilled, (state: any, action: any) => {
      state.getAdOnsList = action.payload.data;
    })
    builder.addCase(getAddOns.rejected, (state, action) => {
      state.error = action.payload || null;
    })
    builder.addCase(updateCategoryStatus.fulfilled, (state: any, action: any) => {
      state.categoriesUpdate = action.payload;
      state.catMsgResponse = {
        message:action.payload ? action.payload.message : 'Category Status Updated Successfully',
        type: action.payload ? 'success' : 'error',
      };
    })
    builder.addCase(updateCategoryStatus.rejected, (state, action) => {
      state.error = action.payload || null;
    })
    builder.addCase(updateCategory.fulfilled, (state: any, action: any) => {
      state.categoriesUpdate = action.payload;
      state.catMsgResponse = {
        message:action.payload ? action.payload.message : 'Category Updated Successfully',
        type: action.payload ? 'success' : 'error',
      };
    })
    builder.addCase(updateCategory.rejected, (state, action) => {
      state.error = action.payload || null;
    })
    builder.addCase(deleteAddOns.fulfilled, (state: any, action: any) => {
      state.catMsgResponse = action.payload.data;
    })
    builder.addCase(deleteAddOns.rejected, (state, action) => {
      state.error = action.payload || null;
    })
    builder.addCase(resetApiResponseFlag.fulfilled, (state, action) => {
      state.catMsgResponse = { message: "" };
    })

  },
});

export default categoriesSlice.reducer;