import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getVenueList as getVenueListApi,
  getVenues as getVenuesApi,
  updateVenueStatus as updateVenueStatusApi,
  addNewVenue as addNewVenueApi,
  updateVenue as updateVenueApi,
  addVenueSettingConfigure as addVenueSettingConfigureApi,
  updateVenueReview as updateVenueReviewApi
} from "../../helpers/backend_helper";


export const addNewVenue = createAsyncThunk(
  "venues/addNewVenue",
  async (event: any) => {
    try {
      const response = addNewVenueApi(event);
      return response;
    } catch (error) {
      return error;
    }
  }
);


export const getVenueList = createAsyncThunk(
  "venues/getVenueList",
  async () => {
    try {
      const response = getVenueListApi();
      const modifiedEvent = await response;
      return modifiedEvent;
    } catch (error) {
      return error;
    }
  }
);

export const getVenuesData = createAsyncThunk(
  "venues/getVenues",
  async (e: any) => {
    try {
      const response = getVenuesApi(e);
      const modifiedEvent = await response;
      return modifiedEvent;
    } catch (error) {
      return error;
    }
  }
);

export const updateVenueStatus = createAsyncThunk(
  "venues/updateVenueStatus",
  async (event: any) => {
    try {
      const response = updateVenueStatusApi(event);
      const modifiedEvent = await response;
      return modifiedEvent;
    } catch (error) {
      return error;
    }
  }
);

export const resetVenueApiResponseFlag = createAsyncThunk(
  "venues/resetVenueApiResponseFlag",
  async () => {
    try {
      return true;
    } catch (error) {
      return error;
    }
  }
);

export const updateVenue = createAsyncThunk(
  "venues/updateVenue",
  async (event: any) => {
    try {
      const response = updateVenueApi(event);
      const modifiedEvent = await response;
      return modifiedEvent;
    } catch (error) {
      return error;
    }
  }
);

export const updateVenueReview = createAsyncThunk(
  "venues/updateVenueReview",
  async (event: any) => {
    try {
      const response = updateVenueReviewApi(event);
      const modifiedEvent = await response;
      return modifiedEvent;
    } catch (error) {
      return error;
    }
  }
);


export const addVenueSettingConfigure = createAsyncThunk(
  "venues/addVenueSettingConfigure",
  async (event: any) => {
    try {
      const response = addVenueSettingConfigureApi(event);
      return response;
    } catch (error) {
      return error;
    }
  }
);