import { createSlice } from "@reduxjs/toolkit";

import {
  getAssetsList,
  addNewAsset,
  resetAssetApiResponseFlag,
  updateAsset,
  updateAssetStatus,
  getAssetsListWithType
} from "./thunk";

interface AssetsStateType {
  assetsList: Array<any>,
  assetsListWithType: Array<any>,
  assetMsgResponse: object,
  error?: string | object | null | undefined | unknown,
  assetAddUpdate: any
}
export const initialState: AssetsStateType = {
  assetsList: [],
  assetsListWithType: [],
  error: {},
  assetMsgResponse: {},
  assetAddUpdate: {}
};

const assetsSlice = createSlice({
  name: "assets",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(addNewAsset.fulfilled, (state: any, action: any) => {
      state.assetAddUpdate = action.payload;
      state.assetMsgResponse = {
        message: action.payload ? action.payload.message : 'New Asset Added Successfully',
        type: action.payload ? 'success' : 'error',
      };

    })
    builder.addCase(addNewAsset.rejected, (state, action) => {
      state.error = action.payload || null;
    })
    builder.addCase(getAssetsList.fulfilled, (state: any, action: any) => {
      state.assetsList = action.payload.data;
    })
    builder.addCase(getAssetsList.rejected, (state, action) => {
      state.error = action.payload || null;
    })
    builder.addCase(getAssetsListWithType.fulfilled, (state: any, action: any) => {
      state.assetsListWithType = action.payload.data;
    })
    builder.addCase(getAssetsListWithType.rejected, (state, action) => {
      state.error = action.payload || null;
    })
    builder.addCase(resetAssetApiResponseFlag.fulfilled, (state, action) => {
      state.assetMsgResponse = { message: "" };
    })
    builder.addCase(updateAssetStatus.fulfilled, (state: any, action: any) => {
      state.assetAddUpdate = action.payload;
      state.assetMsgResponse = {
        message: action.payload ? action.payload.message : 'Asset Status Updated Successfully',
        type: action.payload ? 'success' : 'error',
      };
    })
    builder.addCase(updateAssetStatus.rejected, (state, action) => {
      state.error = action.payload || null;
    })

    builder.addCase(updateAsset.fulfilled, (state: any, action: any) => {
      state.assetAddUpdate = action.payload;
      state.assetMsgResponse = {
        message: action.payload ? action.payload.message : 'Asset Updated Successfully',
        type: action.payload ? 'success' : 'error',
      };
    })
    builder.addCase(updateAsset.rejected, (state, action) => {
      state.error = action.payload || null;
    })
  },
});

export default assetsSlice.reducer;