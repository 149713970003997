import { createSlice } from "@reduxjs/toolkit";

import {
    getNotification,
    deleteNotification,
    resetApiNotificationResponseFlag
} from "./thunk";

interface SubCategoriesStateType {
    notificationsList: Array<any>,
    catMsgResponse: object,
    error?: string | object | null | undefined | unknown

}
export const initialState: SubCategoriesStateType = {
    notificationsList: [],
    error: {},
    catMsgResponse: {}
};

const notificationSlice = createSlice({
    name: "notification",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getNotification.rejected, (state, action) => {
            state.error = action.payload || null;
        })
        builder.addCase(getNotification.fulfilled, (state: any, action: any) => {
            state.notificationsList = action.payload.data;
        })

        builder.addCase(deleteNotification.rejected, (state, action) => {
            state.error = action.payload || null;
        })
        builder.addCase(deleteNotification.fulfilled, (state: any, action: any) => {
            state.catMsgResponse = action.payload.data;
        })
    },
});

export default notificationSlice.reducer;