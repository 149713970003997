import { createSlice } from "@reduxjs/toolkit";
import {
  addNewVenue,
  getVenueList,
  resetVenueApiResponseFlag,
  updateVenueStatus,
  updateVenue,
  addVenueSettingConfigure,
  updateVenueReview,
  getVenuesData
} from "./thunk";
import { logoutUser } from "../../slices/thunks";
interface LogoutProps {
  history: any;
}
interface VenueStateType {
  venueList: Array<any>,
  venuesData: Array<any>,
  venueMsgResponse: object,
  error?: string | object | null | undefined | unknown,
  statusCode?: string | object | null | undefined | unknown | number,
  venueAddUpdate: any
}
export const initialState: VenueStateType = {
  venueList: [],
  venuesData: [],
  error: {},
  venueMsgResponse: {},
  venueAddUpdate: {}
};

const venuesSlice = createSlice({
  name: "venues",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(addNewVenue.fulfilled, (state: any, action: any) => {
      state.venueAddUpdate = action.payload;
      state.venueMsgResponse = {
        message: action.payload ? action.payload.message : 'New Venue Area Added Successfully',
        type: action.payload ? 'success' : 'error',
      };
    })
    builder.addCase(addNewVenue.rejected, (state, action) => {
      state.error = action.payload || null;
    })

    builder.addCase(addVenueSettingConfigure.fulfilled, (state: any, action: any) => {
      state.venueAddUpdate = action.payload;
      state.venueMsgResponse = {
        message: action.payload ? action.payload.message : 'Successfully Update',
        type: action.payload ? 'success' : 'error',
      };
    })
    builder.addCase(addVenueSettingConfigure.rejected, (state, action) => {
      state.error = action.payload || null;
    })

    builder.addCase(updateVenueReview.fulfilled, (state: any, action: any) => {
      state.venueAddUpdate = action.payload;
      state.venueMsgResponse = {
        message: action.payload ? action.payload.message : 'Successfully Update',
        type: action.payload ? 'success' : 'error',
      };
    })
    builder.addCase(updateVenueReview.rejected, (state, action) => {
      state.error = action.payload || null;
    })

    builder.addCase(getVenueList.fulfilled, (state: any, action: any) => {
      state.venueList = action.payload.data;
      state.statusCode = action.payload.statusCode;
    })
    builder.addCase(getVenueList.rejected, (state, action) => {
      state.error = action.payload || null;
    })

    builder.addCase(getVenuesData.fulfilled, (state: any, action: any) => {
      state.venuesData = action.payload.data;
      state.statusCode = action.payload.statusCode;
    })
    builder.addCase(getVenuesData.rejected, (state, action) => {
      state.error = action.payload || null;
    })

    builder.addCase(resetVenueApiResponseFlag.fulfilled, (state, action) => {
      state.venueMsgResponse = { message: "" };
    })

    builder.addCase(updateVenueStatus.fulfilled, (state: any, action: any) => {
      state.venueAddUpdate = action.payload;

      state.venueMsgResponse = {
        message: action.payload ? action.payload.message : 'Venue Status Updated Successfully',
        type: action.payload ? 'success' : 'error',
      };
    })
    builder.addCase(updateVenueStatus.rejected, (state, action) => {
      state.error = action.payload || null;
    })

    builder.addCase(updateVenue.fulfilled, (state: any, action: any) => {
      state.venueAddUpdate = action.payload;
      state.venueMsgResponse = {
        message: action.payload ? action.payload.message : 'Venue Updated Successfully',
        type: action.payload ? 'success' : 'error',
      };
    })
    builder.addCase(updateVenue.rejected, (state, action) => {
      state.error = action.payload || null;
    })
  },
});

export default venuesSlice.reducer;