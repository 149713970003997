import React, { useEffect, useState, ChangeEvent } from "react";
import MetaTags from "react-meta-tags";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  UncontrolledAlert,
  ModalFooter,
  Table
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import {
  getAssetsList as onGetAssetsList,
  getVenueList as onGetVenueList,
  getVenueArea as onGetVenueArea,
  addNewAsset as onAddNewAsset,
  updateAssetStatus as onUpdateAssetStatus,
  updateAsset as onUpdateAsset,
  resetAssetApiResponseFlag as onResetApiResponseFlag,
  getAssetsListWithType as onGetAssetsListWithType
} from "../../slices/thunks";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { isEmpty } from "lodash";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DeleteModal from "../Common/DeleteModal";
//redux
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { Link } from "react-router-dom";

const Assets = () => {
  const dispatch = useDispatch();

  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [assetEvent, setAssetEvent] = useState<any>({});
  const [groupEvent, setGroupEvent] = useState<any>([]);
  const [modal, setModal] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [isAssetGroup, setIsAssetGroup] = useState<boolean>(false);
  const [file, setFile] = useState<File>();
  const [groupModel, setGroupModel] = useState<boolean>(false);
  const [paramItem, setparamItem] = useState<any>({});
  // const formData = new FormData();
  const handleShow = () => setModal(false);
  const handleShowClose = () => setGroupModel(false);
  const { venueList, assetsList, assetMsgResponse, assetAddUpdate, venueArea, assetsListWithType } = useSelector((state: any) => ({
    venueList: state.venues.venueList,
    venueArea: state.areas.venueArea,
    assetsList: state.assets.assetsList,
    assetsListWithType: state.assets.assetsListWithType,
    assetMsgResponse: state.assets.assetMsgResponse,
    assetAddUpdate: state.assets.assetAddUpdate
  }));

  useEffect(() => {
    const urlSearchString = window.location.search;
    const params = new URLSearchParams(urlSearchString);
    setparamItem({
      'venueId': params.get('venueId'),
      'venueAreasId': params.get('venueAreasId')
    })
  }, []);

  useEffect(() => {
    const urlSearchString = window.location.search;
    const params = new URLSearchParams(urlSearchString);
    if (params.get('venueId')) {
      dispatch(onGetAssetsList(paramItem));
    } else {
      dispatch(onGetAssetsList({}));
    }
    dispatch(onGetVenueList());
  }, [dispatch, assetAddUpdate, paramItem]);

  useEffect(() => {
    const timer = setTimeout(() => {
      dispatch(onResetApiResponseFlag());
    }, 5000);
    return () => clearTimeout(timer);
  }, [assetMsgResponse]);

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
  };


  const pageOptions = {
    sizePerPage: 10,
    totalSize: assetsList.length, // replace later with size(users),
    custom: true,
  };

  const defaultSorted: any = [
    {
      dataField: "_id", // if dataField is not match to any column you defined, it will be ignored.
      order: "asc", // desc or asc
    },
  ];

  const filterAssetArea = (venueId: string) => {
    dispatch(onGetAssetsList({ venueId: venueId }));
    dispatch(onGetVenueArea({ id: venueId }));
  };

  const filterAssetAreaList = (venueAreasId: string) => {
    dispatch(onGetAssetsListWithType({ venueAreasId: venueAreasId, combinationType: "Single" }));
  };

  const handleDeleteClick = (event: any) => {
    setAssetEvent({
      id: event._id,
      status: event.status == 1 ? 0 : 1
    });
    setDeleteModal(true);
  };
  /**
  * On delete event
  */
  const handleDeleteAsset = () => {
    dispatch(onUpdateAssetStatus(assetEvent));
    setDeleteModal(false);
  };

  const handleDeleteClose = () => {
    setDeleteModal(false)
    setAssetEvent({})
  };

  const filterArea = (venueId: string) => {
    dispatch(onGetVenueArea({ id: venueId }));
  };

  const handleValidFormSubmit = (values: any) => {
    if (isEdit) {
      values["id"] = assetEvent._id
      if (file?.size) {
        values['file'] = file;
      }
      dispatch(onUpdateAsset(values));
    } else {
      values['file'] = file;
      dispatch(onAddNewAsset(values));
    }
    setAssetEvent({});
    setIsEdit(false);
    setModal(!modal);
    dispatch(onGetAssetsList(paramItem));
  };

  const toggle = () => {
    setModal(!modal);
    if (!modal && !isEmpty(assetEvent) && !!isEdit) {
      setTimeout(() => {
        setAssetEvent({})
        setIsEdit(false);
      }, 500);
    }
  };

  const handleAddAssetClicks = () => {
    setIsEdit(false);
    setAssetEvent({})
    setModal(!modal);
  };

  const handleViewGroup = (event: any) => {
    setGroupEvent(event.assetsInfo);
    setGroupModel(true)
  };

  const handleSetAssetIsGroup = (e: string) => {
    if (e === 'Group') {
      setIsAssetGroup(true)
    } else {
      setIsAssetGroup(false)
    }
  };

  const handleEditAssetClick = (event: any) => {
    setAssetEvent(event);
    setIsEdit(true);
    setModal(!modal);
    if (event.combinationType == 'Group') {
      setIsAssetGroup(true)
    } else {
      setIsAssetGroup(false)
    }
    dispatch(onGetAssetsListWithType({ venueAreasId: event.venueAreasId, combinationType: "Single" }));
    dispatch(onGetVenueArea({ id: event.venueId }));
  };

  const venuesListColumns = [
    {
      text: "QR CODE",
      dataField: "qrPrefix",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent: any, asset: any) => (
        <React.Fragment>
          <img
            className="avatar-sm  me-3"
            src={asset.qrPrefix}
            alt=""
          />
          <a
            href={asset.qrPrefix}
            download={asset.venueInfo.name.replace(' ', '-') + "-asset-" + asset.assetName + ".png"}
          >
            <i className="fa fa-download" />
          </a>
        </React.Fragment>
      ),
    },
    {
      text: "ASSET TYPE",
      dataField: "combinationType",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent: any, asset: any) => <React.Fragment>{asset.combinationType}</React.Fragment>,
    },
    {
      text: "VENUE",
      dataField: "venueId",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent: any, asset: any) => <React.Fragment>
        <img
          className="avatar-sm rounded-circle me-2"
          src={asset.locationOnFloorPlan}
          alt=""
        />{asset.venueInfo.name}</React.Fragment>,
    },
    {
      text: "VENUE AREA",
      dataField: "areaName",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent: any, asset: any) => <React.Fragment>{asset.venueAresInfo.areaName}</React.Fragment>,
    },
    {
      text: "ASSET",
      dataField: "assetName",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent: any, asset: any) => <React.Fragment>
        {asset.assetName}

      </React.Fragment>,
    },
    {
      text: "TYPE",
      dataField: "assetType",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent: any, asset: any) => <React.Fragment>{asset.assetType}</React.Fragment>,
    },
    {
      text: "KITCHEN",
      dataField: "kitchen",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent: any, asset: any) => <React.Fragment>
        <Link target="_blank" to={"/assets/kitchen?asset=" + asset._id + "&area=" + asset.venueAreasId}>
          <div className="badge bg-primary font-size-12">View Kitchen</div></Link>
        <div> <Button className="badge bg-success btn-sm" onClick={() => handleViewGroup(asset)}> View Group</Button></div></React.Fragment>,
    },
    {
      text: "CURRENT STATUS",
      dataField: "currentStatus",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent: any, asset: any) => <React.Fragment>

        <div className={asset.currentStatus}> {asset.currentStatus}</div>
      </React.Fragment >,
    },
    // {
    //   text: "STATUS",
    //   dataField: "status",
    //   sort: true,
    //   // eslint-disable-next-line react/display-name
    //   formatter: (cellContent: any, asset: any) => (
    //     <React.Fragment>
    //       {asset.status == 1 ?
    //         <div className="badge bg-success font-size-12">{"Active"}</div>
    //         : <div className="badge bg-warning font-size-12">{"Inactive"}</div>}
    //     </React.Fragment>
    //   ),
    // },
    {
      text: "CREATED DATE",
      dataField: "createDate",
      sort: true,
      // eslint-disable-next-line react/display-name 
      formatter: (cellContent: any, asset: any) => <React.Fragment>{moment(asset.createDate).format('DD MMM Y')}</React.Fragment>,
    },
    {
      text: "ACTION",
      dataField: "action",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent: any, asset: any) => (
        <React.Fragment>
          <UncontrolledDropdown>
            <DropdownToggle tag="button" className="btn btn-light btn-sm">
              <i className="uil uil-ellipsis-h"></i>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-end">
              <DropdownItem to="#" onClick={() => handleEditAssetClick(asset)}>Edit</DropdownItem>
              {/* <DropdownItem to="#" onClick={() => handleDeleteClick(asset)}>{asset.status == 1 ? "Inactive" : "Active"}</DropdownItem> */}
            </DropdownMenu>
          </UncontrolledDropdown>
        </React.Fragment>
      ),
    },
  ];

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteAsset}
        onCloseClick={handleDeleteClose}
        deleteButtonLable={assetEvent.status == 0 ? "Inactive" : "Active"}
      />
      <div className="page-content">
        <MetaTags>
          <title>Assets & Asset Group | Retaurant Booking</title>
        </MetaTags>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <Container fluid>
          {/* Render Breadcrumbs */}
          {/* <Breadcrumbs title="Restaurant" breadcrumbItem="Assets" />
          {assetMsgResponse && assetMsgResponse.message ? (
            <UncontrolledAlert color="primary">
              {assetMsgResponse.message}
            </UncontrolledAlert>
          ) : null} */}
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="_id"
                        data={assetsList}
                        columns={venuesListColumns}
                        bootstrap4
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <Row className="align-items-start">
                              <div className="col-sm">
                                <div>
                                  <Button color="light" onClick={handleAddAssetClicks} className="mb-4 button-success">
                                    <i className="mdi mdi-plus me-1"></i>
                                    Add Asset & Group</Button>
                                </div>
                              </div>
                              <Col xs={3} md={3}>
                                <select className="input-select" onChange={(e) => filterAssetArea(e.target.value)}>
                                  <option value=""> Select Venue </option>
                                  {
                                    venueList.map((venue: any, key: any) => (
                                      <option value={venue._id} key={key}>{venue.name}</option>
                                    ))}
                                </select>
                              </Col>
                              <Col xs={3} md={3}>    <select className="input-select" onChange={(e) => filterAssetAreaList(e.target.value)}>
                                <option value=""> Select Area </option>
                                {
                                  venueArea.map((area: any, key: any) => (
                                    <option value={area._id} key={key}>{area.areaName}</option>
                                  ))}
                              </select>  </Col>
                            </Row>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                    defaultSorted={defaultSorted}
                                    classes={
                                      "table align-middle table-nowrap table-hover"
                                    }
                                    bordered={false}
                                    striped={false}
                                  />
                                  <Modal isOpen={modal} toggle={toggle} size="lg">
                                    <ModalHeader toggle={toggle} tag="h4">
                                      {!!isEdit ? "Edit Asset & Group" : "Add Asset & Group"}
                                    </ModalHeader>
                                    <ModalBody>
                                      <AvForm
                                        onValidSubmit={(
                                          e: any,
                                          values: any
                                        ) => {
                                          handleValidFormSubmit(values);
                                        }}
                                      >
                                        <Row form>
                                          <Row>
                                            <Col xs={6} md={6}>
                                              <AvField
                                                type="select"
                                                name="combinationType"
                                                placeholder="Select Type"
                                                label="Combination Type"
                                                multiple={false}
                                                required
                                                errorMessage="Invalid Combination Type"
                                                onChange={(e: any) => handleSetAssetIsGroup(e.target.value)}
                                                value={assetEvent.combinationType || ""}
                                              >
                                                <option key='blankChoice' hidden value=""> Select Combination Type </option>
                                                <option>Single</option>
                                                <option>Group</option>
                                              </AvField>
                                            </Col>
                                            <Col xs={6} md={6}>
                                              {(!isAssetGroup)
                                                ?
                                                <AvField
                                                  name="assetName"
                                                  label="Asset Name Code"
                                                  type="text"
                                                  placeholder="Enter Valid Asset Name Code"
                                                  errorMessage="Invalid Asset Name Code"
                                                  validate={{
                                                    required: { value: true },
                                                    maxLength: { value: 16, errorMessage: 'Your name must be between 1 and 12 characters' }
                                                  }}
                                                  value={assetEvent.assetName || ""}
                                                />
                                                :
                                                <AvField
                                                  name="assetName"
                                                  label="Group Name"
                                                  type="text"
                                                  placeholder="Enter Valid Group Name"
                                                  errorMessage="Invalid Group Name"
                                                  validate={{
                                                    required: { value: true },
                                                  }}
                                                  value={assetEvent.assetName || ""}
                                                />
                                              }
                                            </Col>

                                            <Col xs={6} md={6}>
                                              <AvField
                                                type="select"
                                                name="assetType"
                                                placeholder="Select Type"
                                                label="Type"
                                                multiple={false}
                                                required
                                                errorMessage="Invalid Asset Type"
                                                value={assetEvent.assetType || ""}
                                              >
                                                <option key='blankChoice' hidden value=""> Select Type </option>
                                                <option>Table</option>
                                                <option>Lounge Chair</option>
                                                <option>Sunbed</option>
                                                <option>VIP Table</option>
                                                <option>Room</option>
                                              </AvField>
                                            </Col>
                                            <Col xs={6} md={6}>
                                              {/* {!!isEdit ? "" :  */}
                                              <div className="mb-3">
                                                <AvField
                                                  type="select"
                                                  name="venueId"
                                                  placeholder="Select Venue"
                                                  label="Venue"
                                                  multiple={false}
                                                  required
                                                  onChange={(e: any) => filterArea(e.target.value)}
                                                  value={assetEvent.venueId || ""}
                                                >
                                                  <option key='blankChoice' hidden value=""> Select Venue </option>
                                                  {
                                                    venueList.map(({ _id, name }: any, key: any) => (
                                                      <option value={_id} key={key}>{name}</option>
                                                    ))}
                                                </AvField>
                                              </div>
                                              {/* } */}
                                            </Col>
                                            <Col xs={6} md={6}>
                                              {/* {!!isEdit ? "" :  */}
                                              <div className="mb-3">
                                                <AvField
                                                  type="select"
                                                  name="venueAreasId"
                                                  placeholder="Select Venue Area"
                                                  label="Venue Area"
                                                  multiple={false}
                                                  onChange={(e: any) => filterAssetAreaList(e.target.value)}
                                                  required
                                                  value={assetEvent.venueAreasId || ""}
                                                >
                                                  <option key='blankChoice' hidden value=""> Select Venue Area </option>
                                                  {
                                                    venueArea.map(({ _id, areaName }: any, key: any) => (
                                                      <option value={_id} key={key}>{areaName}</option>
                                                    ))}
                                                </AvField>
                                              </div>
                                              {/* } */}
                                            </Col>
                                            {!isAssetGroup ? "" :
                                              <Col xs={6} md={6}>
                                                <div className="mb-3">
                                                  <AvField
                                                    type="select"
                                                    name="assetIds"
                                                    placeholder="Select Assets"
                                                    label="Select Assets"
                                                    multiple={true}
                                                    required
                                                    onChange={(e: any) => filterArea(e.target.value)}
                                                    value={assetEvent.assetIds || ""}
                                                  >
                                                    <option key='blankChoice' hidden value=""> Select Assets </option>
                                                    {
                                                      assetsListWithType.map(({ _id, assetName, assetType }: any, key: any) => (
                                                        <option value={_id} key={key}>{assetName + "(" + assetType + ")"}</option>
                                                      ))}
                                                  </AvField>
                                                </div></Col>}
                                            <Col xs={6} md={6}>
                                              <AvField
                                                name="pax"
                                                label="PAX"
                                                type="number"
                                                placeholder="Enter Valid PAX"
                                                errorMessage="Invalid PAX"
                                                validate={{
                                                  required: { value: true }
                                                }}
                                                value={assetEvent.pax || ""}
                                              />
                                            </Col>
                                            <Col xs={6} md={6}>
                                              <AvField
                                                name="price"
                                                label="Price"
                                                type="number"
                                                placeholder="Enter Valid Price"
                                                errorMessage="Invalid Price"
                                                validate={{
                                                  required: (isAssetGroup) ? { value: true } : { value: false },
                                                }}
                                                value={assetEvent.price || ""}
                                              />
                                            </Col>
                                            <Col xs={6} md={6}>
                                              <AvField
                                                name="minSpend"
                                                label="Min Consumption"
                                                type="number"
                                                placeholder="Enter Valid Min Consumption"
                                                errorMessage="Invalid Min Consumption"
                                                validate={{
                                                  required: { value: false },
                                                }}
                                                value={assetEvent.minSpend || 0}
                                              />
                                            </Col>
                                            <Col xs={6} md={6} >
                                              <AvField
                                                type="select"
                                                name="currentStatus"
                                                placeholder="Select Status"
                                                errorMessage="Select valid Status"
                                                label="Status"
                                                multiple={false}
                                                required
                                                value={assetEvent.currentStatus || "Active"}
                                              >
                                                <option key='blankChoice1' hidden value=""> Select Status </option>
                                                <option value="Active" key="Active">Active</option>
                                                <option value="Inactive" key="Inactive">Inactive</option>
                                                <option value="Reservation" key="Reservation">Reservation</option>
                                              </AvField>
                                            </Col>
                                            <Col xs={6} md={6}>
                                              <AvField
                                                name="description"
                                                label="Description"
                                                type="textarea"
                                                placeholder="Enter Valid Description "
                                                errorMessage="Invalid Description "
                                                validate={{
                                                  required: { value: false },
                                                }}
                                                value={assetEvent.description || ""}
                                              />
                                            </Col>
                                            <Col xs={6} md={6}>
                                              <div className="mb-3">
                                                {
                                                  !!isEdit ?
                                                    <AvField
                                                      onChange={handleFileChange}
                                                      name="locationOnFloorPlan"
                                                      label="Location On Floor Plan Image "
                                                      type="file"
                                                      placeholder="Enter Valid category Image "
                                                    />
                                                    :
                                                    <AvField
                                                      onChange={handleFileChange}
                                                      name="locationOnFloorPlan"
                                                      label="Location On Floor Plan Image "
                                                      type="file"
                                                      placeholder="Enter Valid Location On Floor Plan Image "
                                                      errorMessage="Invalid Location On Floor Plan Image "
                                                      validate={{
                                                        required: { value: true },
                                                      }}
                                                    />}
                                              </div>
                                            </Col>
                                          </Row>

                                          <Col xs={12}>
                                            {/* <div className="mb-3">
                                              <AvField
                                                name="locationOnFloorPlan"
                                                label="Location On Floor Plan"
                                                type="text"
                                                placeholder="Enter Valid Location On Floor Plan"
                                                errorMessage="Invalid Location On Floor Plan"
                                                validate={{
                                                  required: { value: true },
                                                }}
                                                value={assetEvent.locationOnFloorPlan || ""}
                                              />
                                            </div> */}

                                          </Col>
                                        </Row>
                                        <Row>
                                          <Col>
                                            <div className="text-end">
                                              <button type="button" className="btn btn-danger btn-light w-sm" onClick={handleShow}>Close</button>
                                              <button
                                                type="submit"
                                                className="btn btn-success save-user"
                                              >
                                                Save
                                              </button>
                                            </div>
                                          </Col>
                                        </Row>
                                      </AvForm>
                                    </ModalBody>
                                  </Modal>

                                  <Modal isOpen={groupModel} toggle={handleShowClose} size="lg">

                                    <ModalHeader toggle={handleShowClose}>Group Info</ModalHeader>
                                    <ModalBody>
                                      <Table responsive>
                                        <thead>
                                          <tr>
                                            <th>
                                              #
                                            </th>
                                            <th>
                                              Asset
                                            </th>
                                            <th>
                                              Type
                                            </th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {/* {
                                          setGroupEvent.map((m: any, key: any) =>(
                                           
                                            <tr  key={key}>
                                              <th scope="row">
                                             {key+1}
                                              </th>
                                              <td>
                                               {m.assetName}
                                              </td>
                                              <td>
                                              {m.assetType}
                                              </td>
                                            </tr>
                                            ))
                                        }            */}
                                        </tbody>
                                      </Table>
                                    </ModalBody>
                                    <ModalFooter>
                                      <Button color="secondary" onClick={handleShowClose}>
                                        Close
                                      </Button>
                                    </ModalFooter>

                                  </Modal>
                                </div>
                              </Col>
                            </Row>
                            <Row className="align-items-md-center mt-30">
                              <Col className="pagination pagination-rounded justify-content-end mb-2">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row >
        </Container >
      </div >
    </React.Fragment >
  );
};

export default Assets;
