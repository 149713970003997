import { createSlice } from "@reduxjs/toolkit";
import {
    findVenueDomains
  } from "./thunk";

interface LoginStateType {
    users: Array<any>,
    venueInfo: object,
    userProfile: any,
    error?: string | object | null | undefined | unknown,
    user?: any,
    history?: any,
    statusCode?:number
}
export const initialState: LoginStateType = {
    users: [],
    userProfile: {},
    venueInfo:{},
    error: '',
    user: '',
    history: '',
    statusCode:400
};

const loginSlice = createSlice({
    name: "login",
    initialState,
    reducers: {
        apiError(state, action) {
            state.error = action.payload
        },
        loginSuccess(state, action) {
            state.user = action.payload
        },
        logoutUserSuccess(state, action) {
            state.history = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase(findVenueDomains.fulfilled, (state: any, action: any) => {
          state.venueInfo = action.payload.data;
          state.statusCode = action.payload.statusCode
        })

        builder.addCase(findVenueDomains.rejected, (state, action) => {
            state.error = action.payload || null;
          })
      },
});

export const {
    apiError,
    loginSuccess,
    logoutUserSuccess
} = loginSlice.actions

export default loginSlice.reducer;