import { createSlice } from "@reduxjs/toolkit";

import {
  getCountryList,
  getStateList,
  getCityList 
} from "./thunk";

interface locationStateType {
    countryList: Array<any>,
    stateList: Array<any>,
    cityList: Array<any>,
    venueMsgResponse: object,
    error?: string | object | null | undefined | unknown
}

export const initialState: locationStateType = {
    countryList: [],
    stateList: [],
    cityList: [],
    error: {},
    venueMsgResponse: {}
};

const locationsSlice = createSlice({
    name: "locations",
    initialState,
    reducers: {},
    extraReducers: (builder) => {

      // Country list reducer
      builder.addCase(getCountryList.fulfilled, (state: any, action: any) => {
        state.countryList = action.payload.data;
      })
      // if rejected
      builder.addCase(getCountryList.rejected, (state, action) => {
        state.error = action.payload || null;
      })
      
      // State list reducer
      builder.addCase(getStateList.fulfilled, (state: any, action: any) => {
        state.stateList = action.payload.data;
      })
      // if rejected
      builder.addCase(getStateList.rejected, (state, action) => {
        state.error = action.payload || null;
      })

      // City list reducer
      builder.addCase(getCityList.fulfilled, (state: any, action: any) => {
        state.cityList = action.payload.data;
      })
      // if rejected
      builder.addCase(getCityList.rejected, (state, action) => {
        state.error = action.payload || null;
      })
    },
  });
  
  export default locationsSlice.reducer;