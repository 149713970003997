import React from "react";
import MetaTags from "react-meta-tags";
import { Link } from "react-router-dom";
import { Card, CardBody, Col, Container, Row } from "reactstrap";


interface OrderDetailModalProps {
  orderDetail: {
    order_details: [],
    totalIteam: 0,
    totalAmount: 0,
    discount: 0,
    tax: 0,
    subTotalAmount: 0
  }
}

const OrderDetail = ({
  orderDetail
}: OrderDetailModalProps) => {



  return (
    <React.Fragment>
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody>
              <div className="py-2">
                {/* <h5 className="font-size-15">Order Summary</h5>
                <hr className="my-4" /> */}
                <div className="table-responsive">
                  <table className="table align-middle table-nowrap table-centered mb-0">
                    <thead>
                      <tr>
                        <th style={{ width: "70px" }}>No.</th>
                        <th>Item</th>
                        <th>Price</th>
                        <th>Quantity</th>
                        <th className="text-end" style={{ width: "120px" }}>Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        orderDetail.order_details.map(({ _id, menuId, description, menuInfo, quantity, price, totalPrice }: any, key: any) => (
                          <tr key={key}>
                            <th scope="row">{key}</th>
                            <td>
                              <div>
                                <h5 className="text-truncate font-size-14 mb-1">{menuInfo.name.en||"NA"}</h5>
                                <p className="text-muted mb-0">{description}</p>
                              </div>
                            </td>
                            <td>&#8364; {price}</td>
                            <td>{quantity}</td>
                            <td className="text-end">&#8364; {totalPrice}</td>
                          </tr>

                        ))}



                      <tr>
                        <th scope="row" colSpan={4} className="text-end">{orderDetail.totalIteam} Items</th>
                        <td className="text-end">&#8364; {Math.floor(orderDetail.subTotalAmount)}</td>
                      </tr>

                      <tr>
                        <th scope="row" colSpan={4} className="border-0 text-end">
                          Discount : </th>
                        <td className="border-0 text-end">- &#8364; {Math.floor(orderDetail.discount)}</td>
                      </tr>
                      <tr>
                        <th scope="row" colSpan={4} className="border-0 text-end">
                          Tax</th>
                        <td className="border-0 text-end">&#8364; {Math.floor(orderDetail.tax)}</td>
                      </tr>

                      <tr>
                        <th scope="row" colSpan={4} className="border-0 text-end">Total</th>
                        <td className="border-0 text-end"><h4 className="m-0 fw-semibold">&#8364; {Math.floor(orderDetail.totalAmount)}</h4></td>
                      </tr>

                    </tbody>
                  </table>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default OrderDetail;