import { createSlice } from "@reduxjs/toolkit";

import {
  getSubCategoriesType,
  addNewSubCategoryType,
  updateSubCategoryType,
  resetApiSubCategoryTypeResponseFlag,
  updateSubCategoryTypeStatus
} from "./thunk";

interface SubCategoriesStateType {
  subCategories: Array<any>,
  catMsgResponse: object,
  error?: string | object | null | undefined | unknown,
  subCategoriesUpdate: any

}
export const initialState: SubCategoriesStateType = {
  subCategories: [],
  error: {},
  catMsgResponse: {},
  subCategoriesUpdate: {}
};

const subCategoriesSlice = createSlice({
  name: "subCategories",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(addNewSubCategoryType.fulfilled, (state: any, action: any) => {
      state.subCategories.push(action.payload.data)
      state.catMsgResponse = {
        message: action.payload ? action.payload.message : 'New Category Added Successfully',
        type: action.payload ? 'success' : 'error',
      };

    })
    builder.addCase(addNewSubCategoryType.rejected, (state, action) => {
      state.error = action.payload || null;
    })
    builder.addCase(getSubCategoriesType.fulfilled, (state: any, action: any) => {
      state.subCategories = action.payload.data;
    })
    builder.addCase(getSubCategoriesType.rejected, (state, action) => {
      state.error = action.payload || null;
    })
    builder.addCase(updateSubCategoryType.fulfilled, (state: any, action: any) => {
      state.subCategoriesUpdate = action.payload;
      state.catMsgResponse = {
        message: action.payload ? action.payload.message : 'Category Updated Successfully',
        type: action.payload ? 'success' : 'error',
      };
    })
    builder.addCase(updateSubCategoryType.rejected, (state, action) => {
      state.error = action.payload || null;
    })
    builder.addCase(resetApiSubCategoryTypeResponseFlag.fulfilled, (state, action) => {
      state.catMsgResponse = { message: "" };
    })
    builder.addCase(updateSubCategoryTypeStatus.fulfilled, (state: any, action: any) => {
      state.itemAddUpdate = action.payload;
      state.itemMsgResponse = {
        message:action.payload ? action.payload.message : 'Category Status Updated Successfully',
        type: action.payload ? 'success' : 'error',
      };
    })
    builder.addCase(updateSubCategoryTypeStatus.rejected, (state, action) => {
      state.error = action.payload || null;
    })

  },
});

export default subCategoriesSlice.reducer;