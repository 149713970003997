import { createSlice } from "@reduxjs/toolkit";
import {
  addNewStaff,
  getStaffList,
  getModuleList,
  resetStaffApiResponseFlag,
  updateStaffStatus,
  updateStaff,
  updateStaffPermission
} from "./thunk";
import { logoutUser } from "../../slices/thunks";
interface LogoutProps {
  history: any;
}
interface StaffStateType {
  staffList: Array<any>,
  moduleList: Array<any>,
  staffMsgResponse: object,
  error?: string | object | null | undefined | unknown,
  statusCode?: string | object | null | undefined | unknown | number,
  staffAddUpdate: any
}
export const initialState:StaffStateType = {
  staffList: [],
  moduleList: [],
  error: {},
  staffMsgResponse: {},
  staffAddUpdate: {}
};

const staffSlice = createSlice({
  name: "staff",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(addNewStaff.fulfilled, (state: any, action: any) => {
      state.staffAddUpdate = action.payload;
      state.venueMsgResponse = {
        message:action.payload ? action.payload.message : 'New Staff Added Successfully',
        type: action.payload ? 'success' : 'error',
      };
    })
    builder.addCase(addNewStaff.rejected, (state, action) => {
      state.error = action.payload || null;
    })

    builder.addCase(getStaffList.fulfilled, (state: any, action: any) => {
        state.staffList = action.payload.data;
        state.statusCode = action.payload.statusCode;
    })
    builder.addCase(getStaffList.rejected, (state, action) => {
      state.error = action.payload || null;
    })

    builder.addCase(getModuleList.fulfilled, (state: any, action: any) => {
      state.moduleList = action.payload.data;
      state.statusCode = action.payload.statusCode;
    })
    builder.addCase(getModuleList.rejected, (state, action) => {
      state.error = action.payload || null;
    })

    builder.addCase(resetStaffApiResponseFlag.fulfilled, (state, action) => {
      state.staffMsgResponse = { message: "" };
    })

    builder.addCase(updateStaffStatus.fulfilled, (state: any, action: any) => {
      //state.staffAddUpdate = action.payload;
      
      state.venueMsgResponse = {
        message:action.payload ? action.payload.message : 'Staff Status Updated Successfully',
        type: action.payload ? 'success' : 'error',
      };
    })
    builder.addCase(updateStaffStatus.rejected, (state, action) => {
      state.error = action.payload || null;
    })

    builder.addCase(updateStaff.fulfilled, (state: any, action: any) => {
      state.venueAddUpdate = action.payload;
      state.venueMsgResponse = {
        message:action.payload ? action.payload.message : 'Staff Updated Successfully',
        type: action.payload ? 'success' : 'error',
      };
    })
    builder.addCase(updateStaff.rejected, (state, action) => {
      state.error = action.payload || null;
    })

    builder.addCase(updateStaffPermission.fulfilled, (state: any, action: any) => {
      state.venueAddUpdate = action.payload;
      state.venueMsgResponse = {
        message:action.payload ? action.payload.message : 'Staff Updated Successfully',
        type: action.payload ? 'success' : 'error',
      };
    })
    builder.addCase(updateStaffPermission.rejected, (state, action) => {
      state.error = action.payload || null;
    })
  },
});

export default staffSlice.reducer;