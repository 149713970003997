import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  getOrderList as getOrderListApi,
  getVenueKds as getVenueKdsApi,
  getTodayOrderList as getTodayOrderListApi,
  getDashboardReport as getDashboardReportApi,
  getAllOrderList as getAllOrderListApi,
  updateOrderStatus as updateOrderStatusApi,
  updateSetOrderItemStatusData as updateSetOrderItemStatusApi
} from "../../helpers/backend_helper";

export const updateSetOrderStatus = createAsyncThunk(
  "orders/updateStateOrderList",
  async (event: any) => {
    try {
      const response = updateOrderStatusApi(event.orderId,event.status);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const updateSetOrderItemStatus = createAsyncThunk(
  "orders/updateSetOrderItemStatus",
  async (event: any) => {
    try {
      const response = updateSetOrderItemStatusApi(event);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getOrderList = createAsyncThunk(
  "orders/getOrderList",
  async (event: any) => {
    try {
      const response = getOrderListApi(event);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getVenuekds = createAsyncThunk(
  "orders/getVenuekds",
  async (event: any) => {
    try {
      const response = getVenueKdsApi(event);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getTodayOrderList = createAsyncThunk(
  "orders/getTodayOrderList",
  async () => {
    try {
      const response = getTodayOrderListApi();
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getAllOrderList = createAsyncThunk(
  "orders/getAllOrderList",
  async (event: any) => {
    try {
      const response = getAllOrderListApi(event);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getDashboardReport = createAsyncThunk(
  "orders/getDashboardReport",
  async () => {
    try {
      const response = getDashboardReportApi();
      return response;
    } catch (error) {
      return error;
    }
  }
);


