import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  getMenuArea as getMenuAreaApi,
  getMenuAreaDetail as getMenuAreaDetailApi,
  addNewMenuArea as addNewMenuAreaApi,
  updateMenuArea as updateMenuAreaApi,
  deleteMenuArea as deleteMenuAreaApi,
  getMenuList as onGetMenuList
} from "../../helpers/backend_helper";

// Get Menu Area
export const getMenuArea = createAsyncThunk(
  "menuAreas/getMenuArea",
  async (event: any) => {
    try {
      const response = getMenuAreaApi(event);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getMenuListData = createAsyncThunk(
  "items/getItemList",
  async (event: any) => {
    try {
      const response = onGetMenuList(event);
      return response;
    } catch (error) {
      return error;
    }
  }
);


// get Menu Area Detail
export const getMenuAreaDetail = createAsyncThunk(
  "menuAreas/getMenuAreaDetail",
  async (event: any) => {
    try {
      const response = getMenuAreaDetailApi(event);
      return response;
    } catch (error) {
      return error;
    }
  }
);

// Delete Menu Area
export const deleteMenuArea = createAsyncThunk(
  "menuAreas/deleteMenuArea",
  async (event: any) => {
    try {
      const response = deleteMenuAreaApi(event);
      const modifiedEvent = await response;
      return modifiedEvent;
    } catch (error) {
      return error;
    }
  }
);

// Add Menu Area
export const addNewMenuArea = createAsyncThunk(
  "menuAreas/addNewMenuArea",
  async (event: any) => {
    try {
      const response = addNewMenuAreaApi(event);
      return response;
    } catch (error) {
      return error;
    }
  }
);

// Update Menu Area
export const updateMenuArea = createAsyncThunk(
  "menuAreas/updateMenuArea",
  async (event: any) => {
    try {
      const response = updateMenuAreaApi(event);
      const modifiedEvent = await response;
      return modifiedEvent;
    } catch (error) {
      return error;
    }
  }
);

// Reset Menu Area Flag
export const resetMenuAreaApiResponseFlag = createAsyncThunk(
  "menuAreas/resetMenuAreaApiResponseFlag",
  async () => {
    try {
      return true;
    } catch (error) {
      return error;
    }
  }
);