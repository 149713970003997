import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  Button,
  Modal
} from "reactstrap";

import Breadcrumbs from "../../components/Common/Breadcrumb";

import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";

//redux
import { useSelector, useDispatch } from "react-redux";


import {
  getOrderList as onGetOrderList,
  getVenueList as onGetVenueList,
  getVenueArea as onGetVenueArea,
  getAssetsList as onGetAssetsList,
} from "../../slices/thunks";
import moment from "moment";
import Icon from "@ailibs/feather-react-ts/dist/Icon";
import OrderDetail from "./order-details";

const OrderList = () => {
  const dispatch = useDispatch();

  const { orderList, venueList, venueArea, assetsList } = useSelector((state: any) => ({
    orderList: state.orders.orderList,
    venueList: state.venues.venueList,
    venueArea: state.areas.venueArea,
    assetsList: state.assets.assetsList,
  }));
  const [modal_xlarge, setmodal_xlarge] = useState(false);
  const [orderDetail, setOrderDetail] = useState<any>({});

  useEffect(() => {
    dispatch(onGetOrderList({}));
    dispatch(onGetVenueList());
  }, [dispatch]);

  const pageOptions = {
    sizePerPage: 10,
    totalSize: orderList.length, // replace later with size(users),
    custom: true,
  };

  const defaultSorted: any = [
    {
      dataField: "id", // if dataField is not match to any column you defined, it will be ignored.
      order: "asc", // desc or asc
    },
  ];
  function tog_xlarge() {
    setmodal_xlarge(!modal_xlarge);
  }
  const handleViewClick = (event: any) => {
    setOrderDetail(event||{});
    setmodal_xlarge(!modal_xlarge);
    // toggle();
  };

  const filterAssetArea = (venueId: string) => {
    dispatch(onGetVenueArea({ id: venueId }));
  };

  const filterAssetAreaList = (venueAreasId: string) => {
    dispatch(onGetAssetsList({ venueAreasId: venueAreasId }));
  };

  const filterGetOrder = (assetId: string) => {
    dispatch(onGetOrderList({ assetId: assetId }));
  };

  const ordersListColumns = [
    {
      text: "ASSET",
      dataField: "assetName",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent: any, order: any) => <React.Fragment>{order.assetInfo.assetName}</React.Fragment>,
    },
    {
      text: "ORDER ID",
      dataField: "orderNumber",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent: any, order: any) => <React.Fragment>{order.orderNumber || "-"}</React.Fragment>,
    },
    {
      text: "START TIME",
      dataField: "startTime",
      sort: true,
      // eslint-disable-next-line react/display-name 
      formatter: (cellContent: any, order: any) => <React.Fragment>{moment(order.startTime).format('DD MMM Y hh:mm A')}</React.Fragment>,
    },
    {
      text: "END TIME",
      dataField: "endTime",
      sort: true,
      // eslint-disable-next-line react/display-name 
      formatter: (cellContent: any, order: any) => <React.Fragment>{moment(order.endTime).format('DD MMM Y hh:mm A')}</React.Fragment>,
    },
    {
      text: "STATUS",
      dataField: "status",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent: any, order: any) => (
        <React.Fragment>
          {order.status == "New Order" ?
            <div className="badge bg-info font-size-12">{order.status}</div>
            : order.status == "Done" ? 
            <div className="badge bg-success font-size-12">{order.status}</div> : 
            order.status == "Preparing" ? <div className="badge bg-warning font-size-12">{order.status}</div> : <div className="badge bg-danger font-size-12">{order.status}</div> }
        </React.Fragment>
      ),
    },
    // {
    //   text: "PAYMENT TYPE",
    //   dataField: "endTime",
    //   sort: true,
    //   // eslint-disable-next-line react/display-name 
    //   formatter: (cellContent: any, order: any) => <React.Fragment>{moment(order.endTime).format('DD MMM Y hh:mm A')}</React.Fragment>,
    // },
    {
      text: "TOTAL AMOUNT",
      dataField: "totalAmount",
      sort: true,
      // eslint-disable-next-line react/display-name 
      formatter: (cellContent: any, order: any) => <React.Fragment>&#8364; {order.totalAmount.toFixed(2)}</React.Fragment>,
    },
    {
      text: "ORDER DATE",
      dataField: "orderDate",
      sort: true,
      // eslint-disable-next-line react/display-name 
      formatter: (cellContent: any, order: any) => <React.Fragment>{moment(order.orderDate).format('DD MMM Y')}</React.Fragment>,
    },
    {
      text: "ACTION",
      dataField: "action",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent: any, order: any) => (
        <React.Fragment>
          <Button
            type="button"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="View Order Detail"
            id="TooltipTop" color="info"
            onClick={() => handleViewClick(order)}><i className="uil uil-exclamation-octagon"></i></Button>

        </React.Fragment>
      ),
    },

  ];

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Orders | Restaurant Booking</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Restaurant" breadcrumbItem="Orders" />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="_id"
                        data={orderList}
                        columns={ordersListColumns}
                        bootstrap4
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <Row>
                            <Col xs={3} md={3}>   
                                  <select className="input-select" onChange={(e) => filterAssetArea(e.target.value)}>
                                    <option value=""> Select Venue </option>
                                    {
                                      venueList.map((venue: any, key: any) => (
                                        <option value={venue._id} key={key}>{venue.name}</option>
                                      ))}
                                  </select>
                               </Col>
                               <Col xs={3} md={3}>    <select className="input-select"onChange={(e) => filterAssetAreaList(e.target.value)}>
                                    <option value=""> Select Area </option>
                                    {
                                      venueArea.map((area: any, key: any) => (
                                        <option value={area._id} key={key}>{area.areaName}</option>
                                      ))}
                                  </select>  </Col>
                                  <Col xs={3} md={3}>    <select className="input-select"onChange={(e) => filterGetOrder(e.target.value)}>
                                    <option value=""> Select Asset </option>
                                    {
                                      assetsList.map((area: any, key: any) => (
                                        <option value={area._id} key={key}>{area.assetName}</option>
                                      ))}
                                  </select>  </Col>
                            </Row>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                    defaultSorted={defaultSorted}
                                    classes={
                                      "table align-middle table-nowrap table-hover"
                                    }
                                    bordered={false}
                                    striped={false}
                                  />
                                </div>
                                <Modal
                                  isOpen={modal_xlarge}
                                  size="xl"
                                  toggle={() => {
                                    tog_xlarge();
                                  }}
                                  backdrop={"static"}
                                  id="staticBackdrop"
                                >
                                  <div className="modal-header">
                                    <h5 className="modal-title" id="staticBackdropLabel">
                                      Order Summary  ({orderDetail.totalIteam||0} Items)
                                      
                                    </h5>
                                    <button
                                      type="button"
                                      className="btn-close"
                                      onClick={() => {
                                        setmodal_xlarge(false);
                                      }}
                                      aria-label="Close"
                                    ></button>
                                  </div>
                                  <div className="modal-body">
                                    <OrderDetail orderDetail={orderDetail}/>
                                  </div>
                                  <div className="modal-footer">
                                    <button
                                      type="button"
                                      className="btn btn-light"
                                      onClick={() => {
                                        setmodal_xlarge(false);
                                      }}
                                    >
                                      Close
                                    </button>
                                  </div>
                                </Modal>
                              </Col>
                            </Row>
                            <Row className="align-items-md-center mt-30">
                              <Col className="pagination pagination-rounded justify-content-end mb-2">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment >
  );
};

export default OrderList;
