import MetaTags from "react-meta-tags";
import React, { useEffect, useState, ChangeEvent } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Row, Col, Alert, Container } from "reactstrap";
//redux
import { useSelector, useDispatch } from "react-redux";
import { withRouter, Link } from "react-router-dom";
// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";
// actions
import { loginUser, findVenueDomains } from "../../slices/thunks";
//import images
import logoDark from "../../assets/images/logo_2.png";
import logoLight from "../../assets/images/logo_2.png";
interface LoginProps {
  history: any;
}
const VenueLogin = ({ history }: LoginProps) => {
  const dispatch = useDispatch();

  const { error, venueInfo, statusCode } = useSelector((state: any) => ({
    error: (state.login.error) ? "Email & password does not match" : "",
    venueInfo: state.login.venueInfo,
    statusCode: state.login.statusCode
  }));

  // handleValidSubmit
  const handleValidSubmit = (event: any, values: any) => {
    dispatch(loginUser(values, history));
  };

  useEffect(() => {
    let domain : string = window.location.hostname
    dispatch(findVenueDomains(domain));
  }, [dispatch, history]);

  return (statusCode == 200)

  ?
   (
    <React.Fragment>
      <MetaTags>
        <title>Login | Restaurant Booking</title>
      </MetaTags>
      <div className="authentication-bg min-vh-100">
        {/* <div className="bg-overlay bg-white"></div> */}
        <Container>
        <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
          <div className="d-flex flex-column min-vh-100 px-3 pt-4">
            <Row className="justify-content-center my-auto">
              <Col md={8} lg={6} xl={4}>
  
                <div className="py-5">
                  <div className="mb-4 mb-md-5 text-center">
                    <Link to="/venue-login" className="d-block auth-logo">
                      <img src={venueInfo.floorPlanImage} alt="" height="100" className="auth-logo-dark" />
                      <img src={venueInfo.floorPlanImage} alt="" height="100" className="auth-logo-light" />
                    </Link>
                  </div>
                  <div className="text-center mb-4">
                    <h5>Welcome Back ! <span className="text-success"> {venueInfo.name}</span></h5>
                    <p> Venue Sign in to continue to Dashboard.</p>
                  </div>
                  <AvForm
                    onValidSubmit={(e: any, v: any) => {
                      handleValidSubmit(e, v);
                    }}
                  >
                    {error ? <Alert color="danger">{error}</Alert> : null}
                    <div className="form-floating form-floating-custom mb-3">
                      <AvField
                        name="email"
                        label="Email"
                        value=""
                        className="form-control"
                        placeholder="Enter email"
                        type="email"
                        required
                      />
                    </div>
                    <div className="form-floating form-floating-custom mb-3">
                      <AvField
                        name="password"
                        label="password"
                        value=""
                        type="password"
                        className="form-control"
                        required
                        placeholder="Enter Password"
                      />
                    </div>
                    {/* <div className="form-check form-check-info font-size-16">
                      <input className="form-check-input" type="checkbox" id="remember-check" />
                      <label className="form-check-label font-size-14" htmlFor="remember-check">
                        Remember me
                      </label>
                    </div> */}

                    <div className="mt-3">
                      <button className="btn btn-success w-100" type="submit">{venueInfo.name} Log In</button>
                    </div>

                    {/* <div className="mt-4 text-center"><Link className="text-muted" to="/forgot-password"><i className="mdi mdi-lock me-1"></i> Forgot your password?</Link></div> */}

                  </AvForm>

                  {/* <div className="mt-5 text-center text-muted">
                    <p>Don&apos;t have an account ? <Link to="/register" className="fw-medium text-decoration-underline"> Signup </Link></p>
                  </div> */}
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div></React.Fragment>
  ):
   (
    <React.Fragment>
      <MetaTags>
        <title>Login | Reservation</title>
      </MetaTags>
      <div className="authentication-bg min-vh-100">
        {/* <div className="bg-overlay bg-white"></div> */}
        <Container>
        <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
          <div className="d-flex flex-column min-vh-100 px-3 pt-4">
            <Row className="justify-content-center my-auto">
              <Col md={8} lg={8} xl={4}>
  
                <div className="py-8">
                  <div className="mb-4 mb-md-5 text-center">
                    <Link to="/venue-login" className="d-block auth-logo">
                      <img src={logoLight} alt="" height="100" className="auth-logo-dark" />
                      <img src={logoLight} alt="" height="100" className="auth-logo-light" />
                    </Link>
                  </div>
                  <div className="text-center mb-8">
                    <h5>Welcome Back ! <span className="text-success"> Venue</span></h5>
                    <div className="text-danger"> Venue not found on the domain, please contact to admin.</div>
                  </div>

                  {/* <div className="mt-5 text-center text-muted">
                    <p>Don&apos;t have an account ? <Link to="/register" className="fw-medium text-decoration-underline"> Signup </Link></p>
                  </div> */}
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div></React.Fragment>
  );

  ""


};

export default withRouter(VenueLogin);