import React, { useState, useEffect } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

//i18n
import { withTranslation } from "react-i18next";
// Redux
import { withRouter } from "react-router-dom";


const ProfileMenu = (props: any) => {

  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState<boolean>(false);
  const [username, setusername] = useState();
  const [email, setemail] = useState();
  const [profileImage, setProfileImage] = useState();
  useEffect(() => {
    const getAuthUser = localStorage.getItem("authUser");
    if (getAuthUser) {
      const obj = JSON.parse(getAuthUser);
      if (obj.name) {
        setusername(obj.name);
        setemail(obj.email);
        setProfileImage(obj.profileImage)
      } else {
        setusername(obj.name);
      }
    }
  }, []);

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item user text-start d-flex align-items-center"
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            className="rounded-circle header-profile-user"
            src={profileImage}
            alt={username}
          />
          <span className="ms-2 d-none d-sm-block user-item-desc">
            <span className="user-name">{username}</span>
            <span className="user-sub-title">{email}</span>
          </span>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end pt-0" dir="left">
          <DropdownItem tag="a" href="/logout">
            <i className="mdi mdi-logout text-muted font-size-16 align-middle me-1"></i> <span className="align-middle">{props.t("Logout")}</span>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default withTranslation()(withRouter(ProfileMenu));
