import { createSlice } from "@reduxjs/toolkit";

import {
    addVenueHardware,
    getVenueHardware,
    deleteVenueHardwareLocation,
    getVenueHardwareDetail,
    updateVenueHardwareLocation,
    resetHardwareApiResponseFlag
} from './thunk'

interface HardwareStateType{
    hardwareList : Array<any>,
    hardwareMsgResponse : object,
    error?: string | object | null | undefined | unknown,
    hardwareAddUpdate : any
}

export const initialState : HardwareStateType = {
    hardwareList : [],
    error: {},
    hardwareAddUpdate : {},
    hardwareMsgResponse : {},
};

const hardwareSlice = createSlice({
    name : "hardwares",
    initialState,
    reducers: {},
    extraReducers : (builder) =>{
        builder.addCase(addVenueHardware.fulfilled,(state : any, action: any)=>{
            state.hardwareAddUpdate = action.payload;
            state.hardwareMsgResponse = {
                message : action.payload.message,
                type : "success"
            };
        })

        builder.addCase(addVenueHardware.rejected, (state,action)=>{
            state.error = action.payload || null;
        })

        builder.addCase(getVenueHardware.fulfilled, (state: any,action:any)=>{
            state.hardwareList = action.payload.data;
        })

        builder.addCase(getVenueHardware.rejected, (state,action)=>{
            state.error = action.payload || null;
        })

        builder.addCase(resetHardwareApiResponseFlag.fulfilled, (state,action)=>{
            state.hardwareMsgResponse = {message : ""};
        })

        builder.addCase(updateVenueHardwareLocation.fulfilled,(state : any, action : any)=>{
            state.hardwareAddUpdate = action.payload;
            state.hardwareMsgResponse = {
                message : action.payload.message,
                type : "success"
            };
        })

        builder.addCase(updateVenueHardwareLocation.rejected, (state,action)=>{
            state.error = action.payload || null;
        })

        builder.addCase(getVenueHardwareDetail.fulfilled, (state: any,action : any)=>{
            state.hardwareList = action.payload.data;
        })

        builder.addCase(getVenueHardwareDetail.rejected, (state,action)=>{
            state.error = action.payload || null;
        })

        builder.addCase(deleteVenueHardwareLocation.fulfilled, (state: any, action: any)=>{
            state.hardwareAddUpdate = action.payload;
            state.hardwareMsgResponse = {
                message : action.payload.message,
                type : "success"
            };
        })

        builder.addCase(deleteVenueHardwareLocation.rejected, (state,action)=>{
            state.error = action.payload || null;
        })
    },

});

export default hardwareSlice.reducer;