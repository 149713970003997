// Login
export const LOGIN_URL = "/login"

//  Category
export const REST_MENU_CATEGORIES = "/admin/item-category"

//  Category Type
export const REST_MENU_CATEGORIES_TYPE = "/admin/item-category"

//  Venues
export const REST_VENUE = "/admin/venue"

//  Notification
export const REST_NOTIFICATION = "/admin/notification"

//  staff
export const REST_STAFF = "/admin/auth-admin"

//  Areas
export const REST_AREA = "/admin/venue-area"

//Assets
export const REST_ASSET = "/admin/asset"

// Menu Items
export const REST_MENU_ITEM = "/admin/menu";

// Orders
export const REST_ORDER = "/reservation"

// Update Order Status
export const UPDATE_ORDER = "/reservation/update-order"

// Update Order Menu Status
export const UPDATE_ORDER_ITEM = "/reservation/update-order-item"

// Location
export const REST_LOCATION = "/location"

// Hardware
export const REST_HARDWARE = "/admin/venue-hardware"

// Hardware Location 
export const REST_HARDWARE_LOCATION = "/admin/venue-hardware"

// Hardware Location 
export const REST_MENU_AREA = "/admin/menu-area"
