import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import {
  Col,
  Container,
  Row,
  CardBody,
  Card,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Pagination,
  PaginationLink,
  PaginationItem
} from "reactstrap";
//redux
import { useSelector, useDispatch } from "react-redux";
import classnames from "classnames";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
//Import Cards
import CardProject from "./order-detail-card";
import { getTodayOrderList as onGetTodayOrderList } from "../../slices/thunks";
const Home = () => {
  const dispatch = useDispatch();
  const { todayOrderList } = useSelector((state: any) => ({
    todayOrderList: state.orders.todayOrderList,
  }));

  // console.log("todayOrderList===",todayOrderList)
  const [activeTab, setactiveTab] = useState<string>("1");
  const [orderType, setOrderType] = useState<any>("all");

  const toggleTab = (tab: any) => {
    if (activeTab !== tab) {
      setactiveTab(tab);
    }
  };

  useEffect(() => {
    dispatch(onGetTodayOrderList());
  }, [dispatch]);

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>
            Today&apos;s Orders | Restaurant Booking
          </title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Dashboard" breadcrumbItem="Today's Orders" />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <div className="mt-2">
                    <Nav tabs className="nav-tabs-custom mb-4">
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: activeTab === "1",
                          })}
                          onClick={() => {
                            toggleTab("1");
                            setOrderType("all");
                          }}
                        >
                          All
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: activeTab === "2",
                          })}
                          onClick={() => {
                            toggleTab("2");
                            setOrderType("New Order");
                          }}
                        >
                          New Order
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: activeTab === "3",
                          })}
                          onClick={() => {
                            toggleTab("3");
                            setOrderType("Preparing");
                          }}
                        >
                          Preparing
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: activeTab === "4",
                          })}
                          onClick={() => {
                            toggleTab("4");
                            setOrderType("Done");
                          }}
                        >
                          Done
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </div>

                  <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                      <div>
                        <div>
                          {/* Import Cards */}
                          {todayOrderList.length?
                           <CardProject
                           todayOrderList={todayOrderList}
                           orderType={orderType}
                         />:"No Today Order Found..."}
                         
                        </div>
                        {/* <Paginations /> */}
                      </div>
                    </TabPane>
                    <TabPane tabId="2">
                      <div>
                        <div>
                          {/* Import Cards */}
                          {todayOrderList.length?
                           <CardProject
                           todayOrderList={todayOrderList}
                           orderType={orderType}
                         />:"No Today Order Found..."}
                         
                        </div>
                        {/* <Paginations /> */}
                      </div>
                    </TabPane>
                    <TabPane tabId="3">
                      <div>
                        <div>
                          {/* Import Cards */}
                          {todayOrderList.length?
                           <CardProject
                           todayOrderList={todayOrderList}
                           orderType={orderType}
                         />:"No Today Order Found..."}
                         
                        </div>
                        {/* <Paginations /> */}
                      </div>
                    </TabPane>
                    <TabPane tabId="4">
                      <div>
                        <div>
                          {/* Import Cards */}
                          {todayOrderList.length?
                           <CardProject
                           todayOrderList={todayOrderList}
                           orderType={orderType}
                         />:"No Today Order Found..."}
                         
                        </div>
                        {/* <Paginations /> */}
                      </div>
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Home;
