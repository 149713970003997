import React, { useEffect, useState, ChangeEvent } from "react";
import MetaTags from "react-meta-tags";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  UncontrolledAlert,
  ModalFooter
} from "reactstrap";
import Icon from "@ailibs/feather-react-ts/dist/Icon";
import { AvForm, AvField } from "availity-reactstrap-validation";
import {
  getMenuListData as OnGetMenuListData,
  getMenuArea as onGetMenuArea,
  getMenuAreaDetail as onGetMenuAreaDetail,
  addNewMenuArea as onAddNewMenuArea,
  updateMenuArea as onUpdateMenuArea,
  deleteMenuArea as onDeleteMenuArea,
  resetMenuAreaApiResponseFlag as onResetMenuAreaApiResponseFlag,
  getAssetsList as onGetAssetsList,
  getVenueList as onGetVenueList,
  getVenueArea as onGetVenueArea,
  addNewAsset as onAddNewAsset,
  updateAssetStatus as onUpdateAssetStatus,
  updateAsset as onUpdateAsset,
  resetAssetApiResponseFlag as onResetApiResponseFlag,
  getMenuCategories as onGetgetCategories,
  getVenueHardware as onGetHardware,
  getItemList as onGetitemList,
} from "../../slices/thunks";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import { isEmpty } from "lodash";

import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";


import DeleteModal from "../Common/DeleteModal";
//redux
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";

const MenuArea = () => {
  const dispatch = useDispatch();

  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [menuAreaEvent, setMenuAreaEvent] = useState<any>({});
  const [modal, setModal] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [venueClass, setVenueClass] = useState<any>({});
  const [venueAreaClass, setVenueAreaClass] = useState<any>({});
  const [categoryClass, setCategoryClass] = useState<any>({});
  const [file, setFile] = useState<File>();
  const [videoUrl, setVideoUrl] = useState<any>({});
  const [isOpen, setOpen] = useState(false);
  const [isVenueId, setVenueId] = useState('');
  const handleVideoModel = () => setOpen(false);
  const [multipleItemModal, setMultipleItemModal] = useState<boolean>(false);
  // const formData = new FormData();
  const handleShow = () => setModal(false);
  const handleMultipleItemModal = () => setMultipleItemModal(false);
  const { menuArea, menuAreaDetail, menuAreaMsgResponse, menuAreaAddUpdate, venueList, assetsList, assetMsgResponse, assetAddUpdate, venueArea, categories, itemList, hardwareList } = useSelector((state: any) => ({
    menuArea: state.menuAreas.menuArea,
    menuAreaDetail: state.menuAreas.menuAreaDetail,
    menuAreaMsgResponse: state.menuAreas.menuAreaMsgResponse,
    menuAreaAddUpdate: state.menuAreas.menuAreaAddUpdate,
    venueList: state.venues.venueList,
    venueArea: state.areas.venueArea,
    assetsList: state.assets.assetsList,
    assetMsgResponse: state.assets.assetMsgResponse,
    assetAddUpdate: state.assets.assetAddUpdate,
    categories: state.categories.categories,
    itemList: state.items.itemList,
    hardwareList: state.hardware.hardwareList,
  }));

  // Load Menu Area Items 
  useEffect(() => {
    dispatch(onGetMenuArea({}));
    dispatch(onGetVenueList());
    dispatch(onGetgetCategories({}));
  }, [dispatch, menuAreaAddUpdate]);

  useEffect(() => {
    const timer = setTimeout(() => {
      dispatch(onResetMenuAreaApiResponseFlag());
    }, 5000);
    return () => clearTimeout(timer);
  }, [menuAreaMsgResponse]);

  useEffect(() => {
    const timer = setTimeout(() => {
      dispatch(onResetApiResponseFlag());
    }, 5000);
    return () => clearTimeout(timer);
  }, [assetMsgResponse]);

  const pageOptions = {
    sizePerPage: 10,
    totalSize: menuArea.length, // replace later with size(users),
    custom: true,
  };

  const defaultSorted: any = [
    {
      dataField: "_id", // if dataField is not match to any column you defined, it will be ignored.
      order: "asc", // desc or asc
    },
  ];

  // Filter Hardware Location
  const filterHardware = (venueAreasId: any) => {
    dispatch(onGetHardware({ id: venueAreasId }));
  };

  const filterArea = (venueId: string) => {
    setVenueClass({
      venueId: venueId
    })
    setVenueId(venueId)
    dispatch(onGetVenueArea({ id: venueId }));
    dispatch(onGetgetCategories({ venueId: venueId }));
    dispatch(onGetMenuArea({ venueId: venueId }));
  };

  const filterMenuByArea = (venueAreasId: string) => {
    setVenueAreaClass({
      venueAreasId: venueAreasId
    })
    dispatch(onGetMenuArea({ venueAreasId: venueAreasId, categoryId: categoryClass.categoryId }));
  };

  const filterMenuByCategory = (categoryId: string) => {
    setCategoryClass({
      categoryId: categoryId
    })
    dispatch(onGetMenuArea({ categoryId: categoryId, venueAreasId: venueAreaClass.venueAreasId }));
  };

  const filterByMenu = (keyward: string) => {
    dispatch(onGetMenuArea({ categoryId: categoryClass.categoryId, venueAreasId: venueAreaClass.venueAreasId, keyward: keyward, venueId: venueClass.venueId }));
  };

  const filterItems = (categoryId: string) => {
    dispatch(OnGetMenuListData({ catId: categoryId, venueId: isVenueId }));
  };

  const handleDeleteClick = (event: any) => {
    setMenuAreaEvent({
      menuAreaId: event._id,
    });
    setDeleteModal(true);
  };
  /**
  * On delete event
  */
  const handleDeleteAsset = () => {
    dispatch(onUpdateMenuArea(menuAreaEvent));
    setDeleteModal(false);
  };

  const handleDeleteClose = () => {
    setDeleteModal(false)
    setMenuAreaEvent({})
  };

  const handleValidFormSubmit = (values: any) => {
    if (isEdit) {
      values["menuAreaId"] = `${menuAreaEvent._id}`
      dispatch(onUpdateMenuArea(values));
    } else {
      dispatch(onAddNewMenuArea(values));
    }
    setMenuAreaEvent({});
    setIsEdit(false);
    setModal(!modal);
  };

  const handleValidMultipleFormSubmit = (values: any) => {
    dispatch(onAddNewMenuArea(values));
    setMenuAreaEvent({});
    setMultipleItemModal(false);
  };

  const toggle = () => {
    setModal(!modal);
    if (!modal && !isEmpty(menuAreaEvent) && !!isEdit) {
      setTimeout(() => {
        setMenuAreaEvent({})
        setIsEdit(false);
      }, 500);
    }
  };

  const playVide = (e: any) => {
    setVideoUrl(e.menuInfo)
    setOpen(true)
  }

  const setName = (keyward: string) => {
    dispatch(onGetitemList({ keyward: keyward }));
  };


  const handleAddMenuAreaClicks = () => {
    setIsEdit(false);
    setMenuAreaEvent({})
    dispatch(onGetgetCategories({}));
    dispatch(onGetAssetsList({}));
    dispatch(onGetVenueList());
    setModal(!modal);
  };

  const handleMultipleAddMenuAreaClicks = () => {
    setMultipleItemModal(true);
  };

  const handleEditMenuAreaClick = (event: any) => {
    setMenuAreaEvent(event);
    setIsEdit(true);
    dispatch(onGetVenueList());
    dispatch(onGetVenueArea({ id: event.venueId }));
    dispatch(onGetgetCategories({}));
    dispatch(OnGetMenuListData({ catId: event.categoryId }));
    setModal(!modal);
  };

  const createMarkup = (e: string) => {
    return { __html: e };
  }

  const setEventMenu = (event: any) => {
    console.log('event', event)
    let ev: any = JSON.parse(event)
    let e: any = {
      price: ev.price,
      takeOutPrice: ev.takeOutPrice,
      deliveryPrice: ev.deliveryPrice,
      marketPlacePrice: ev.marketPlacePrice,
      discount: ev.discount
    }
    if (Object.keys(menuAreaEvent).length === 0) {
      setMenuAreaEvent(e);
    }

  }

  const menuListColumns = [
    {
      text: "VENUE (VENUE AREA)",
      dataField: "venueInfo.name",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent: any, menu: any) => (
        <React.Fragment>{menu.venueInfo.name} {"->"} ({menu.venueAreaInfo.areaName})</React.Fragment>
      ),
    },
    {
      text: "VAT CATEGORY (CATEGORY)",
      dataField: "vatCategoryInfo.name.en",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent: any, menu: any) => (
        <React.Fragment>{menu.vatCategoryInfo.name.en} ({menu.vatCategoryInfo.vat}%) {"->"}({menu.categoryInfo.name.en})</React.Fragment>
      ),
    },
    // {
    {
      text: "ITEM",
      dataField: "name",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent: any, menu: any) => (
        <React.Fragment>
          <Button onClick={() => playVide(menu)}><Icon className="mdi-file-video" name={"video"} /></Button>
          <img
            className="avatar-sm  me-3"
            src={menu.image}
            alt=""
          />{menu.name}

        </React.Fragment>
      ),
    },
    {
      text: "PRICE",
      dataField: "price",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent: any, menu: any) => (
        <React.Fragment>{menu.price}</React.Fragment>
      ),
    },
    {
      text: "TAKE OUT PRICE",
      dataField: "takeOutPrice",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent: any, menu: any) => (
        <React.Fragment>{menu.takeOutPrice}</React.Fragment>
      ),
    },
    {
      text: "DELIVERY PRICE",
      dataField: "deliveryPrice",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent: any, menu: any) => (
        <React.Fragment>{menu.deliveryPrice}</React.Fragment>
      ),
    },
    {
      text: "MARKETPLACE PRICE",
      dataField: "marketPlacePrice",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent: any, menu: any) => (
        <React.Fragment>{menu.marketPlacePrice}</React.Fragment>
      ),
    },
    {
      text: "DISCOUNT",
      dataField: "discount",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent: any, menu: any) => (
        <React.Fragment>{menu.discount}</React.Fragment>
      ),
    },
    {
      text: "QUANTITY",
      dataField: "quantity",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent: any, menu: any) => (
        <React.Fragment>{menu.quantity}</React.Fragment>
      ),
    },
    {
      text: "STATUS",
      dataField: "status",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent: any, menu: any) => (
        <React.Fragment>
          {menu.status === 1 ?
            <div className="badge bg-success font-size-12">{"Active"}</div>
            : <div className="badge bg-warning font-size-12">{"Inactive"}</div>}
        </React.Fragment>
      ),
    },
    {
      text: "ACTION",
      dataField: "action",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent: any, menu: any) => (
        <React.Fragment>
          <UncontrolledDropdown>
            <DropdownToggle tag="button" className="btn btn-light btn-sm">
              <i className="uil uil-ellipsis-h"></i>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-end">
              <DropdownItem to="#" onClick={() => handleEditMenuAreaClick(menu)}>Edit</DropdownItem>
              <DropdownItem to="#" onClick={() => handleDeleteClick(menu)}>{menu.status == 1 ? "Inactive" : "Active"}</DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </React.Fragment>
      ),
    },
  ];

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteAsset}
        onCloseClick={handleDeleteClose}
        deleteButtonLable={menuAreaEvent.status == 0 ? "Inactive" : "Active"}
      />


      <div className="page-content">
        <MetaTags>
          <title>Menu Area | Retaurant Booking</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Restaurant" breadcrumbItem="Item Area" />
          {menuAreaMsgResponse && menuAreaMsgResponse.message ? (
            <UncontrolledAlert color="primary">
              {menuAreaMsgResponse.message}
            </UncontrolledAlert>
          ) : null}
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="_id"
                        data={menuArea}
                        columns={menuListColumns}
                        bootstrap4
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <Row className="align-items-start">

                              <Col xs={2} md={2}>
                                <Button color="light" onClick={handleAddMenuAreaClicks} className="mb-1 btn-sm button-success">
                                  <i className="mdi mdi-plus me-1"></i>
                                  signle Item</Button>
                              </Col>
                              <Col xs={2} md={2}>
                                <Button color="light" onClick={handleMultipleAddMenuAreaClicks} className="mb-1 btn-sm button-success">
                                  <i className="mdi mdi-plus me-1"></i>
                                  Multiple Item</Button>
                              </Col>

                              <Col xs={2} md={2}>
                                <select className="input-select-1 col-sm" onChange={(e) => filterArea(e.target.value)}>
                                  <option value=""> Venue </option>
                                  {
                                    venueList.map((venue: any, key: any) => (
                                      <option value={venue._id} key={key}>{venue.name}</option>
                                    ))}
                                </select></Col>
                              <Col xs={2} md={2}>
                                <select name="venueAreaSelect" className="input-select-1 col-sm" onChange={(e) => filterMenuByArea(e.target.value)}>
                                  <option value=""> Venue Area</option>
                                  {
                                    venueArea.map((area: any, key: any) => (
                                      <option value={area._id} key={key}>{area.areaName}</option>
                                    ))}
                                </select></Col>
                              <Col xs={2} md={2}>
                                <select className="input-select-1 col-sm" onChange={(e) => filterMenuByCategory(e.target.value)}>
                                  <option value=""> Category </option>
                                  {
                                    categories.map((category: any, key: any) => (
                                      <option value={category._id} key={key}>{category.name.en}</option>
                                    ))}
                                </select></Col>
                              <Col xs={2} md={2}>
                                <input className="search-input" type="search" placeholder="Search..." onChange={(e) => filterByMenu(e.target.value)} />
                              </Col>
                              {/* <Col xs={6} md={4}> </Col> */}

                            </Row>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                    defaultSorted={defaultSorted}
                                    classes={
                                      "table align-middle table-nowrap table-hover"
                                    }
                                    bordered={false}
                                    striped={false}
                                  />
                                  <Modal isOpen={modal} toggle={toggle} size="lg">
                                    <ModalHeader toggle={toggle} tag="h4">
                                      {!!isEdit ? "Edit Menu Area" : "Add Menu Area"}
                                    </ModalHeader>
                                    <ModalBody>
                                      <AvForm
                                        onValidSubmit={(
                                          e: any,
                                          values: any
                                        ) => {
                                          handleValidFormSubmit(values);
                                        }}
                                      >
                                        <Row form>
                                          <Row >
                                            <Col xs={6} md={6}>

                                              <AvField
                                                type="select"
                                                name="venueId"
                                                placeholder="Select Venue"
                                                label="Venue"
                                                multiple={false}
                                                required
                                                onChange={(e: any) => filterArea(e.target.value)}
                                                value={menuAreaEvent.venueId || ""}
                                              >
                                                <option key='blankChoice' hidden value=""> Select Venue </option>
                                                {
                                                  venueList.map((venue: any, index: number) => (
                                                    <option value={venue._id} key={index}>{venue.name}</option>
                                                  ))}
                                              </AvField>

                                            </Col>
                                            <Col xs={6} md={6}>

                                              <AvField
                                                type="select"
                                                name="venueAreasId"
                                                placeholder="Select Venue Area"
                                                label="Venue Area"
                                                multiple={false}
                                                required
                                                onChange={(e: any) => filterHardware(e.target.value)}
                                                value={menuAreaEvent.venueAreasId || ""}
                                              >
                                                <option key='blankChoice' hidden value=""> Select Venue Area </option>
                                                {
                                                  venueArea.map((area: any, index: number) => (
                                                    <option value={area._id} key={index}>{area.areaName}</option>
                                                  ))}
                                              </AvField>

                                            </Col>
                                            <Col xs={6} md={6}>  <AvField
                                              type="select"
                                              name="categoryId"
                                              placeholder="Select Category"
                                              errorMessage="Select valid Catrgory"
                                              label="Category"
                                              multiple={false}
                                              required
                                              onChange={(e: any) => filterItems(e.target.value)}
                                              value={menuAreaEvent.categoryId || ""}
                                            >
                                              <option key='blankChoice1' hidden value=""> Select Caegory </option>
                                              {
                                                categories.map((category: any, index: number) => (
                                                  <option value={category._id} key={index}>{category.name.en}</option>
                                                ))}
                                            </AvField></Col>
                                            <Col xs={6} md={6}>

                                              <AvField
                                                type="select"
                                                name="venueHardwareId"
                                                placeholder="Select Hardware"
                                                label="Hardware"
                                                multiple={false}
                                                required
                                                // onChange={(e: any) => filterHardware(e.target.value)}
                                                value={menuAreaEvent.venueHardwareId || ""}
                                              >
                                                <option key='blankChoice' hidden value=""> Select Hardware </option>
                                                {
                                                  hardwareList.map((area: any, index: number) => (
                                                    <option value={area._id} key={index}>({area.hardwareType + "-" + area.code})</option>
                                                  ))}
                                              </AvField>

                                            </Col>
                                            <Col xs={6} md={6}>   <AvField
                                              type="select"
                                              name="menuId"
                                              placeholder="Select Menu Item"
                                              label="Menu Item"
                                              multiple={false}
                                              required
                                              onChange={(e: any) => setEventMenu(e.target.value)}
                                              value={JSON.stringify(menuAreaEvent.menuInfo) || ""}
                                            >
                                              <option key='blankChoice' hidden value=""> Select Menu Item </option>
                                              {
                                                itemList.map((item: any, index: any) => (
                                                  <option key={index} value={JSON.stringify(item)}>{item.name.en}</option>
                                                ))}
                                            </AvField></Col>
                                            <Col xs={6} md={6}>      <AvField
                                              name="price"
                                              label="Price"
                                              type="number"
                                              placeholder="Enter Valid Price"
                                              errorMessage="Invalid Price"
                                              validate={{
                                                required: { value: true },
                                              }}
                                              value={menuAreaEvent.price || ""}
                                            /></Col>
                                            <Col xs={6} md={6}> <AvField
                                              name="takeOutPrice"
                                              label="Take Out Price"
                                              type="number"
                                              placeholder="Enter Valid Take Out Price"
                                              errorMessage="Invalid Take Out Price"
                                              validate={{
                                                required: { value: true },
                                              }}
                                              value={menuAreaEvent.takeOutPrice || ""}
                                            /></Col>
                                            <Col xs={6} md={6}>    <AvField
                                              name="deliveryPrice"
                                              label="Delivery Price"
                                              type="number"
                                              placeholder="Enter Valid Delivery Price "
                                              errorMessage="Invalid Delivery Price "
                                              validate={{
                                                required: { value: true },
                                              }}
                                              value={menuAreaEvent.deliveryPrice || ""}
                                            /></Col>
                                            <Col xs={6} md={6}>   <AvField
                                              name="marketPlacePrice"
                                              label="MarketPlace Price"
                                              type="number"
                                              placeholder="Enter Valid MarketPlace Price "
                                              errorMessage="Invalid MarketPlace Price "
                                              validate={{
                                                required: { value: true },
                                              }}
                                              value={menuAreaEvent.marketPlacePrice || ""}
                                            /></Col>

                                            <Col xs={6} md={6}>


                                              <AvField
                                                name="discount"
                                                label="Discount"
                                                type="number"
                                                placeholder="Enter Valid Discount "
                                                errorMessage="Invalid Discount "
                                                validate={{
                                                  required: { value: true },
                                                }}
                                                value={menuAreaEvent.discount || ""}
                                              />
                                            </Col>
                                            <Col xs={6} md={6}>

                                              <AvField
                                                name="quantity"
                                                label="Quantity"
                                                type="number"
                                                placeholder="Enter Valid Quantity "
                                                errorMessage="Invalid Quantity "
                                                validate={{
                                                  required: { value: true },
                                                }}
                                                value={menuAreaEvent.quantity || ""}
                                              />
                                              <AvField key="actionType" type="hidden" name="actionType" value="single" />
                                            </Col>
                                          </Row>
                                        </Row>
                                        <Row>
                                          <Col>
                                            <div className="text-end">
                                              <button type="button" className="btn btn-light w-sm" onClick={handleShow}>Close</button>
                                              <button
                                                type="submit"
                                                className="btn btn-success save-user"
                                              >
                                                Save
                                              </button>
                                            </div>
                                          </Col>
                                        </Row>
                                      </AvForm>
                                    </ModalBody>
                                  </Modal>
                                  <Modal isOpen={isOpen} toggle={handleVideoModel}>
                                    <ModalBody>
                                      <video width="100%" controls >
                                        <source src={videoUrl.videoUrl} type="video/mp4" />
                                      </video>
                                      <div dangerouslySetInnerHTML={createMarkup(videoUrl.prepareDescription)}></div>
                                    </ModalBody>
                                    <ModalFooter>
                                      <Button color="secondary" onClick={handleVideoModel}>
                                        Cancel
                                      </Button>
                                    </ModalFooter>
                                  </Modal>

                                  <Modal isOpen={multipleItemModal} size="lg">
                                    <ModalHeader tag="h4">
                                      Add Multiple Items
                                    </ModalHeader>
                                    <ModalBody>
                                      <AvForm
                                        onValidSubmit={(
                                          e: any,
                                          values: any
                                        ) => {
                                          handleValidMultipleFormSubmit(values);
                                        }}
                                      >
                                        <Row form>
                                          <Row >
                                            <Col xs={6} md={6}>

                                              <AvField
                                                type="select"
                                                name="venueId"
                                                placeholder="Select Venue"
                                                label="Venue"
                                                multiple={false}
                                                required
                                                onChange={(e: any) => filterArea(e.target.value)}
                                                value={menuAreaEvent.venueId || ""}
                                              >
                                                <option key='blankChoice' hidden value=""> Select Venue </option>
                                                {
                                                  venueList.map((venue: any, index: number) => (
                                                    <option value={venue._id} key={index}>{venue.name}</option>
                                                  ))}
                                              </AvField>

                                            </Col>
                                            <Col xs={6} md={6}>

                                              <AvField
                                                type="select"
                                                name="venueAreasId"
                                                placeholder="Select Venue Area"
                                                label="Venue Area"
                                                multiple={false}
                                                required
                                                onChange={(e: any) => filterHardware(e.target.value)}
                                                value={menuAreaEvent.venueAreasId || ""}
                                              >
                                                <option key='blankChoice' hidden value=""> Select Venue Area </option>
                                                {
                                                  venueArea.map((area: any, index: number) => (
                                                    <option value={area._id} key={index}>{area.areaName}</option>
                                                  ))}
                                              </AvField>

                                            </Col>
                                            <Col xs={6} md={6}>  <AvField
                                              type="select"
                                              name="categoryId"
                                              placeholder="Select Category"
                                              errorMessage="Select valid Catrgory"
                                              label="Category"
                                              multiple={false}
                                              required
                                              onChange={(e: any) => filterItems(e.target.value)}
                                              value={menuAreaEvent.categoryId || ""}
                                            >
                                              <option key='blankChoice1' hidden value=""> Select Caegory </option>
                                              {
                                                categories.map((category: any, index: number) => (
                                                  <option value={category._id} key={index}>{category.name.en}</option>
                                                ))}
                                            </AvField></Col>
                                            <Col xs={6} md={6}>

                                              <AvField
                                                type="select"
                                                name="venueHardwareId"
                                                placeholder="Select Hardware"
                                                label="Hardware"
                                                multiple={false}
                                                required
                                                // onChange={(e: any) => filterHardware(e.target.value)}
                                                value={menuAreaEvent.venueHardwareId || ""}
                                              >
                                                <option key='blankChoice' hidden value=""> Select Hardware </option>
                                                {
                                                  hardwareList.map((area: any, index: number) => (
                                                    <option value={area._id} key={index}>({area.hardwareType + "-" + area.code})</option>
                                                  ))}
                                              </AvField>

                                            </Col>
                                            <Col xs={6} md={6}>   <AvField
                                              type="select"
                                              name="menuId"
                                              placeholder="Select Menu Item"
                                              label="Menu Item"
                                              multiple={true}
                                              required
                                              onChange={(e: any) => setEventMenu(e.target.value)}
                                              value={JSON.stringify(menuAreaEvent.menuInfo) || ""}
                                            >
                                              <option key='blankChoice' hidden value=""> Select Menu Item </option>
                                              {
                                                itemList.map((item: any, index: any) => (
                                                  <option key={index} value={JSON.stringify(item)}>{item.name.en}</option>
                                                ))}
                                            </AvField>
                                              <AvField key="actionType" type="hidden" name="actionType" value="multiple" />
                                            </Col>
                                          </Row>
                                        </Row>
                                        <Row>
                                          <Col>
                                            <div className="text-end">
                                              <button type="button" className="btn btn-light w-sm" onClick={handleMultipleItemModal}>Close</button>
                                              <button
                                                type="submit"
                                                className="btn btn-success save-user"
                                              >
                                                Save
                                              </button>
                                            </div>
                                          </Col>
                                        </Row>
                                      </AvForm>
                                    </ModalBody>
                                  </Modal>
                                </div>
                              </Col>
                            </Row>
                            <Row className="align-items-md-center mt-30">
                              <Col className="pagination pagination-rounded justify-content-end mb-2">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row >
        </Container >
      </div >
    </React.Fragment >
  );
};

export default MenuArea;
