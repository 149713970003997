import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
    Card,
    CardBody,
    Col,
    Container,
    Row,
    Button
} from "reactstrap";
import Icon from "@ailibs/feather-react-ts";
import moment from "moment";
import {
    getNotification as onGetNotification,
    deleteNotification as onDeleteNotification
} from "../../slices/thunks";
import paginationFactory, {
    PaginationListStandalone,
    PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable, { BootstrapTableProps } from "react-bootstrap-table-next";
//redux
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";

const Notification = () => {
    const dispatch = useDispatch();
    const [modal, setModal] = useState<boolean>(false);
    const { notificationsList } = useSelector((state: any) => ({
        notificationsList: state.notification.notificationsList
    }));

    useEffect(() => {
        dispatch(onGetNotification({}));
    }, []);

    const pageOptions = {
        sizePerPage: 10,
        totalSize: notificationsList.length, // replace later with size(users),
        custom: true,
    };

    const defaultSorted: any = [
        {
            dataField: "_id", // if dataField is not match to any column you defined, it will be ignored.
            order: "asc", // desc or asc
        },
    ];

    const deleteNotification = (event: any) => {
        dispatch(onDeleteNotification({id:event._id}))
        const timer = setTimeout(() => {
            dispatch(onGetNotification({}));
          }, 2000);
          return () => clearTimeout(timer);
    };

    const categoriesListColumns = [
        {
            text: "TITLE",
            dataField: "title",
            sort: true,
            // eslint-disable-next-line react/display-name
            formatter: (cellContent: any, notificationsList: any) => <React.Fragment>
                <div className="text-danger" style={{ whiteSpace: 'pre-line' }}>
                    {notificationsList.title}
                    {(notificationsList.type == "NewOrder") ?
                        <Link to={'/assets/kitchen?kdsId=&area='+notificationsList.venueAreasId+'&venueId='+notificationsList.venueId}>  Go Order</Link>
                        : ""}
                    </div></React.Fragment>,
        },
        {
            text: "MESSAGE",
            dataField: "message",
            sort: true,
            // eslint-disable-next-line react/display-name
            formatter: (cellContent: any, notificationsList: any) => <React.Fragment>
                <div className="" style={{ whiteSpace: 'pre-line' }}>{notificationsList.message}</div>
            </React.Fragment>,
        },
        {
            text: "STATUS",
            dataField: "status",
            sort: true,
            // eslint-disable-next-line react/display-name
            formatter: (cellContent: any, notificationsList: any) => (
                <React.Fragment>
                    {notificationsList.status === "Active" ?
                        <div className="badge bg-success font-size-12">{"Active"}</div>
                        : <div className="badge bg-warning font-size-12">{"Inactive"}</div>}
                </React.Fragment>
            ),
        },
        {
            text: "DATETIME",
            dataField: "orderDate",
            sort: true,
            // eslint-disable-next-line react/display-name 
            formatter: (cellContent: any, notificationsList: any) => <React.Fragment>{moment(notificationsList.createDate).format('DD MMM Y HH:mm:ss')}</React.Fragment>,
          },
        {
            text: "ACTION",
            dataField: "action",
            sort: true,
            // eslint-disable-next-line react/display-name
            formatter: (cellContent: any, notificationsList: any) => (
                <React.Fragment>
                    <a color='badge bg-green primary' className="p-0" onClick={() => { deleteNotification(notificationsList) }}>
                        <Icon name='trash-2' className="my-awesome-class" />
                    </a>
                </React.Fragment>
            ),
        },
    ];

    return (
        <React.Fragment>
            {/* <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteCategory}
        onCloseClick={handleDeleteClose}
        deleteButtonLable={catEvent.status == 0 ? "Inactive" : "Active"}
      /> */}

            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <div className="page-content">
                <MetaTags>
                    <title>Notifications | Retaurant Booking</title>
                </MetaTags>
                <Container fluid>
                    {/* Render Breadcrumbs
          <Breadcrumbs title="Alert" breadcrumbItem="Notifications" />
          {catMsgResponse && catMsgResponse.message ? (
            <UncontrolledAlert color="primary">
              {catMsgResponse.message}
            </UncontrolledAlert>
          ) : null} */}
                    <Row>
                        <Col lg="12">
                            <Card>
                                <CardBody>
                                    <PaginationProvider
                                        pagination={paginationFactory(pageOptions)}
                                    >
                                        {({ paginationProps, paginationTableProps }) => (
                                            <ToolkitProvider
                                                keyField="_id"
                                                data={notificationsList}
                                                columns={categoriesListColumns}
                                                bootstrap4
                                                search
                                            >
                                                {(toolkitProps: { baseProps: JSX.IntrinsicAttributes & JSX.IntrinsicClassAttributes<BootstrapTable<any, number>> & Readonly<BootstrapTableProps<any, number>> & Readonly<{ children?: React.ReactNode; }>; }) => (
                                                    <React.Fragment>
                                                        <Row>
                                                            <Col xl="12">
                                                                <div className="table-responsive">
                                                                    <BootstrapTable
                                                                        {...toolkitProps.baseProps}
                                                                        {...paginationTableProps}
                                                                        defaultSorted={defaultSorted}
                                                                        classes={
                                                                            "table align-middle table-nowrap table-hover"
                                                                        }
                                                                        bordered={false}
                                                                        striped={false}
                                                                    />

                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row className="align-items-md-center mt-30">
                                                            <Col className="pagination pagination-rounded justify-content-end mb-2">
                                                                <PaginationListStandalone
                                                                    {...paginationProps}
                                                                />
                                                            </Col>
                                                        </Row>
                                                    </React.Fragment>
                                                )}
                                            </ToolkitProvider>
                                        )}
                                    </PaginationProvider>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment >
    );
};

export default Notification;
