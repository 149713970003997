import React, { ChangeEvent, useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  UncontrolledAlert
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import {
  getCategoriesType as onGetCategoriesType,
  addNewCategoryType as onAddNewCategoryType,
  updateCategoryType as onUpdateCategoryType,
  resetApiCategoryTypeResponseFlag as onResetApiCategoryTypeResponseFlag,
  updateCategoryTypeStatus as onUpdateCategoryTypeStatus
} from "../../slices/thunks";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { isEmpty } from "lodash";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable, { BootstrapTableProps } from "react-bootstrap-table-next";


import DeleteModal from "../Common/DeleteModal";
//redux
import { useSelector, useDispatch } from "react-redux";

const CategoriesType = () => {
  const dispatch = useDispatch();

  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [catEvent, setCatEvent] = useState<any>({});
  const [modal, setModal] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [file, setFile] = useState<File>();

  const handleShow = () => setModal(false);

  const { categoriesType, catMsgResponse, categoriesTypeUpdate } = useSelector((state: any) => ({
    categoriesType: state.categoriesType.categoriesType,
    catMsgResponse: state.categoriesType.catMsgResponse,
    categoriesTypeUpdate: state.categoriesType.categoriesTypeUpdate
  }));

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      dispatch(onResetApiCategoryTypeResponseFlag());
    }, 5000);
    return () => clearTimeout(timer);
  }, [catMsgResponse]);


  // issue in it
  useEffect(() => {
    dispatch(onGetCategoriesType());
  }, [dispatch, categoriesTypeUpdate]);


  const pageOptions = {
    sizePerPage: 10,
    totalSize: categoriesType.length, // replace later with size(users),
    custom: true,
  };

  const defaultSorted: any = [
    {
      dataField: "_id", // if dataField is not match to any column you defined, it will be ignored.
      order: "asc", // desc or asc
    },
  ];

  const handleDeleteClick = (event: any) => {
    setCatEvent({
      id: event._id,
      status: event.status == 1 ? 0 : 1
    });
    setDeleteModal(true);
  };
  /**
  * On delete event
  */
  const handleDeleteCategory = () => {
    dispatch(onUpdateCategoryTypeStatus(catEvent));
    setDeleteModal(false);
  };

  const handleDeleteClose = () => {
    setDeleteModal(false)
    setCatEvent({})
  };

  const handleValidFormSubmit = (values: any) => {
    const name = { en: `${values.enName}`, gr: `${values.grName}` }
    values['name'] = JSON.stringify(name);
    if (isEdit) {
      values['id'] = catEvent.id
      dispatch(onUpdateCategoryType(values));
    } else {
      dispatch(onAddNewCategoryType(values));
    }
    setCatEvent({})
    setIsEdit(false);
    setModal(!modal);
  };

  const toggle = () => {
    setModal(!modal);
    if (!modal && !isEmpty(catEvent) && !!isEdit) {
      setTimeout(() => {
        setCatEvent({})
        setIsEdit(false);
      }, 500);
    }
  };

  const handleUserClicks = () => {
    setIsEdit(false);
    setCatEvent({})
    setModal(!modal);
  };

  const handleCategoryClick = (event: any) => {
    setCatEvent({
      id: event._id,
      enName: event.enName,
      grName: event.grName,
      enDescription: event.enDescription,
      grDescription: event.grDescription,
      vat: event.vat,
    });
    setIsEdit(true);
    setModal(!modal);
  };

  const categoriesListColumns = [
    {
      text: "NAME",
      dataField: "name",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent: any, categoriesType: any) => <React.Fragment>{categoriesType.name.en} ({categoriesType.name.gr})</React.Fragment>,
    },
    {
      text: "VAT",
      dataField: "vat",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent: any, categoriesType: any) => <React.Fragment>{categoriesType.vat}%</React.Fragment>,
    },
    {
      text: "STATUS",
      dataField: "status",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent: any, categoriesType: any) => (
        <React.Fragment>
          {categoriesType.status == 1 ?
            <div className="badge bg-success font-size-12">{"Active"}</div>
            : <div className="badge bg-warning font-size-12">{"Inactive"}</div>}
        </React.Fragment>
      ),
    },
    {
      text: "ACTION",
      dataField: "action",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent: any, categoriesType: any) => (
        <React.Fragment>
          <UncontrolledDropdown>
            <DropdownToggle tag="button" className="btn btn-light btn-sm">
              <i className="uil uil-ellipsis-h"></i>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-end">
              <DropdownItem to="#" onClick={() => handleCategoryClick(categoriesType)}>Edit</DropdownItem>
              <DropdownItem to="#" onClick={() => handleDeleteClick(categoriesType)}>{categoriesType.status == 1 ? "Inactive" : "Active"}</DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </React.Fragment>
      ),
    },
  ];

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteCategory}
        onCloseClick={handleDeleteClose}
        deleteButtonLable={catEvent.status == 0 ? "Inactive" : "Active"}
      />

      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className="page-content">
        <MetaTags>
          <title>Category Type | Retaurant Booking</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Menus" breadcrumbItem="VAT Category" />
          {catMsgResponse && catMsgResponse.message ? (
            <UncontrolledAlert color="primary">
              {catMsgResponse.message}
            </UncontrolledAlert>
          ) : null}
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="_id"
                        data={categoriesType}
                        columns={categoriesListColumns}
                        bootstrap4
                        search
                      >
                        {(toolkitProps: { baseProps: JSX.IntrinsicAttributes & JSX.IntrinsicClassAttributes<BootstrapTable<any, number>> & Readonly<BootstrapTableProps<any, number>> & Readonly<{ children?: React.ReactNode; }>; }) => (
                          <React.Fragment>
                            <Row className="align-items-start">
                              <div className="col-sm">
                                <div>
                                  <Button color="light" onClick={handleUserClicks} className="mb-4 button-success">
                                    <i className="mdi mdi-plus me-1"></i>
                                    Add VAT Category</Button>
                                </div>
                              </div>
                            </Row>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                    defaultSorted={defaultSorted}
                                    classes={
                                      "table align-middle table-nowrap table-hover"
                                    }
                                    bordered={false}
                                    striped={false}
                                  />
                                  <Modal isOpen={modal} toggle={toggle}>
                                    <ModalHeader toggle={toggle} tag="h4">
                                      {!!isEdit ? "Edit VAT Category" : "Add VAT Category"}
                                    </ModalHeader>
                                    <ModalBody>
                                      <AvForm
                                        onValidSubmit={(
                                          e: any,
                                          values: any
                                        ) => {
                                          handleValidFormSubmit(values);
                                        }}
                                      >
                                        <Row form>
                                          <Col xs={12}>
                                            <div className="mb-3">
                                              <AvField
                                                name="enName"
                                                label="Category Name (English)"
                                                type="text"
                                                placeholder="Enter Valid Category Name"
                                                errorMessage="Invalid Category Name"
                                                validate={{
                                                  required: { value: true },
                                                }}
                                                value={catEvent.enName || ""}
                                              />
                                            </div>
                                            <div className="mb-3">
                                              <AvField
                                                name="grName"
                                                label="Category Name (Greece)"
                                                type="text"
                                                placeholder="Enter Valid Category Name"
                                                errorMessage="Invalid Category Name"
                                                validate={{
                                                  required: { value: true },
                                                }}
                                                value={catEvent.grName || ""}
                                              />
                                            </div>
                                            <div className="mb-3">
                                              <AvField
                                                name="vat"
                                                label="VAT"
                                                type="number"
                                                placeholder="Enter Valid VAT"
                                                errorMessage="Invalid VAT"
                                                validate={{
                                                  required: { value: true },
                                                }}
                                                value={catEvent.vat || ""}
                                              />
                                            </div>
                                            {/* <div className="mb-3">
                                              <AvField
                                                name="enDescription"
                                                label="Description (English)"
                                                type="textarea"
                                                placeholder="Enter Valid Description "
                                                errorMessage="Invalid Description "
                                                validate={{
                                                  required: { value: true },
                                                }}
                                                value={catEvent.enDescription || ""}
                                              />
                                            </div> */}
                                            {/* <div className="mb-3">
                                              <AvField
                                                name="grDescription"
                                                label="Description (Greece)"
                                                type="textarea"
                                                placeholder="Enter Valid Description "
                                                errorMessage="Invalid Description "
                                                validate={{
                                                  required: { value: true },
                                                }}
                                                value={catEvent.grDescription || ""}
                                              />
                                            </div> */}
                                            {/* <div className="mb-3">
                                              {
                                                !!isEdit ?
                                                  <AvField
                                                    onChange={handleFileChange}
                                                    name="image"
                                                    label="Category Image "
                                                    type="file"
                                                    placeholder="Enter Valid category Image "
                                                   />
                                                  :
                                                  <AvField
                                                    onChange={handleFileChange}
                                                    name="image"
                                                    label="Category Image "
                                                    type="file"
                                                    placeholder="Enter Valid Category Image "
                                                    errorMessage="Invalid Category Image "
                                                    validate={{
                                                      required: { value: true },
                                                    }}
                                                  />}
                                            </div> */}
                                          </Col>
                                        </Row>
                                        <Row>
                                          <Col>
                                            <div className="text-end">
                                              <button type="button" className="btn btn-light w-sm" onClick={handleShow}>Close</button>
                                              <button
                                                type="submit"
                                                className="btn btn-success save-user"
                                              >
                                                Save
                                              </button>
                                            </div>
                                          </Col>
                                        </Row>
                                      </AvForm>
                                    </ModalBody>
                                  </Modal>
                                </div>
                              </Col>
                            </Row>
                            <Row className="align-items-md-center mt-30">
                              <Col className="pagination pagination-rounded justify-content-end mb-2">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment >
  );
};

export default CategoriesType;
