import React, { useEffect, useState, ChangeEvent } from "react";
import MetaTags from "react-meta-tags";
import { ToastContainer, toast } from 'react-toastify';
import { withRouter, Link } from "react-router-dom";
import {
    Card,
    CardBody,
    Col,
    Container,
    Row,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Table,
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import {
    getVenuekds as onGetVenuekds,
    getVenueList as onGetVenueList,
    getVenueArea as onGetVenueArea,
} from "../../slices/thunks";
import BreadCrumbs from "../../components/Common/Breadcrumb";
import { isEmpty } from "lodash";
import paginationFactory, {
    PaginationListStandalone,
    PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
// redux
import { useSelector, useDispatch } from "react-redux";
const Hardwares = () => {
    const dispatch = useDispatch();
    const { SearchBar } = Search;
    const [categoryEvent, setCategoryEvent] = useState<any>([]);
    const [categoryModal, setCategoryModal] = useState<boolean>(false);
    // const formData = new FormData();
    const handleShow = () => setCategoryModal(false);
    const {
        venueKdsList,
        venueList,
        venueArea,
    } = useSelector((state: any) => ({
        venueKdsList: state.orders.venueKdsList,
        venueList: state.venues.venueList,
        venueArea: state.areas.venueArea,
    }));

    // Filter Hardware Location
    const filterHardware = (venueAreasId: any) => {
        dispatch(onGetVenuekds({ venueAreasId: venueAreasId }));
    };

    const handleViewCategory = (categories: any) => {
        setCategoryEvent(categories)
        setCategoryModal(true)
    };

    const pageOptions = {
        sizePerPage: 10,
        totalSize: venueKdsList.length, // replace later with size(users),
        custom: true,
    };


    const defaultSorted: any = [
        {
            dataField: "_id", // if dataField is not match to any column you defined, it will be ignored.
            order: "asc", // desc or asc
        },
    ];

    const filterArea = (venueId: string) => {
        dispatch(onGetVenueArea({ id: venueId }));
        dispatch(onGetVenuekds({ venueId: venueId }));
    };

    // ----- 1. Get Hardware List -----------
    useEffect(() => {
        dispatch(onGetVenuekds({ id: '' }));
        dispatch(onGetVenueList())
    }, [dispatch]);

    let i = 1;
    const hardwareListColumns = [
        {
            text: "#",
            dataField: "name",
            sort: true,
            // eslint-disable-next-line react/display-name
            formatter: (cellContent: any, hardware: any) => (
                <React.Fragment>KDS {i++}</React.Fragment>
            ),
        },
        {
            text: "KDS",
            dataField: "name",
            sort: true,
            // eslint-disable-next-line react/display-name
            formatter: (cellContent: any, hardware: any) => (
                <React.Fragment>{hardware.name.en}</React.Fragment>
            ),
        },
        {
            text: "VENUE",
            dataField: "venueInfo",
            sort: true,
            // eslint-disable-next-line react/display-name
            formatter: (cellContent: any, hardware: any) => (
                <React.Fragment>{hardware.venueInfo.name}</React.Fragment>
            ),
        },
        {
            text: "VENUE AREA",
            dataField: "venueAreaInfo",
            sort: true,
            // eslint-disable-next-line react/display-name
            formatter: (cellContent: any, hardware: any) => (
                <React.Fragment>{hardware.venueAreaInfo.areaName}</React.Fragment>
            ),
        },
        {
            text: "TABLES",
            dataField: "tables",
            sort: true,
            // eslint-disable-next-line react/display-name
            formatter: (cellContent: any, hardware: any) => (
                <React.Fragment>
                    <Link target="_blank" to={"/assets?venueId=" + hardware.venueId + "&venueAreasId=" + hardware.venueAreasId}>
                        <div className="badge bg-primary font-size-12">View Tables</div></Link>
                </React.Fragment>
            ),
        },
        {
            text: "CATEGORY",
            dataField: "categoryInfo",
            sort: true,
            // eslint-disable-next-line react/display-name
            formatter: (cellContent: any, hardware: any) => (
                <React.Fragment>
                    <Button
                        color="success"
                        onClick={() => handleViewCategory(hardware.categoryInfo)}
                        className="btn"
                    >
                        View
                    </Button>
                </React.Fragment>
            ),
        },
        {
            text: "ACTION",
            dataField: "name",
            sort: true,
            // eslint-disable-next-line react/display-name
            formatter: (cellContent: any, hardware: any) => (
                <React.Fragment>
                    <Link target="_blank" to={"/assets/kitchen?kdsId=" + hardware._id + "&area=" + hardware.venueAreasId + "&venueId=" + hardware.venueId}>
                        <div className="btn btn-danger font-size-12">     <i className="mdi mdi-cast-connected me-1"></i> Connect KDS View Orders</div></Link>

                </React.Fragment>
            ),
        },
    ];

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Hardware | Retaurant Booking</title>
                </MetaTags>
                <ToastContainer
                    position="top-right"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <BreadCrumbs title="KDS" breadcrumbItem="Venue KDS" />
                    <Row>
                        <Col lg="12">
                            <Card>
                                <CardBody>
                                    <PaginationProvider
                                        pagination={paginationFactory(pageOptions)}
                                    >
                                        {({ paginationProps, paginationTableProps }) => (
                                            <ToolkitProvider
                                                keyField="_id"
                                                data={venueKdsList}
                                                columns={hardwareListColumns}
                                                bootstrap4
                                                search
                                            >
                                                {toolkitProps => (
                                                    <React.Fragment>
                                                        <Row className="align-items-start">
                                                            <Col xs={3} md={3}>
                                                                <select className="input-select" onChange={(e) => filterArea(e.target.value)}>
                                                                    <option value=""> Select Venue </option>
                                                                    {
                                                                        venueList.map((venue: any, key: any) => (
                                                                            <option value={venue._id} key={key}>{venue.name}</option>
                                                                        ))}
                                                                </select>  </Col>
                                                            <Col xs={3} md={3}>
                                                                <select className="input-select" onChange={(e) => filterHardware(e.target.value)}>
                                                                    <option value=""> Select Area </option>
                                                                    {
                                                                        venueArea.map((area: any, key: any) => (
                                                                            <option value={area._id} key={key}>{area.areaName}</option>
                                                                        ))}
                                                                </select>  </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col xl="12">
                                                                <div className="table-responsive">
                                                                    <BootstrapTable
                                                                        {...toolkitProps.baseProps}
                                                                        {...paginationTableProps}
                                                                        defaultSorted={defaultSorted}
                                                                        classes={
                                                                            "table align-middle table-nowrap table-hover"
                                                                        }
                                                                        bordered={false}
                                                                        striped={false}
                                                                    />
                                                                </div>

                                                                <Modal isOpen={categoryModal} toggle={handleShow} size="md">
                                                                    <ModalHeader toggle={handleShow}>Categories Info</ModalHeader>
                                                                    <ModalBody>
                                                                        <Table responsive>
                                                                            <thead>
                                                                                <tr>
                                                                                    <th>
                                                                                        #
                                                                                    </th>
                                                                                    <th>
                                                                                        Name
                                                                                    </th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {
                                                                                    (categoryEvent != "") ?
                                                                                        categoryEvent.map((m: any, key: any) => (

                                                                                            <tr key={key}>
                                                                                                <th scope="row">
                                                                                                    {key + 1}
                                                                                                </th>
                                                                                                <td>
                                                                                                    {m.name.en}
                                                                                                </td>

                                                                                            </tr>
                                                                                        ))
                                                                                        : <tr><th>No Categories</th><td>No Categories</td><td>No Categories</td></tr>
                                                                                }
                                                                            </tbody>
                                                                        </Table>
                                                                    </ModalBody>
                                                                    <ModalFooter>
                                                                        <Button color="secondary" onClick={handleShow}>
                                                                            Close
                                                                        </Button>
                                                                    </ModalFooter>

                                                                </Modal>
                                                            </Col>
                                                        </Row>
                                                        <Row className="align-items-md-center mt-30">
                                                            <Col className="pagination pagination-rounded justify-content-end mb-2">
                                                                <PaginationListStandalone
                                                                    {...paginationProps}
                                                                />
                                                            </Col>
                                                        </Row>
                                                    </React.Fragment>
                                                )}
                                            </ToolkitProvider>
                                        )}
                                    </PaginationProvider>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Hardwares;
