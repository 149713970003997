
import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  getServingOrder as getServingOrderApi,
  updateServingOrderStatus as updateServingOrderStatusApi,
  addServingOrder as addServingOrderApi,
  updateServingOrder as updateServingOrderApi,
} from "../../helpers/backend_helper";

export const getServingOrder = createAsyncThunk(
  "servingOrder/getServingOrder",
  async () => {
    try {
      const response = getServingOrderApi();
      return response;
    } catch (error) {
      return error;
    }
  }
);


export const addServingOrder = createAsyncThunk(
  "servingOrder/addServingOrder",
  async (event: any) => {
    try {
      const response = addServingOrderApi(event);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const updateServingOrder = createAsyncThunk(
  "servingOrder/updateServingOrder",
  async (event: any) => {
    try {
      const response = updateServingOrderApi(event);
      const modifiedEvent = await response;
      return modifiedEvent;
    } catch (error) {
      return error;
    }
  }
);


export const updateServingOrderStatus = createAsyncThunk(
  "servingOrder/updateServingOrderStatus",
  async (event: any) => {
    try {
      const response = updateServingOrderStatusApi(event);
      const modifiedEvent = await response;
      return modifiedEvent;
    } catch (error) {
      return error;
    }
  }
);

export const resetApiServingOrderResponseFlag = createAsyncThunk(
  "servingOrder/resetApiServingOrderResponseFlag",
  async () => {
    try {
      return true;
    } catch (error) {
      return error;
    }
  }
);
